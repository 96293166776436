import { convertToRaw, EditorState } from 'draft-js'
import { TFunction } from 'i18next'
import { Moment } from 'moment'
import * as Yup from 'yup'

import { Language } from '../shared'

export const creationValidation = (t: TFunction) =>
    Yup.object().shape({
        title: Yup.string().required(t('validation.required')),
        slug: Yup.string().required(t('validation.required')),
        text: Yup.mixed().test(
            'textValidation',
            t('validation.1required'),
            (value: EditorState) => {
                const rawContent = convertToRaw(value.getCurrentContent())
                return (
                    rawContent.blocks.length > 1 ||
                    rawContent.blocks[0].text.length > 0 ||
                    Object.keys(rawContent.entityMap).length > 0
                )
            }
        ),
        createdAt: Yup.date().required(t('validation.required')),
        mainImg: Yup.mixed()
            .test('fileSize', t('validation.size'), value => {
                if (value) return value.size <= 2000000
                else return true
            })
            .required(t('validation.required')),
        author: Yup.string().required(t('validation.required')),
        language: Yup.string().required(t('validation.required'))
    })

export const updateValidation = (t: TFunction) =>
    Yup.object().shape({
        title: Yup.string().required(t('validation.required')),
        slug: Yup.string().required(t('validation.required')),
        text: Yup.mixed().test(
            'textValidation',
            t('validation.1required'),
            (value: EditorState) => {
                const rawContent = convertToRaw(value.getCurrentContent())
                return (
                    rawContent.blocks.length > 1 ||
                    rawContent.blocks[0].text.length > 0 ||
                    Object.keys(rawContent.entityMap).length > 0
                )
            }
        ),
        createdAt: Yup.date().required(t('validation.required')),
        updatedImg: Yup.mixed().test('fileSize', t('validation.size'), value => {
            if (value) return value.size <= 2000000
            else return true
        }),
        author: Yup.string().required(t('validation.required')),
        language: Yup.string().required(t('validation.required'))
    })

const getFriendlyUrl = (string: string) => {
    const nodiac = {
        á: 'a',
        č: 'c',
        ď: 'd',
        é: 'e',
        ě: 'e',
        í: 'i',
        ň: 'n',
        ó: 'o',
        ř: 'r',
        š: 's',
        ť: 't',
        ú: 'u',
        ů: 'u',
        ý: 'y',
        ž: 'z'
    }

    const s = string.toLowerCase()
    let result = ''
    for (let i = 0; i < s.length; i++) {
        result += typeof nodiac[s.charAt(i)] != 'undefined' ? nodiac[s.charAt(i)] : s.charAt(i)
    }
    return result.replace(/[^a-z0-9_]+/g, '-').replace(/^-|-$/g, '')
}

export const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
) => {
    setFieldValue('title', event.target.value)
    setFieldValue('slug', getFriendlyUrl(event.target.value))
}

export const tagsSuggestions = [
    { text: 'development', value: 'development' },
    { text: 'design', value: 'design' },
    { text: 'events', value: 'events' },
    { text: 'technology', value: 'technology' },
    { text: 'tech', value: 'tech' },
    { text: 'ui', value: 'ui' },
    { text: 'ux', value: 'ux' },
    { text: 'project management', value: 'project management' },
    { text: 'delivery', value: 'delivery' },
    { text: 'android', value: 'android' },
    { text: 'iOS', value: 'iOS' },
    { text: 'dev', value: 'dev' }
]

export interface Category {
    id: string
    name: string
}

export interface FormValues {
    title: string
    slug: string
    text: EditorState
    excerpt: string
    published: boolean
    language: Language
    categories: string[]
    createdAt: Moment
    mainImg: string
    updatedImg?: string
    author: string
    ogTitle?: string
    ogDescription?: string
    tags: string[]
    isDraft?: boolean
}
