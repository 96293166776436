import { Form, Icon, Input } from 'antd'
import { Field, FieldConfig, FieldProps, getIn } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { ErrorText } from '../shared'

const { Item } = Form

const StyledIcon = styled(Icon)`
    color: rgba(0, 0, 0, 0.25);
`

interface Props {
    required?: boolean
    icon?: string
    placeholder?: string
    type?: string
    size?: 'small' | 'default' | 'large'
    disabled?: boolean
    style?: React.CSSProperties | undefined
    maxLength?: number
}

const TextInput = ({
    required,
    icon,
    placeholder,
    type,
    size,
    disabled,
    style,
    form: { errors, touched },
    field,
    maxLength
}: Props & FieldProps) => {
    const error = getIn(errors, field.name)
    const touch = getIn(touched, field.name)
    return (
        <Item validateStatus={touch && error ? 'error' : ''} required={required} style={style}>
            {type === 'textarea' ? (
                <Input.TextArea
                    {...field}
                    disabled={disabled}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                />
            ) : (
                <Input
                    {...field}
                    placeholder={placeholder}
                    type={type}
                    prefix={icon ? <StyledIcon type={icon} /> : ''}
                    size={size}
                    disabled={disabled}
                    maxLength={maxLength}
                />
            )}
            {touch && error && <ErrorText>{error}</ErrorText>}
        </Item>
    )
}

export default (props: Omit<FieldConfig, 'component'> & Props) => (
    <Field {...props} component={TextInput} />
)
