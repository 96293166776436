import { Button, Icon, Popover as AntdPopover } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import React from 'react'

interface Props {
    children: JSX.Element[] | JSX.Element
}

const Popover = ({ children }: Props) => {
    const [t] = useTranslation()

    return (
        <AntdPopover
            trigger="hover"
            placement="bottomRight"
            overlayStyle={{ maxWidth: 500 }}
            content={
                <Container>
                    <IconDiv>
                        <StyledIcon type="question-circle" />
                    </IconDiv>
                    <div>
                        <Title>{t('blog.shared.info.delete')}</Title>
                        {t('blog.shared.info.description')}
                        <ButtonGroup>
                            <StyledButton>{t('blog.shared.info.cancel')}</StyledButton>
                            <StyledButton type="primary">{t('blog.shared.info.ok')}</StyledButton>
                        </ButtonGroup>
                    </div>
                </Container>
            }
        >
            {children}
        </AntdPopover>
    )
}

export default Popover

const StyledIcon = styled(Icon)`
    font-size: 20px;
    color: #faad14;
    margin-right: 20px;
`

const Title = styled.p`
    font-weight: bold;
    font-size: 16px;
`

const Container = styled.div`
    display: flex;
    margin: 20px;
`

const IconDiv = styled.div`
    padding-top: 2px;
`

const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
`

const StyledButton = styled(Button)`
    margin-top: 20px;
    :first-child {
        margin-right: 10px;
    }
`
