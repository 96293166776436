import React from 'react'
import styled from 'styled-components'
import bubble from '../../../assets/bubble-big.svg'
import qusion from '../../../assets/logo.svg'
import { ThemeProps } from '../../../theme'

interface Props {
    heading1: string
    heading2: string
    children: JSX.Element[] | JSX.Element
}

const Container = styled.div`
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-color: #fafafa;
`

const LeftSide = styled.div`
    width: 60%;
    height: 100vh;
    position: relative;
`

const RightSide = styled.div`
    width: 40%;
`

const FormWrapper = styled.div`
    max-width: 300px;
`

const StyledBubbleImg = styled.img`
    position: absolute;
    width: 650px;
    height: 550px;
    margin-left: -325px;
    margin-top: -270px;
    left: 43%;
    top: 50%;
    @media screen and (max-width: ${({ theme }: ThemeProps) => theme.resolution.screenMd}) {
        width: 500px;
        height: 400px;
        margin-left: -250px;
        margin-top: -200px;
    }
`

const StyledQusionImg = styled.img`
    position: absolute;
    left: 22%;
    top: 20%;
    @media screen and (max-width: ${({ theme }: ThemeProps) => theme.resolution.screenMd}) {
        left: 16%;
    }
`

const LeftText = styled.div`
    position: absolute;
    width: 440px;
    height: 170px;
    margin-left: -220px;
    margin-top: -85px;
    left: 50%;
    top: 50%;
    @media screen and (max-width: ${({ theme }: ThemeProps) => theme.resolution.screenMd}) {
        width: 300px;
        height: 130px;
        margin-left: -150px;
        margin-top: -65px;
    }
`

const H1 = styled.h1`
    font-size: 38px;
    @media screen and (max-width: ${({ theme }: ThemeProps) => theme.resolution.screenMd}) {
        font-size: 26px;
    }
`

const H2 = styled.h2`
    font-size: 20px;
    @media screen and (max-width: ${({ theme }: ThemeProps) => theme.resolution.screenMd}) {
        font-size: 16px;
    }
    opacity: 0.6;
`

const Layout = ({ heading1, heading2, children }: Props) => {
    return (
        <Container>
            <LeftSide>
                <LeftText>
                    <H1>{heading1}</H1>
                    <H2>{heading2}</H2>
                </LeftText>
                <StyledBubbleImg src={bubble} alt="bubble" />
                <StyledQusionImg src={qusion} alt="qusion" />
            </LeftSide>
            <RightSide>
                <FormWrapper>{children}</FormWrapper>
            </RightSide>
        </Container>
    )
}

export default Layout
