import { createGlobalStyle } from 'styled-components'

const screenSm = 600
const screenMd = 768
const screenLg = 992

export const theme = {
    colors: {},
    resolution: {
        screenSm: screenSm + 'px',
        screenMd: screenMd + 'px',
        screenLg: screenLg + 'px'
    }
}

export type ThemeProps = { theme: Readonly<typeof theme> }

export const GlobalStyle = createGlobalStyle`
  *, :after, :before {
    box-sizing: border-box;
    margin: 0;
  }

  .ant-form-item-control {
    line-height: 20px !important;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }

  .ant-table-header-column {
    z-index: 1 !important;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow: unset;
  }

  tr.ant-table-expanded-row, tr.ant-table-expanded-row:hover {
    background: none;
}
`
