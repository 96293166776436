import { Card, message } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import Input from "../../../../../components/Input";
import {
  CompaniesDocument,
  useCreateCompanyMutation
} from "../../../../../generated/graphql";
import { FormH1, Label } from "../../../shared/styled";
import {
  ButtonGroup,
  Container,
  Row,
  RowItem,
  StyledButton
} from "../../shared";
import { FormValues, validation } from "../shared";

const initialValues = {
  name: ""
};

const CompanyCreation = ({ history }: RouteComponentProps) => {
  const [t] = useTranslation();
  const [
    createCompany,
    { loading: mutationLoading }
  ] = useCreateCompanyMutation({
    update(cache, { data }) {
      try {
        const { companies }: any = cache.readQuery({
          query: CompaniesDocument
        });
        cache.writeQuery({
          query: CompaniesDocument,
          data: {
            companies: companies.concat([data?.createOneCompany])
          }
        });
      } catch {}
    },
    onCompleted: () => {
      message.success(t("company.creation.success"));
    }
  });

  return (
    <Container>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validation(t)}
        onSubmit={values => {
          createCompany({ variables: values });
        }}
      >
        {() => (
          <Form>
            <Card>
              <FormH1>{t("company.creation.heading")}</FormH1>
              <Row>
                <RowItem>
                  <Label>{t("company.shared.title")}</Label>
                  <Input name="name" size="large" />
                </RowItem>
              </Row>
              <ButtonGroup>
                <StyledButton onClick={() => history.goBack()}>
                  {t("company.shared.cancel")}
                </StyledButton>
                <StyledButton
                  htmlType="submit"
                  type="primary"
                  loading={mutationLoading}
                >
                  {t("company.shared.save")}
                </StyledButton>
              </ButtonGroup>
            </Card>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CompanyCreation;
