import cuid from 'cuid'
import * as Yup from 'yup'
import { Moment } from 'moment'
import { TFunction } from 'i18next'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

export const suggestionValidation = (t: TFunction) =>
    Yup.object().shape({
        from: Yup.date().nullable(),
        until: Yup.date().nullable(),
        img: Yup.mixed()
            .test('fileSize', t('validation.size'), value => {
                if (isString(value)) return true
                if (value) return value.size <= 1000000
                else return true
            })
            .required(t('validation.required')),
        title: Yup.string().required(t('validation.required')),
        unlimited: Yup.boolean().required(t('validation.required')),
        published: Yup.boolean().required(t('validation.required')),
        projectId: Yup.string().required(t('validation.required')),
        data: Yup.array().of(
            Yup.object().shape({
                img: Yup.mixed()
                    .test('fileSize', t('validation.size'), value => {
                        if (isString(value)) return true
                        if (value) return value.size <= 1000000
                        else return true
                    })
                    .required(t('validation.required')),
                title: Yup.string().required(t('validation.required')),
                text: Yup.string().required(t('validation.required')),
                index: Yup.number().required(t('validation.required'))
            })
        )
    })

export interface SuggestionFormValues {
    img: any
    title: string
    from?: Date | Moment | null
    until?: Date | Moment | null
    unlimited: boolean
    published: boolean
    projectId?: string | null
    data: {
        img: any
        id: string
        text: string
        title: string
        index: number
        suggestionId?: string | null
    }[]
}

export const initialValuesSuggestionItem = (idx: number) => {
    return {
        id: cuid(),
        text: '',
        title: '',
        img: null,
        index: idx,
        suggestionId: ''
    }
}

export const getNewItems = (values: SuggestionFormValues['data']) => {
    if (!values || isEmpty(values)) {
        return undefined
    }

    const result = values.map((i, idx) => {
        return {
            id: i.id,
            text: i.text,
            title: i.title,
            index: i.index,
            img: isString(i.img) ? i.img : '',
            updatedImg: isString(i.img) ? undefined : i.img
        }
    })
    return result
}
