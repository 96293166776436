import { Icon, Menu } from "antd";
import styled from "styled-components";

interface IStyled {
  published?: boolean;
  deleteButton?: boolean;
}

export const StyledIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.25);
`;

export const StyledMenu = styled(Menu)`
  background-color: inherit;
`;

export const StatusIcon = styled.span<IStyled>`
  height: 10px;
  width: 10px;
  background-color: ${props => (props.published ? "#46e08c" : "#f73f54")};
  border-radius: 50%;
  display: inline-block;
`;

export const ListH1 = styled.h1`
  font-family: "Suisse Works";

  color: #000;
  font-size: 38px;
  line-height: 46px;
  letter-spacing: 0.02em;
  margin: 0 20px 0 0;
`;

export const FormH1 = styled.h1`
  font-size: 30px;
  margin: 0 0 20px 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.p`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
`;

export const ClickableLabel = styled.p`
  margin-bottom: 15px;
  font-size: 12px;
  color: #1890ff;
  font-weight: bold;
  width: fit-content;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const PrimaryColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const TransparentButton = styled.button<IStyled>`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: ${props => (props.deleteButton ? "#f73f54" : "")};
`;
