import 'antd/dist/antd.css'
import React, { useContext } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Loader from './components/Loader'
import { Routes } from './constants'
import { Auth } from './context/Authentication'
import AdminDashboard from './pages/Protected/Admin/AdminDashboard'
import AdminRoute from './pages/Protected/Admin/AdminRoute'
import CompanyCreation from './pages/Protected/Admin/CompanyManagement/Creation'
import CompanyDetail from './pages/Protected/Admin/CompanyManagement/Detail'
import Companies from './pages/Protected/Admin/CompanyManagement/List'
import ProjectCreation from './pages/Protected/Admin/ProjectManagement/Creation'
import ProjectDetail from './pages/Protected/Admin/ProjectManagement/Detail'
import Projects from './pages/Protected/Admin/ProjectManagement/List'
import Dashboard from './pages/Protected/Private/Dashboard'
import FaqCreation from './pages/Protected/Private/FaqsManagement/Creation'
import FaqDetail from './pages/Protected/Private/FaqsManagement/Detail'
import Faqs from './pages/Protected/Private/FaqsManagement/List'
import PostCreation from './pages/Protected/Private/PostsManagement/Creation'
import PostDetail from './pages/Protected/Private/PostsManagement/Detail'
import Posts from './pages/Protected/Private/PostsManagement/List'
import Suggestions from './pages/Protected/Private/SuggestionsManagement/List'
import SuggestionCreation from './pages/Protected/Private/SuggestionsManagement/Creation'
import SuggestionDetail from './pages/Protected/Private/SuggestionsManagement/Detail'
import RestrictionCreation from './pages/Protected/Private/RestrictionsManagement/Creation'
import RestrictionDetail from './pages/Protected/Private/RestrictionsManagement/Detail'
import Restrictions from './pages/Protected/Private/RestrictionsManagement/List'
import PrivateRoute from './pages/Protected/Private/PrivateRoute'
import Users from './pages/Protected/Private/UsersManagement/List'
import Profile from './pages/Protected/shared/Profile'
import ForgotPassword from './pages/Public/ForgotPassword'
import Login from './pages/Public/Login'
import PublicRoute from './pages/Public/PublicRoute'
import Register from './pages/Public/Register'
import ResetPassword from './pages/Public/ResetPassword'

const App = () => {
    const { loading, user } = useContext(Auth)

    if (loading) {
        return <Loader />
    }

    return !user.qAdmin ? (
        <Switch>
            <PublicRoute exact={true} path={Routes.HOME} component={Login} />
            <PublicRoute exact={true} path={Routes.REGISTER} component={Register} />
            <PublicRoute exact={true} path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />
            <PublicRoute exact={true} path={Routes.RESET_PASSWORD} component={ResetPassword} />
            <PrivateRoute exact={true} path={Routes.PROFILE} component={Profile} />
            <PrivateRoute exact={true} path={Routes.DASHBOARD} component={Dashboard} />
            {user.activeProject && user.activeProject.faqFeature && (
                <PrivateRoute exact={true} path={Routes.FAQ_CREATION} component={FaqCreation} />
            )}
            {user.activeProject && user.activeProject.faqFeature && (
                <PrivateRoute exact={true} path={Routes.FAQ_DETAIL} component={FaqDetail} />
            )}
            {user.activeProject && user.activeProject.blogFeature && (
                <PrivateRoute exact={true} path={Routes.POST_DETAIL} component={PostDetail} />
            )}
            {user.activeProject && user.activeProject.blogFeature && (
                <PrivateRoute exact={true} path={Routes.POST_CREATION} component={PostCreation} />
            )}
            {user.activeProject && user.activeProject.blogFeature && (
                <PrivateRoute exact={true} path={Routes.POSTS} component={Posts} />
            )}
            {user.activeProject && user.activeProject.restrictionFeature && (
                <PrivateRoute
                    exact={true}
                    path={Routes.RESTRICTION_DETAIL}
                    component={RestrictionDetail}
                />
            )}
            {user.activeProject && user.activeProject.restrictionFeature && (
                <PrivateRoute
                    exact={true}
                    path={Routes.RESTRICTION_CREATION}
                    component={RestrictionCreation}
                />
            )}
            {user.activeProject && user.activeProject.restrictionFeature && (
                <PrivateRoute exact={true} path={Routes.RESTRICTIONS} component={Restrictions} />
            )}
            {user.activeProject && user.activeProject.suggestionFeature && (
                <PrivateRoute exact={true} path={Routes.SUGGESTIONS} component={Suggestions} />
            )}
            {user.activeProject && user.activeProject.suggestionFeature && (
                <PrivateRoute
                    exact={true}
                    component={SuggestionDetail}
                    path={Routes.SUGGESTIONS_DETAIL}
                />
            )}
            {user.activeProject && user.activeProject.suggestionFeature && (
                <PrivateRoute
                    exact={true}
                    component={SuggestionCreation}
                    path={Routes.SUGGESTIONS_CREATION}
                />
            )}
            {user.activeProject && user.activeProject.faqFeature && (
                <PrivateRoute exact={true} path={Routes.FAQS} component={Faqs} />
            )}
            {user.activeProject && user.activeProject.admin && (
                <PrivateRoute exact={true} path={Routes.USERS} component={Users} />
            )}
            <Redirect to={Routes.HOME} />
        </Switch>
    ) : (
        <Switch>
            <PublicRoute exact={true} path={Routes.HOME} component={Login} />
            <PublicRoute exact={true} path={Routes.REGISTER} component={Register} />
            <PublicRoute exact={true} path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />
            <PublicRoute exact={true} path={Routes.RESET_PASSWORD} component={ResetPassword} />
            <AdminRoute exact={true} path={Routes.PROFILE} component={Profile} />
            <AdminRoute exact={true} path={Routes.DASHBOARD} component={AdminDashboard} />
            <AdminRoute exact={true} path={Routes.COMPANIES} component={Companies} />
            <AdminRoute exact={true} path={Routes.COMPANY_CREATION} component={CompanyCreation} />
            <AdminRoute exact={true} path={Routes.COMPANY_DETAIL} component={CompanyDetail} />
            <AdminRoute exact={true} path={Routes.PROJECTS} component={Projects} />
            <AdminRoute exact={true} path={Routes.PROJECT_CREATION} component={ProjectCreation} />
            <AdminRoute exact={true} path={Routes.PROJECT_DETAIL} component={ProjectDetail} />
            <Redirect to={Routes.HOME} />
        </Switch>
    )
}

export default App
