import { matchPath } from 'react-router'
import { Routes } from '../../../constants'

export const createSelectOptions = (options: Option[]) => {
    return options.map(option => ({
        text: option.name,
        value: option.id || option.authId
    }))
}

export interface Option {
    id: string
    authId: string
    name: string
}

export const getMatchedKey = (location: string) =>
    Object.values(Routes).find(route =>
        matchPath(location, {
            path: route,
            exact: true
        })
    ) || ''
