import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Input from '../../../components/Input'
import { Routes } from '../../../constants'
import { Auth } from '../../../context/Authentication'
import Layout from '../Layout'
import { A, P, StyledButton } from '../shared'

interface FormValues {
    email: string
    password: string
}

const loginValidation = (t: TFunction) =>
    Yup.object().shape({
        email: Yup.string()
            .email(t('validation.email'))
            .required(t('validation.required')),
        password: Yup.string()
            .min(6, t('validation.password'))
            .required(t('validation.required'))
    })

const Login = () => {
    const [t] = useTranslation()
    const { signIn } = useContext(Auth)

    return (
        <Layout heading1={t('landing.heading')} heading2={t('landing.description')}>
            <Formik<FormValues>
                initialValues={{ email: '', password: '' }}
                validationSchema={loginValidation(t)}
                onSubmit={({ email, password }) => {
                    signIn(email, password)
                }}
            >
                {() => (
                    <Form>
                        <P bold>{t('landing.email')}</P>
                        <Input name="email" icon="user" placeholder={t('landing.email')} />
                        <P bold>{t('landing.password')}</P>
                        <Input
                            name="password"
                            type="password"
                            icon="lock"
                            placeholder={t('landing.password')}
                        />

                        <StyledButton htmlType="submit" type="primary">
                            {t('landing.login')}
                        </StyledButton>
                    </Form>
                )}
            </Formik>
            <A href={Routes.FORGOT_PASSWORD}>{t('landing.forgotPassword')}</A>
            <P>{t('landing.troubles')}</P>
            <P>{t('landing.contact')}</P>
        </Layout>
    )
}

export default Login
