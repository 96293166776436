import { Button, Form, Icon, message, Upload } from 'antd'
import { Field, FieldConfig, FieldProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ErrorText } from '../shared'

const { Item } = Form

const StyledUpload = styled(Upload)`
    & div {
        width: 100%;
    }
`

interface Props {
    accept?: string
}

const UploadInput = ({
    accept,
    form: { errors, touched, setFieldValue },
    field
}: Props & FieldProps) => {
    const [t] = useTranslation()

    const handleImageInsert = (file: File) => {
        if (file.size >= 2000000) {
            message.error('File cannot be larger than 2MB')
        }

        setFieldValue(field.name, file)
        return false
    }
    const handleImageRemove = () => {
        setFieldValue(field.name, null)
        return true
    }
    return (
        <Item validateStatus={touched[field.name] && errors[field.name] ? 'error' : ''}>
            <StyledUpload
                beforeUpload={handleImageInsert}
                onRemove={handleImageRemove}
                accept={accept}
            >
                <Button style={{ width: '100%' }}>
                    <Icon type="upload" /> {t('profile.upload')}
                </Button>
            </StyledUpload>
            {touched[field.name] && errors[field.name] && (
                <ErrorText>{errors[field.name]}</ErrorText>
            )}
        </Item>
    )
}

export default (props: Omit<FieldConfig, 'component'> & Props) => (
    <Field {...props} component={UploadInput} />
)
