import styled from 'styled-components'

export const ProjectLogo = styled.img`
  margin-right: 12px;
  height: 30px;
  width: 30px;
`

export const ArrowDown = styled.img<{ open: boolean }>`
  margin-left: auto;
  transform: rotate(${({ open }) => open ? '180deg' : '0deg'});
  transition: 200ms transform ease;
`

export const DropdownContainer = styled.div`
  width: 100%;
  min-height: 70px;
  cursor: pointer;
  position: relative;
  z-index: 100;

  padding: 10px 0;
  margin: 17px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    color: #BFBFBF;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    padding: 0 12px;
    margin-bottom: 17px;
  }
`

export const DropdownHead = styled.div<{ open: boolean, collapsed: boolean }>`
  width: 100%;
  height: ${({ collapsed }) => collapsed ? '98px' : '79px'};

  border-bottom: ${({ open }) => open ? '1px solid #fafafa' : '1px solid #fff'};
  border-radius: ${({ open }) => open ? '0' : '10px'};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  padding: 19px 12px;
  margin-bottom: 0;
  
  display: flex;
  flex-direction: ${({ collapsed }) => collapsed ? 'column' : 'row'};
  align-items: center;

  img {
    margin-right: ${({ collapsed }) => collapsed ? '0' : '12px'};
  }

  ${ArrowDown} {
    margin: ${({ collapsed }) => collapsed ? '19px auto 0' : 'auto'};
    margin-right: ${({ collapsed }) => collapsed ? 'auto' : '0'};
  }

  background-color: #fff;
`

export const DropdownItem = styled.div<{ collapsed: boolean }>`
  width: 100%;

  padding: 0 12px;
  margin-bottom: ${({ collapsed }) => collapsed ? '20px' : '30px'};;
  max-height: 30px;

  display: flex;
  align-items: center;

  background-color: #fff;

  img {
    max-height: 30px;
  }

  &:last-of-type {
    margin: 0;
  }
`

export const DropdownContent = styled.div<{ open: boolean, collapsed: boolean }>`
  width: 100%;

  padding-top: ${({ collapsed }) => collapsed ? '20px' : '11px'};
  padding-bottom: ${({ collapsed }) => collapsed ? '20px' : '30px'};
  
  display: ${({ open }) => open ? 'block' : 'none'};
  position: absolute;
  background-color: #fff;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  top: ${({ collapsed }) => collapsed ? '100px' : '79px'};
  z-index: 100;
`

export const ProjectName = styled.h5`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DropdownFade = styled.div<{ open: boolean }>`
  z-index: 98;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: #000000;
  opacity: ${({ open }) => open ? 0.2 : 0};

  transition: 200ms opacity ease;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
`