import { Card, Icon } from "antd";
import React from "react";
import styled from "styled-components";
import bubble from "../../../../../assets/bubble.svg";

interface IProps {
  icon: string;
  text: string;
  index: number;
}

interface IStyled {
  index: number;
}

const StyledCard = styled(Card)`
  margin: 10px 20px 10px 0 !important;
  max-width: 170px;
  width: 100%;
  height: 140px;
  border-radius: 7px;
  border: 0.7px solid #f5f5f5;
  position: relative;
  > * {
    padding: 0;
    height: 100%;
  }
`;

const StyledBubble = styled.img<IStyled>`
  position: absolute;
  max-width: 100px;
  max-height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(${props => props.index * 180 + "deg"});
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const P = styled.p`
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 10px;
`;

export default ({ icon, text, index }: IProps) => (
  <StyledCard>
    <StyledIcon type={icon} />
    <StyledBubble src={bubble} alt="bubble" index={index} />
    <P>{text}</P>
  </StyledCard>
);
