import moment from 'moment'
import Truncate from 'react-truncate'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Empty, Icon, Table, Tooltip, Popconfirm } from 'antd'

import '../../../shared/styles.css'
import { AddButton } from '../../styled'
import PrevNext from '../../shared/PrevNext'
import Loader from '../../../../../components/Loader'
import { MenuIcon, IconContainer } from '../../FaqsManagement/shared'
import {
    SuggestionsQuery,
    SuggestionsDocument,
    useSuggestionsQuery,
    useDeleteSuggestionMutation
} from '../../../../../generated/graphql'
import { Header, ListH1, StatusIcon, PrimaryColumn } from '../../../shared/styled'

type Suggestion = SuggestionsQuery['suggestions'][0]

export default ({ history, match }: RouteComponentProps<{ projectId: string }>) => {
    const { projectId } = match.params
    const [t] = useTranslation()

    const [selectedItemId, setSelectedItemId] = useState('')

    const { data, error, loading } = useSuggestionsQuery({
        variables: { projectId },
        fetchPolicy: 'cache-and-network'
    })

    const [deleteSuggestion] = useDeleteSuggestionMutation({
        update(cache, { data }) {
            const { suggestions }: any = cache.readQuery({
                query: SuggestionsDocument,
                variables: { projectId }
            })
            const deleteIndex = suggestions
                .map((faq: Suggestion) => faq.id)
                .indexOf(data?.deleteOneSuggestion?.id)
            suggestions.splice(deleteIndex, 1)
            cache.writeQuery({
                query: SuggestionsDocument,
                variables: { projectId },
                data: { suggestions }
            })
        },
        onCompleted: data => {
            setSelectedItemId('')
        }
    })

    if (loading || !data) {
        return <Loader />
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const columns = [
        {
            width: 100,
            dataIndex: 'published',
            title: t('blog.status'),
            render: (published: boolean, record: Suggestion) => {
                return (
                    <Tooltip
                        title={
                            record.published
                                ? t('suggestion.published')
                                : t('suggestion.unpublished')
                        }
                    >
                        <StatusIcon published={record.published} />
                    </Tooltip>
                )
            },
            sorter: (a: Suggestion, b: Suggestion) =>
                a.published === b.published ? 0 : a.published ? -1 : 1
        },
        {
            dataIndex: 'title',
            title: t('blog.title'),
            render: (_: any, record: Suggestion) => (
                <PrimaryColumn
                    onClick={() => history.push(`/${projectId}/suggestions/${record.id}`)}
                >
                    <Truncate lines={2} ellipsis={<span>...</span>}>
                        {record.title}
                    </Truncate>
                </PrimaryColumn>
            ),
            sorter: (a: Suggestion, b: Suggestion) => (a.title > b.title ? 1 : -1)
        },
        {
            width: 140,
            dataIndex: 'items.length',
            title: t('suggestion.slidesCount'),
            render: (_: any, record: Suggestion) => <span>{record.items.length}</span>,
            sorter: (a: Suggestion, b: Suggestion) => (a.items.length > b.items.length ? 1 : -1)
        },
        {
            width: 180,
            title: t('suggestion.period'),
            render: (record: Suggestion) => (
                <>
                    {record.unlimited && <span>{t('suggestion.unlimited')}</span>}
                    {record?.from && record?.until && !record.unlimited && (
                        <span>
                            {moment(record.from).format('DD.MM.YY')} -{' '}
                            {moment(record.until).format('DD.MM.YY')}
                        </span>
                    )}
                </>
            )
        },
        {
            title: t('blog.action'),
            width: 100,
            render: (record: Suggestion) => {
                return (
                    <IconContainer>
                        <Tooltip title={t('blog.edit')}>
                            <MenuIcon
                                type="edit"
                                style={{ marginRight: 20 }}
                                onClick={() =>
                                    history.push(`/${projectId}/suggestions/${record.id}`)
                                }
                            />
                        </Tooltip>
                        <Popconfirm
                            okType="danger"
                            placement="bottomRight"
                            title={t('suggestion.confirm.title')}
                            okText={t('suggestion.confirm.okText')}
                            cancelText={t('suggestion.confirm.cancelText')}
                            onConfirm={e => {
                                deleteSuggestion({
                                    variables: {
                                        id: selectedItemId
                                    }
                                })
                            }}
                            onCancel={e => {
                                setSelectedItemId('')
                            }}
                        >
                            <MenuIcon type="delete" onClick={() => setSelectedItemId(record.id)} />
                        </Popconfirm>
                    </IconContainer>
                )
            }
        }
    ]

    return (
        <>
            <Header>
                <ListH1>{t('suggestion.heading')}</ListH1>
                <AddButton onClick={() => history.push(`/${projectId}/suggestion-new`)}>
                    <Icon type="plus" />
                    &nbsp;&nbsp;{t('suggestion.add')}
                </AddButton>
            </Header>
            {data.suggestions.length ? (
                <Table<Suggestion>
                    // @ts-ignore
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data.suggestions}
                    pagination={{
                        position: 'bottom',
                        style: { float: 'left' },
                        defaultPageSize: 10,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <PrevNext type="left" current={current} />
                            }
                            if (type === 'next') {
                                return <PrevNext type="right" current={current} />
                            }
                            return originalElement
                        },
                        showSizeChanger: true
                    }}
                />
            ) : (
                <Empty
                    imageStyle={{ height: 160 }}
                    description={<span>{t('suggestion.none')}</span>}
                >
                    <Button
                        type="primary"
                        onClick={() => history.push(`/${projectId}/suggestion-new`)}
                    >
                        <Icon type="plus" /> {t('suggestion.add')}
                    </Button>
                </Empty>
            )}
        </>
    )
}
