import { TFunction } from 'i18next'
import { convertToRaw } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { EditorState, ContentState } from 'draft-js'

import csIcon from '../../../../assets/czech.svg'
import enIcon from '../../../../assets/english.svg'
import deIcon from '../../../../assets/german.svg'
import plIcon from '../../../../assets/poland.svg'
import skIcon from '../../../../assets/slovak.svg'

export type Language = 'CS' | 'SK' | 'EN' | 'DE' | 'PL' | 'ENCZ' | 'ENSK' | 'ENPL'

export const createLanguageOptions = (stringifiedLanguages: string, t: TFunction) => {
    const options = []
    const parsedLanguages = JSON.parse(stringifiedLanguages)
    if (parsedLanguages.cs) options.push({ text: t('blog.czech'), value: 'CS' })
    if (parsedLanguages.sk) options.push({ text: t('blog.slovak'), value: 'SK' })
    if (parsedLanguages.en) options.push({ text: t('blog.english'), value: 'EN' })
    if (parsedLanguages.de) options.push({ text: t('blog.german'), value: 'DE' })
    if (parsedLanguages.pl) options.push({ text: t('blog.polish'), value: 'PL' })
    if (parsedLanguages.encz) options.push({ text: 'EN-CZ', value: 'ENCZ' })
    if (parsedLanguages.ensk) options.push({ text: 'EN-SK', value: 'ENSK' })
    if (parsedLanguages.enpl) options.push({ text: 'EN-PL', value: 'ENPL' })
    return options
}

export const languageIcons = {
    CS: csIcon,
    SK: skIcon,
    EN: enIcon,
    DE: deIcon,
    PL: plIcon,
    ENCZ: enIcon,
    ENSK: enIcon,
    ENPL: enIcon
}

const replaceAll = (string: string, search: string, replace: string): string => {
    return string.split(search).join(replace)
}

export const convertStateToHTML = (state: ContentState) => {
    const rawContentState = convertToRaw(state)
    const markup = draftToHtml(rawContentState)
    const result = replaceAll(markup, '<p></p>', '<p><br/></p>')
    return result
}

export const convertHTMLToState = (html: string): EditorState => {
    // Fix backward compatibility
    // See: https://github.com/jpuri/react-draft-wysiwyg/issues/609
    const htmlWhitoutNewLines = replaceAll(html, '<p><br/></p>', '<p></p>')
    const htmlStringWithoutFigureTag = htmlWhitoutNewLines
        .replace(/<figure>/g, '')
        .replace(/<\/figure>/g, '')

    const blocksFromHtml = htmlToDraft(htmlStringWithoutFigureTag)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    const state = EditorState.createWithContent(contentState)
    return state
}
