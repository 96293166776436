/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Button, Dropdown, Empty, Icon, Menu, message, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import Loader from '../../../../../components/Loader'
import { Routes } from '../../../../../constants'
import {
    ProjectsDocument,
    ProjectsQuery,
    useDeleteProjectMutation,
    useProjectsQuery
} from '../../../../../generated/graphql'
import { Company } from '../../../../../types'
import { Header, ListH1, PrimaryColumn, TransparentButton } from '../../../shared/styled'
import '../../../shared/styles.css'

const { Item } = Menu

type Project = ProjectsQuery['projects'][0]

export default ({ history }: RouteComponentProps) => {
    const [t] = useTranslation()

    const { data, error, loading } = useProjectsQuery()

    const [deleteProject] = useDeleteProjectMutation({
        update(cache, { data }) {
            const { projects }: any = cache.readQuery({
                query: ProjectsDocument
            })
            const deleteIndex = projects
                .map((project: Project) => project.id)
                .indexOf(data?.deleteOneProject?.id)
            projects.splice(deleteIndex, 1)
            cache.writeQuery({
                query: ProjectsDocument,
                data: { projects }
            })
        },
        onCompleted: () => {
            message.success(t('project.deleteSuccess'))
        }
    })

    if (loading || !data) {
        return <Loader />
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const columns = [
        {
            title: t('project.title'),
            dataIndex: 'name',
            render: (name: string, record: Project) => (
                <PrimaryColumn onClick={() => history.push(`${Routes.PROJECTS}/${record.id}`)}>
                    {name}
                </PrimaryColumn>
            ),
            sorter: (a: Project, b: Project) => (a.name > b.name ? 1 : -1)
        },
        {
            title: t('project.company'),
            dataIndex: 'Company',
            render: (company: Company) => (
                <PrimaryColumn onClick={() => history.push(`${Routes.COMPANIES}/${company.id}`)}>
                    {company.name}
                </PrimaryColumn>
            ),
            sorter: (a: Project, b: Project) => (a.name > b.name ? 1 : -1)
        },
        {
            title: t('project.action'),
            width: 100,
            render: (project: Project) => {
                const menu = (
                    <Menu>
                        <Item>
                            <TransparentButton
                                onClick={() => history.push(`${Routes.PROJECTS}/${project.id}`)}
                            >
                                {t('project.edit')}
                            </TransparentButton>
                        </Item>
                        <Item>
                            <TransparentButton
                                deleteButton
                                onClick={() =>
                                    deleteProject({
                                        variables: { id: project.id }
                                    })
                                }
                            >
                                {t('project.delete')}
                            </TransparentButton>
                        </Item>
                    </Menu>
                )
                return (
                    <Dropdown overlay={menu} trigger={['hover']}>
                        <a>
                            {t('project.more')} <Icon type="down" />
                        </a>
                    </Dropdown>
                )
            }
        }
    ]

    return (
        <>
            <Header>
                <ListH1>{t('project.heading')}</ListH1>
                <Link to={Routes.PROJECT_CREATION}>
                    <Button type="primary">
                        <Icon type="plus" />
                        {t('project.add')}
                    </Button>
                </Link>
            </Header>
            {data.projects.length ? (
                <Table<Project>
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={data.projects}
                    pagination={false}
                />
            ) : (
                <Empty
                    imageStyle={{
                        height: 160
                    }}
                    description={<span>{t('project.none')}</span>}
                >
                    <Button type="primary" onClick={() => history.push(Routes.PROJECT_CREATION)}>
                        <Icon type="plus" />
                        {t('project.add')}
                    </Button>
                </Empty>
            )}
        </>
    )
}
