import { Button, Icon } from "antd";
import styled from "styled-components";

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  :first-child {
    margin-right: 10px;
  }
`;

export const AddButton = styled(Button)`
  width: 100%;
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 24px;
  margin-left: 10px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  margin-bottom: 20px;
`;
