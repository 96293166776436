import styled from 'styled-components';

export const ContainerLink = styled.a<{ page: number, type: 'left' | 'right' }>`
  display: block;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  &:hover {
    /* border: 1px solid #000 !important; */
  }
`