import styled from 'styled-components'
import { Button } from 'antd'

import { ThemeProps } from '../../../../../../theme'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 20px 40px;
    border-radius: 10px;
    margin-bottom: 24px;
    flex-direction: column;
    background-color: white;
    border: 1px solid #e8e8e8;

    :hover {
        cursor: move;
    }
`

export const Head = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const Title = styled.h3`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.02em;
`
