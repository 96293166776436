import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-fetch'
import React, { Suspense } from 'react'
import { ApolloProvider } from 'react-apollo'
import ReactDOM from 'react-dom'
import { useSSR } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import App from './App'
import '../locales/i18n'
import Loader from './components/Loader'
import { GlobalStyle, theme } from './theme'
import AuthenticationProvider from './context/Authentication'

const getApolloClient = (token?: string) => {
    const authLink = setContext((_, { headers }) => ({
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
            ...headers
        }
    }))
    const client = new ApolloClient({
        link: authLink.concat(
            createUploadLink({
                uri: process.env.RAZZLE_API_URL,
                fetch
            })
        ),
        cache: new InMemoryCache()
    })
    return client
}

// @ts-ignore
const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate

const BaseApp = () => {
    // @ts-ignore
    useSSR(window.initialI18nStore, window.initialLanguage)
    return (
        <AuthenticationProvider apollo={getApolloClient()}>
            {({ user }: any) => {
                return (
                    <ApolloProvider client={getApolloClient(user.token)}>
                        <GlobalStyle />
                        <ThemeProvider theme={theme}>
                            <DndProvider backend={HTML5Backend}>
                                <BrowserRouter>
                                    <Suspense fallback={<Loader />}>
                                        <App />
                                    </Suspense>
                                </BrowserRouter>
                            </DndProvider>
                        </ThemeProvider>
                    </ApolloProvider>
                )
            }}
        </AuthenticationProvider>
    )
}

renderMethod(<BaseApp />, document.getElementById('root'))

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept()
}
