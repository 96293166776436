import { convertToRaw, EditorState } from 'draft-js'
import { TFunction } from 'i18next'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Language } from '../shared'
import { Icon } from 'antd'

export const validation = (t: TFunction) =>
    Yup.object().shape({
        question: Yup.string().required(t('validation.required')),
        answer: Yup.mixed().test(
            'answerValidation',
            t('validation.1required'),
            (value: EditorState) => {
                const rawContent = convertToRaw(value.getCurrentContent())
                return (
                    rawContent.blocks.length > 1 ||
                    rawContent.blocks[0].text.length > 0 ||
                    Object.keys(rawContent.entityMap).length > 0
                )
            }
        ),
        language: Yup.string().required(t('validation.required'))
    })

export interface FormValues {
    question: string
    answer: EditorState
    largeLink?: string
    largeLinkTitle?: string
    published: boolean
    language: Language
    category: string
}

export const MenuIcon = styled(Icon)<{ center?: boolean }>`
    margin: ${({ center }) => (center ? '0 20px' : '0')};
    cursor: pointer;
    font-size: 20px;

    &:hover {
        color: #1890ff;
    }
`

export const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
`
