import React, { useContext, useState } from 'react'
// @ts-ignore
import { useMediaQuery } from 'react-responsive'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { Routes } from '../../../../constants'
import { Auth } from '../../../../context/Authentication'
import AdminLayout from '../AdminLayout'

interface Props {
    exact?: boolean
    path: string
    component: any
}

export default ({ exact, path, component: Component }: Props) => {
    const { user } = useContext(Auth)
    const isSmScreen = useMediaQuery({
        query: '(max-device-width: 600px)'
    })
    const [collapsed, toggleCollapse] = useState(isSmScreen)

    if (!user.token) {
        return <Redirect to={Routes.HOME} />
    }

    return (
        <Route
            exact={exact}
            path={path}
            component={(props: RouteComponentProps) => (
                <AdminLayout
                    {...props}
                    collapsed={collapsed}
                    toggleCollapse={toggleCollapse}
                    isSmScreen={isSmScreen}
                >
                    <Component {...props} />
                </AdminLayout>
            )}
        />
    )
}
