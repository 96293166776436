import { message } from 'antd'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import gql from 'graphql-tag'
import { History } from 'history'
import i18n from 'i18next'
import get from 'lodash/get'
import React, { createContext, useEffect, useState } from 'react'
import { Cookies, withCookies } from 'react-cookie'
import { RouteComponentProps } from 'react-router'
import { Routes } from '../constants'
import { UsersDocument } from '../generated/graphql'

interface Project {
    id: string
    logo: string
    logoSmall: string
    name: string
    webUrl: string
    faqFeature: boolean
    restrictionFeature: boolean
    suggestionFeature: boolean
    blogFeature: boolean
    languages: string
    editorConfig: string
    admin: boolean
    admins: { email: string }[]
}

interface User {
    token?: string
    name?: string
    email?: string
    uid?: string
    qAdmin?: boolean
    projects?: Project[]
    activeProject?: Project
    profileImg?: string
}

interface ContextProps {
    user: User
    confirmUser: (values: { email: string; password: string; name: string }) => void
    loading: boolean
    signOut: () => void
    signIn: (email: string, password: string) => void
    setActiveProject: (project: Project, history: History) => void
    updateUser: (name: string, file: File | null) => void
    inviteUsers: (emails: string[], projectId: string, apolloClient: ApolloClient<object>) => void
    changeUserPassword: (options: {
        email: string
        oldPassword: string
        newPassword: string
    }) => void
    sendForgotPasswordEmail: (email: string) => void
    confirmPasswordReset: (
        code: string,
        password: string,
        history: RouteComponentProps['history']
    ) => void
}

interface State {
    user: User
    loading: boolean
}

interface Props {
    children: (props: ContextProps) => React.ReactNode
    cookies: Cookies
    apollo: ApolloClient<NormalizedCacheObject>
}

const ACTIVE_PROJECT_KEY = 'active-project'

const AuthenticationProvider = (props: Props) => {
    const [state, setState] = useState<State>({ loading: true, user: {} })

    useEffect(() => {
        // Initiate Firebase
        if (!window['firebase']) {
            const firebaseConfig = {
                apiKey: 'AIzaSyAGP4UWavU_OUllncCioMnDecKs6v3cvQM',
                authDomain: 'q-testing-environment.firebaseapp.com'
            }
            // @ts-ignore
            window.firebase = firebase.initializeApp(firebaseConfig)
        }

        // Attach authenitaction listener
        // on signin/signout change state
        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                setState({ ...state, loading: true })

                if (user.metadata.creationTime === user.metadata.lastSignInTime) {
                    setState({
                        ...state,
                        user: {
                            uid: user.uid,
                            email: user?.email || '',
                            name: user?.displayName || ''
                        }
                    })

                    await props.apollo.mutate({
                        mutation: CONFIRM_USER,
                        variables: {
                            authId: user.uid,
                            email: user.email,
                            name: user?.displayName || ''
                        }
                    })
                    message.success(i18n.t('register.success'))
                }

                const tokenResult = await user.getIdTokenResult()

                props.cookies.set('token', tokenResult.token, {
                    path: '/'
                })

                const queryResult = await fetchUser(tokenResult.token)

                if (user && tokenResult && queryResult) {
                    const activeProject = localStorage.getItem(ACTIVE_PROJECT_KEY)

                    let projectExistsInList

                    if (activeProject) {
                        projectExistsInList = queryResult.data.me.projects.some(
                            (project: Project) => project.id === JSON.parse(activeProject).id
                        )
                    }

                    if (queryResult.data.me.projects.length) {
                        setState({
                            user: {
                                token: tokenResult.token,
                                email: user.email as string,
                                name: queryResult.data.me.name,
                                projects: queryResult.data.me.projects,
                                uid: get(tokenResult, 'claims.user_id'),
                                activeProject:
                                    projectExistsInList && activeProject
                                        ? {
                                              ...queryResult.data.me.projects.find(
                                                  (project: any) =>
                                                      project.id === JSON.parse(activeProject).id
                                              ),
                                              admin: JSON.parse(activeProject).admins.some(
                                                  (admin: { email: string }) =>
                                                      admin.email === user.email
                                              )
                                          }
                                        : {
                                              ...queryResult.data.me.projects[0],
                                              admin: queryResult.data.me.projects[0].admins.some(
                                                  (admin: { email: string }) =>
                                                      admin.email === user.email
                                              )
                                          },
                                profileImg: queryResult.data.me.profileImg
                            },
                            loading: false
                        })
                    } else {
                        setState({
                            user: {
                                token: tokenResult.token,
                                email: user.email as string,
                                uid: get(tokenResult, 'claims.user_id'),
                                qAdmin: tokenResult.claims.qAdmin,
                                name: queryResult.data.me.name,
                                profileImg: queryResult.data.me.profileImg
                            },
                            loading: false
                        })
                    }
                }
            } else {
                setState({ user: {}, loading: false })
            }
        })
    }, [])

    const fetchUser = async (token: string) => {
        const client = props.apollo
        return await client.query({
            query: USER_QUERY,
            context: {
                headers: {
                    Authorization: token ? `Bearer ${token}` : ''
                }
            },
            fetchPolicy: 'network-only'
        })
    }

    const changeUserPassword = async (options: {
        email: string
        oldPassword: string
        newPassword: string
    }) => {
        try {
            const { email, oldPassword, newPassword } = options
            await firebase.auth().signInWithEmailAndPassword(email, oldPassword)
            const user = firebase.auth().currentUser
            await user!.updatePassword(newPassword)
            message.success(i18n.t('resetPassword.success'))
        } catch (err) {
            message.error(err.message)
            console.log(err)
        }
    }

    const updateUser = async (name: string, file: File | null) => {
        const client = props.apollo
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_USER,
                variables: { name, file },
                context: {
                    headers: {
                        Authorization: `Bearer ${state.user.token}`
                    }
                }
            })
            setState({
                ...state,
                user: {
                    ...state.user,
                    name: data.updateUserProfile.name,
                    profileImg: data.updateUserProfile.profileImg
                }
            })
            message.success(i18n.t('profile.success'))
        } catch (err) {
            console.log(err)
        }
    }

    const setActiveProject = (project: Project, history: History) => {
        const adjustedProject = {
            ...project,
            admin: project.admins.some(
                (admin: { email: string }) => admin.email === state.user.email
            )
        }
        localStorage.setItem(ACTIVE_PROJECT_KEY, JSON.stringify(adjustedProject))
        setState({
            ...state,
            user: {
                ...state.user,
                activeProject: adjustedProject
            }
        })
        return history.push(`/${adjustedProject.id}`)
    }

    const sendForgotPasswordEmail = async (email: string) => {
        try {
            await firebase.auth().sendPasswordResetEmail(email)
            message.success(i18n.t('resetPassword.linkSent'))
        } catch (err) {
            console.log(err)
        }
    }

    const confirmPasswordReset = async (
        code: string,
        password: string,
        history: RouteComponentProps['history']
    ) => {
        try {
            await firebase.auth().confirmPasswordReset(code, password)
            history.push(Routes.HOME)
            message.success(i18n.t('resetPassword.success'))
        } catch (err) {
            message.error(err.message)
            console.log(err)
        }
    }

    const inviteUsers = async (
        emails: string[],
        projectId: string,
        apolloClient: ApolloClient<object>
    ) => {
        const client = props.apollo
        try {
            await Promise.all(
                emails.map(async email => {
                    const { data } = await client.mutate({
                        mutation: CREATE_USER,
                        variables: { email, projectId },
                        context: {
                            headers: {
                                Authorization: `Bearer ${state.user.token}`
                            }
                        },
                        update(_, { data: { createOneUser } }) {
                            try {
                                const { users }: any = apolloClient.readQuery({
                                    query: UsersDocument,
                                    variables: { projectId }
                                })
                                apolloClient.writeQuery({
                                    query: UsersDocument,
                                    variables: { projectId },
                                    data: {
                                        users: [createOneUser, ...users]
                                    }
                                })
                            } catch (err) {
                                console.log(err)
                            }
                        }
                    })
                    message.success(
                        i18n.t('user.adding.success', { user: data.createOneUser.email })
                    )
                })
            )
        } catch (err) {
            console.log(err)
        }
    }

    const confirmUser = async (values: { email: string; password: string; name: string }) => {
        try {
            const { email, password, name } = values
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)

            await firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(data => {
                    if (!data?.user) return
                    data.user.updateProfile({ displayName: name })
                })
                .catch(err => console.error(err))
        } catch (error) {
            console.log(error)
        }
    }

    const signIn = async (email: string, password: string) => {
        try {
            setState({
                ...state,
                loading: true
            })
            await firebase.auth().signInWithEmailAndPassword(email, password)
        } catch (error) {
            setState({ ...state, loading: false })
            message.error(error.message)
        }
    }

    const signOut = async () => {
        try {
            await firebase.auth().signOut()
        } catch (error) {
            console.log(error)
        }
    }

    const { children } = props
    const value = {
        ...state,
        confirmUser: confirmUser,
        signIn: signIn,
        signOut: signOut,
        setActiveProject: setActiveProject,
        updateUser: updateUser,
        inviteUsers: inviteUsers,
        changeUserPassword: changeUserPassword,
        sendForgotPasswordEmail: sendForgotPasswordEmail,
        confirmPasswordReset: confirmPasswordReset
    }

    return (
        <Auth.Provider value={value}>
            {typeof children === 'function' ? children(value) : children}
        </Auth.Provider>
    )
}

export const Auth = createContext({} as ContextProps)

export default withCookies<Props>(AuthenticationProvider)

const CONFIRM_USER = gql`
    mutation confirmUser($authId: String!, $email: String!, $name: String) {
        confirmUser(authId: $authId, email: $email, name: $name) {
            id
        }
    }
`

const CREATE_USER = gql`
    mutation createUser($email: String!, $projectId: String!) {
        createOneUser(email: $email, projectId: $projectId) {
            id
            authId
            name
            email
            createdAt
        }
    }
`

const UPDATE_USER = gql`
    mutation updateProfile($name: String!, $file: Upload) {
        updateUserProfile(name: $name, file: $file) {
            id
            name
            profileImg
        }
    }
`

const USER_QUERY = gql`
    query getUser {
        me {
            id
            name
            profileImg
            projects {
                id
                name
                logo
                logoSmall
                webUrl
                faqFeature
                suggestionFeature
                restrictionFeature
                blogFeature
                languages
                editorConfig
                admins {
                    email
                }
            }
        }
    }
`
