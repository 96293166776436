import * as Yup from 'yup'
import { TFunction } from 'i18next'
import isString from 'lodash/isString'
import { convertToRaw, EditorState } from 'draft-js'

import { Language } from '../shared'

export interface FormValues {
    title: string
    slug: string
    logoImg: string
    text: EditorState
    language: Language
}

export const creationValidation = (t: TFunction) =>
    Yup.object().shape({
        title: Yup.string().required(t('validation.required')),
        slug: Yup.string().required(t('validation.required')),
        text: Yup.mixed().test(
            'textValidation',
            t('validation.1required'),
            (value: EditorState) => {
                const rawContent = convertToRaw(value.getCurrentContent())
                return (
                    rawContent.blocks.length > 1 ||
                    rawContent.blocks[0].text.length > 0 ||
                    Object.keys(rawContent.entityMap).length > 0
                )
            }
        ),
        logoImg: Yup.mixed()
            .test('fileSize', t('validation.size'), value => {
                if (value) return value.size <= 2000000
                else return true
            })
            .required(t('validation.required')),
        language: Yup.string().required(t('validation.required'))
    })

export const updateValidation = (t: TFunction) =>
    Yup.object().shape({
        title: Yup.string().required(t('validation.required')),
        text: Yup.mixed().test(
            'textValidation',
            t('validation.1required'),
            (value: EditorState) => {
                const rawContent = convertToRaw(value.getCurrentContent())
                return (
                    rawContent.blocks.length > 1 ||
                    rawContent.blocks[0].text.length > 0 ||
                    Object.keys(rawContent.entityMap).length > 0
                )
            }
        ),
        logoImg: Yup.mixed().test('fileSize', t('validation.size'), value => {
            if (isString(value)) return true
            if (value) return value.size <= 2000000
            else return true
        }),
        language: Yup.string().required(t('validation.required'))
    })

const getFriendlyUrl = (string: string) => {
    const nodiac = {
        á: 'a',
        č: 'c',
        ď: 'd',
        é: 'e',
        ě: 'e',
        í: 'i',
        ň: 'n',
        ó: 'o',
        ř: 'r',
        š: 's',
        ť: 't',
        ú: 'u',
        ů: 'u',
        ý: 'y',
        ž: 'z'
    }

    const s = string.toLowerCase()
    let result = ''
    for (let i = 0; i < s.length; i++) {
        result += typeof nodiac[s.charAt(i)] != 'undefined' ? nodiac[s.charAt(i)] : s.charAt(i)
    }
    return result.replace(/[^a-z0-9_]+/g, '-').replace(/^-|-$/g, '')
}

export const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (fieldName: string, value: string) => void
) => {
    setFieldValue('title', event.target.value)
    setFieldValue('slug', getFriendlyUrl(event.target.value))
}
