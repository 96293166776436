/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { message, Row } from 'antd'
import assign from 'lodash/assign'
import isString from 'lodash/isString'
import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

import { FormH1, Label } from '../../../shared/styled'
import { Auth } from '../../../../../context/Authentication'
import EditorInput from '../../../../../components/EditorInput'
import SelectInput from '../../../../../components/SelectInput'
import UploadPreview from '../../../../../components/UploadPreview'
import ConnectedInput from '../../../../../components/ConnectedInput'
import { FormValues, handleTitleChange, updateValidation } from '../shared'
import {
    Language,
    convertStateToHTML,
    convertHTMLToState,
    createLanguageOptions
} from '../../shared'
import {
    H2,
    LeftSide,
    Container,
    ButtonGroup,
    LeftSideCard,
    StyledButton,
    RightSideCard
} from '../../shared/styled'
import {
    useRestrictionQuery,
    RestrictionsDocument,
    useUpdateRestrictionMutation,
    ClientRestrictionVersion
} from '../../../../../generated/graphql'

const RestrictionDetail = ({
    match,
    history,
    location
}: RouteComponentProps<{ id: string; projectId: string }>) => {
    const [t, { language }] = useTranslation()

    const { user } = useContext(Auth)
    const { projectId } = match.params

    const { data, error, loading } = useRestrictionQuery({
        variables: { id: match.params.id },
        fetchPolicy: 'cache-and-network'
    })

    const [updateRestriction, { loading: mutationLoading }] = useUpdateRestrictionMutation({
        refetchQueries: [
            { query: RestrictionsDocument, variables: { projectId } },
            {
                query: RestrictionsDocument,
                variables: { id: match.params.id, projectId }
            }
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
            message.success(t('restriction.editing.success'))
            history.push(`/${projectId}/restrictions`)
        }
    })

    const submit = async (values: Omit<FormValues, 'slug'>) => {
        const adjustedValues = {
            title: values.title,
            language: values.language,
            text: convertStateToHTML(values.text.getCurrentContent()),
            id: match.params.id
        }

        if (!isString(values.logoImg)) {
            assign(adjustedValues, {
                logoImg: values.logoImg
            })
        }

        try {
            await updateRestriction({ variables: adjustedValues })
        } catch (error) {
            console.error(error)
        }
    }

    if (loading || !data || !data?.restriction) {
        return <div>loading..</div>
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const restrictionVersion = getRestrictionVersion(language, data.restriction.versions)

    if (!restrictionVersion) {
        return null
    }

    const text = convertHTMLToState(restrictionVersion.text)

    const initValues = {
        text: text,
        title: restrictionVersion.title,
        logoImg: data.restriction.logoImg,
        language: restrictionVersion.language as Language
    }

    return (
        <Formik<Omit<FormValues, 'slug'>>
            onSubmit={submit}
            initialValues={initValues}
            validationSchema={updateValidation(t)}
        >
            {({ values, isValid, setFieldValue }) => (
                <Form>
                    <Container>
                        <LeftSide>
                            <LeftSideCard>
                                <Row type="flex" justify="space-between" align="middle">
                                    <FormH1>{t('restriction.editing.heading')}</FormH1>
                                </Row>
                                <Label>{t('restriction.shared.title')}</Label>
                                <ConnectedInput
                                    name="title"
                                    size="large"
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleTitleChange(e, setFieldValue)
                                    }
                                />

                                {data.restriction?.slug && (
                                    <Label style={{ marginBottom: 20 }}>
                                        Slug{' '}
                                        <span style={{ fontWeight: 400 }}>
                                            /{data.restriction.slug}
                                        </span>
                                    </Label>
                                )}

                                <Label>{t('restriction.shared.text')}</Label>
                                <EditorInput name="text" />
                            </LeftSideCard>
                        </LeftSide>
                        <RightSideCard>
                            <H2>{t('restriction.shared.settings')}</H2>
                            <Label>{t('restriction.shared.language')}</Label>
                            <SelectInput
                                name="language"
                                onChange={(lang: string) =>
                                    onLangSelect(lang, setFieldValue, data.restriction?.versions)
                                }
                                options={createLanguageOptions(user.activeProject!.languages, t)}
                            />
                            <Label>{t('restriction.shared.image')}</Label>
                            <UploadPreview
                                name="logoImg"
                                accept="image/png,image/jpg,image/jpeg"
                                imageUrl={values.logoImg}
                            />
                            <ButtonGroup>
                                <StyledButton
                                    onClick={() => history.push(`/${projectId}/restrictions`)}
                                >
                                    {t('restriction.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!isValid}
                                    loading={mutationLoading}
                                >
                                    {t('restriction.editing.publish')}
                                </StyledButton>
                            </ButtonGroup>
                        </RightSideCard>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

const onLangSelect = (
    lang: string,
    setFieldValue: (name: string, value: any) => void,
    versions?: ClientRestrictionVersion[] | null
) => {
    const rv = getRestrictionVersion(lang, versions)
    if (rv?.id && rv?.language.toLowerCase() === lang.toLowerCase()) {
        const text = convertHTMLToState(rv.text)
        setFieldValue('text', text)
        setFieldValue('title', rv.title)
    } else {
        setFieldValue('text', convertHTMLToState(''))
        setFieldValue('title', '')
    }
}

const getRestrictionVersion = (
    language: string,
    data?: ClientRestrictionVersion[] | null
): ClientRestrictionVersion | null => {
    if (!data) {
        return null
    }

    const rv = data.find(rv => rv.language.toLowerCase() === language.toLowerCase())

    if (rv?.id) {
        return {
            id: rv.id,
            text: rv.text,
            title: rv.title,
            language: rv.language
        }
    }

    return {
        id: data[0].id,
        text: data[0].text,
        title: data[0].title,
        language: data[0].language
    }
}

export default RestrictionDetail
