import React from 'react'
import { message, Button, Icon } from 'antd'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Formik, FieldArray, FieldArrayRenderProps } from 'formik'

import Item from './Item'
import { Cards } from './styled'
import { getNewItems } from '../shared'
import Input from '../../../../../components/Input'
import Switch from '../../../../../components/Switch'
import { FormH1, Label } from '../../../shared/styled'
import { initialValuesSuggestionItem } from '../shared'
import Checkbox from '../../../../../components/Checkbox'
import DatePicker from '../../../../../components/DatePicker'
import UploadPreview from '../../../../../components/UploadPreview'
import {
    H2,
    LeftSide,
    Container,
    ButtonGroup,
    LeftSideCard,
    RightSideCard,
    StyledButton
} from '../../shared/styled'
import { SuggestionFormValues, suggestionValidation } from '../shared'
import {
    Suggestion,
    SuggestionsDocument,
    useCreateSuggestionMutation
} from '../../../../../generated/graphql'

const initialValuesSuggestion = Object.freeze({
    title: '',
    img: null,
    from: null,
    until: null,
    unlimited: true,
    published: true
})

const SuggestionCreation = ({ history, match }: RouteComponentProps<{ projectId: string }>) => {
    const projectId = match.params.projectId
    const [t] = useTranslation()

    const [createSuggestion, { loading: createSuggestionLoading }] = useCreateSuggestionMutation({
        update(cache, { data }) {
            try {
                const { suggestions }: any = cache.readQuery({
                    query: SuggestionsDocument,
                    variables: { projectId }
                })
                suggestions.push(data?.createOneSuggestion)
                suggestions.sort(
                    (a: Suggestion, b: Suggestion) =>
                        +new Date(b.createdAt) - +new Date(a.createdAt)
                )
                cache.writeQuery({
                    query: SuggestionsDocument,
                    variables: { projectId },
                    data: { suggestions }
                })
            } catch (err) {
                console.error(err)
            }
        },
        onCompleted: data => {
            history.push(`/${projectId}/suggestions`)
            message.success(t('suggestion.creation.success'))
        }
    })

    const isLoading = createSuggestionLoading

    const submit = async (values: SuggestionFormValues) => {
        if (isLoading) return

        const newItems = getNewItems(values.data)

        try {
            await createSuggestion({
                variables: {
                    img: values.img,
                    title: values.title,
                    projectId: projectId,
                    published: values.published,
                    unlimited: values.unlimited,
                    from: values?.from ? values.from : null,
                    until: values?.until ? values.until : null,
                    items: newItems
                        ? newItems.map(i => {
                              return {
                                  text: i.text,
                                  index: i.index,
                                  title: i.title,
                                  img: i.updatedImg
                              }
                          })
                        : undefined
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    const removeItem = (arrayHelpers: FieldArrayRenderProps, idx: number) => {
        for (let i = idx; i < arrayHelpers.form.values.data.length; i++) {
            if (i === idx) {
                arrayHelpers.remove(idx)
            } else {
                arrayHelpers.form.setFieldValue(`data.${idx}.index`, i - 1)
            }
        }
    }

    const addItem = (arrayHelpers: FieldArrayRenderProps) => {
        arrayHelpers.push(initialValuesSuggestionItem(arrayHelpers.form.values.data.length))
    }

    return (
        <Formik<SuggestionFormValues>
            onSubmit={submit}
            validationSchema={suggestionValidation(t)}
            initialValues={{
                ...initialValuesSuggestion,
                projectId,
                data: [{ ...initialValuesSuggestionItem(0) }]
            }}
        >
            {({ values, isValid }) => (
                <Form>
                    <Container>
                        <LeftSide>
                            <LeftSideCard>
                                <FormH1>{t('suggestion.shared.heading')}</FormH1>
                                <Cards>
                                    <FieldArray
                                        name="data"
                                        render={arrayHelpers => (
                                            <>
                                                {values.data.map((i, idx) => (
                                                    <Item
                                                        data={i}
                                                        idx={idx}
                                                        key={i.id}
                                                        arrayHelpers={arrayHelpers}
                                                        remove={() => {
                                                            removeItem(arrayHelpers, idx)
                                                        }}
                                                    />
                                                ))}
                                                <Button
                                                    type="dashed"
                                                    size="large"
                                                    onClick={() => addItem(arrayHelpers)}
                                                >
                                                    <Icon type="plus" />
                                                    {t('suggestion.shared.addSlide')}
                                                </Button>
                                            </>
                                        )}
                                    />
                                </Cards>
                            </LeftSideCard>
                        </LeftSide>
                        <RightSideCard>
                            <H2>{t('suggestion.shared.settings')}</H2>
                            <Label>{t('suggestion.shared.visibility')}</Label>
                            <Switch
                                name="published"
                                checkedChildren={t('suggestion.published')}
                                unCheckedChildren={t('suggestion.unpublished')}
                            />

                            <Label>{t('suggestion.shared.coverTitle')}</Label>
                            <Input name="title" size="large" />

                            <Label>{t('suggestion.shared.coverImg')}</Label>
                            <UploadPreview name="img" accept="image/png,image/jpg,image/jpeg" />

                            <Label>{t('suggestion.shared.publishedPeriod')}</Label>
                            <Checkbox style={{ marginBottom: 15 }} name="unlimited">
                                {t('suggestion.shared.unlimited')}
                            </Checkbox>

                            <DatePicker name="from" />
                            <DatePicker name="until" />

                            <ButtonGroup>
                                <StyledButton
                                    loading={isLoading}
                                    onClick={() => history.push(`/${projectId}/suggestions`)}
                                >
                                    {t('faq.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                    disabled={!isValid}
                                >
                                    {t('faq.shared.save')}
                                </StyledButton>
                            </ButtonGroup>
                        </RightSideCard>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default SuggestionCreation
