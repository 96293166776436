import { Icon } from "antd";
import React from "react";
import styled from "styled-components";

const StyledIcon = styled(Icon)`
  font-size: 32px;
  display: block;
  position: fixed;
  z-index: 1000;
  top: calc(50% - (32px / 2));
  right: calc(50% - (32px / 2));
`;

export default () => <StyledIcon type="loading" spin />;
