import { Form, Icon, message, Upload } from 'antd'
import { Field, FieldConfig, FieldProps } from 'formik'
import React, { useState } from 'react'
import { ErrorText } from '../shared'

const { Item } = Form

interface Props {
    accept?: string
    imageUrl?: string
}

const getBase64 = (img: File, callback: any) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}

const UploadPreview = ({
    accept,
    imageUrl,
    form: { errors, touched, setFieldValue },
    field
}: Props & FieldProps) => {
    const [uploadedImg, setUploadedImg] = useState('')

    const handleImageInsert = (file: File) => {
        if (file.size >= 2000000) {
            message.error('File cannot be larger than 2MB')
        }

        getBase64(file, (fileUrl: string) => {
            setUploadedImg(fileUrl)
        })

        setFieldValue(field.name, file)
        return false
    }

    return (
        <Item validateStatus={touched[field.name] && errors[field.name] ? 'error' : ''}>
            <Upload
                beforeUpload={handleImageInsert}
                accept={accept}
                listType="picture-card"
                showUploadList={false}
            >
                {uploadedImg || imageUrl ? (
                    <img src={uploadedImg || imageUrl} alt="avatar" style={{ width: '100%' }} />
                ) : (
                    <Icon type="upload" />
                )}
            </Upload>
            {touched[field.name] && errors[field.name] && (
                <ErrorText centered>{errors[field.name]}</ErrorText>
            )}
        </Item>
    )
}

export default (props: Omit<FieldConfig, 'component'> & Props) => (
    <Field {...props} component={UploadPreview} />
)
