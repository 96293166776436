import { Button, Card } from 'antd'
import styled from 'styled-components'

import { ThemeProps } from '../../../../theme'

export const Container = styled.div`
    display: flex;
    width: 100%;

    @media screen and (max-width: ${({ theme }: ThemeProps) => theme.resolution.screenLg}) {
        flex-direction: column;
    }
`

export const LeftSide = styled.div`
    flex-grow: 1;
    width: 100%;
    max-width: 700px;
    @media screen and (max-width: ${({ theme }: ThemeProps) => theme.resolution.screenLg}) {
        margin: 0;
    }
`

export const LeftSideCard = styled(Card)`
    margin-bottom: 40px;
`

export const RightSideCard = styled(Card)`
    flex-grow: 1;
    margin-left: 20px;
    height: fit-content;
    @media screen and (max-width: ${({ theme }: ThemeProps) => theme.resolution.screenLg}) {
        width: 100%;
        margin: 0;
    }
`

export const H1 = styled.h1`
    font-size: 32px;
`

export const H2 = styled.h2`
    font-size: 24px;
    margin-bottom: 20px;
`

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`

// ========= POSTS / RESTRICTIONS ========>

export const SoloButton = styled(Button)`
    width: 100%;
`

export const Heading = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Hint = styled.p`
    color: gray;
`

export const FilterIconWrapper = styled.div`
    width: 100% !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
`

export const FilterTitle = styled.div`
    color: rgba(0, 0, 0, 0.85);
    margin-left: 16px;
`

export const RowCentered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

// ========= POSTS / RESTRICTIONS ========|

export const StyledButton = styled(Button)`
    flex-grow: 1;
    :first-child {
        margin-right: 10px;
    }

    :last-child {
        margin-left: 10px;
    }
`
