/* eslint-disable react/display-name */
import { Button, Dropdown, Empty, Icon, Menu, message, Table } from 'antd'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import ConfirmationModal from '../../../../../components/ConfirmationModal'
import Loader from '../../../../../components/Loader'
import { Auth } from '../../../../../context/Authentication'
import {
    useDisconnectUserMutation,
    UsersDocument,
    UsersQuery,
    useUsersQuery
} from '../../../../../generated/graphql'
import {
    Header,
    ListH1,
    PrimaryColumn,
    TableHeader,
    TransparentButton
} from '../../../shared/styled'
import '../../../shared/styles.css'
import UserCreation from '../Creation'
import UserConnection from '../Creation/connect'
import UserDetail from '../Creation/edit'

const { Item } = Menu

const MarginedButton = styled(Button)`
    margin-right: 10px;
`

type User = UsersQuery['users'][0]

export default ({ match }: RouteComponentProps<{ projectId: string }>) => {
    const [t] = useTranslation()
    const { user } = useContext(Auth)

    const { projectId } = match.params

    const [addOpened, toggleAdd] = useState(false)
    const [editOpened, toggleEdit] = useState(false)
    const [connectOpened, toggleConnect] = useState(false)
    const [updatedUser, setUpdatedUser] = useState({ id: '', name: '' })
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [modalState, setModalState] = useState<{
        opened: boolean
        ids: string[] | []
    }>({ opened: false, ids: [] })

    const { data, error, loading } = useUsersQuery({
        variables: { projectId }
    })

    const [disconnectUser] = useDisconnectUserMutation({
        update(cache, { data }) {
            const { users }: any = cache.readQuery({
                query: UsersDocument,
                variables: { projectId }
            })
            const deleteIndex = users.map((user: User) => user.id).indexOf(data?.disconnectUser?.id)
            users.splice(deleteIndex, 1)
            cache.writeQuery({
                query: UsersDocument,
                variables: { projectId },
                data: { users }
            })
        },
        onCompleted: () => {
            message.success(t('user.disconnectSuccess'))
        }
    })

    if (loading || !data) {
        return <Loader />
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const columns = [
        {
            title: t('user.title'),
            dataIndex: 'name',
            render: (name: string, user: User) => (
                <PrimaryColumn
                    onClick={() => {
                        setUpdatedUser({ id: user.id, name })
                        toggleEdit(!editOpened)
                    }}
                >
                    {name}
                </PrimaryColumn>
            ),
            sorter: (a: User, b: User) => (a.name > b.name ? 1 : -1)
        },
        {
            title: t('user.email'),
            dataIndex: 'email'
        },
        {
            title: t('user.createdAt'),
            render: (user: User) => moment(user.createdAt).format('DD.MM.YYYY'),
            sorter: (a: User, b: User) => (a.createdAt > b.createdAt ? 1 : -1)
        },
        {
            title: t('user.status'),
            render: (user: User) => (user.authId ? t('user.verified') : t('user.waiting')),
            sorter: (a: User, b: User) => (a.authId === b.authId ? 0 : a.authId ? -1 : 1)
        },
        {
            title: t('user.action'),
            render: (user: User) => {
                const menu = (
                    <Menu>
                        <Item>
                            <TransparentButton
                                onClick={() => {
                                    setUpdatedUser({ id: user.id, name: user.name })
                                    toggleEdit(!editOpened)
                                }}
                            >
                                {t('user.edit')}
                            </TransparentButton>
                        </Item>
                        <Item>
                            <TransparentButton
                                deleteButton
                                onClick={() =>
                                    setModalState({
                                        opened: true,
                                        ids: [user.id]
                                    })
                                }
                            >
                                {t('user.disconnect')}
                            </TransparentButton>
                        </Item>
                    </Menu>
                )
                return (
                    <Dropdown overlay={menu} trigger={['hover']}>
                        <a>
                            {t('user.more')} <Icon type="down" />
                        </a>
                    </Dropdown>
                )
            }
        }
    ]

    const isSingular = modalState.ids.length <= 1

    return (
        <>
            <Header>
                <ListH1>{t('user.heading')}</ListH1>
                <MarginedButton type="primary" onClick={() => toggleAdd(!addOpened)}>
                    <Icon type="plus" /> {t('user.add')}
                </MarginedButton>
                {user.projects && user.projects.length > 1 && (
                    <Button type="primary" onClick={() => toggleConnect(!connectOpened)}>
                        <Icon type="plus" /> {t('user.connect')}
                    </Button>
                )}
            </Header>
            <ConfirmationModal
                title={
                    isSingular
                        ? t('user.disconnectConfirmTitle')
                        : t('user.disconnectConfirmTitlePl')
                }
                text={
                    isSingular ? t('user.disconnectConfirmText') : t('user.disconnectConfirmTextPl')
                }
                action={() =>
                    modalState.ids.forEach(id =>
                        disconnectUser({
                            variables: {
                                userId: id,
                                projectId
                            }
                        })
                    )
                }
                visible={modalState.opened}
                setState={setModalState}
            />
            {data.users.length ? (
                <Table<User>
                    rowKey={record => record.id}
                    columns={columns}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: selectedKeys => setSelectedRowKeys(selectedKeys as string[])
                    }}
                    dataSource={data.users}
                    pagination={false}
                    title={() => (
                        <TableHeader>
                            <div />
                            <Button
                                type="danger"
                                disabled={!selectedRowKeys.length}
                                onClick={() =>
                                    setModalState({
                                        opened: true,
                                        ids: selectedRowKeys
                                    })
                                }
                            >
                                {t('user.disconnect')}
                            </Button>
                        </TableHeader>
                    )}
                />
            ) : (
                <Empty
                    imageStyle={{
                        height: 160
                    }}
                    description={<span>{t('user.none')}</span>}
                >
                    <MarginedButton type="primary" onClick={() => toggleAdd(!addOpened)}>
                        <Icon type="plus" /> {t('user.add')}
                    </MarginedButton>
                    {user.projects && user.projects.length > 1 && (
                        <Button type="primary" onClick={() => toggleConnect(!connectOpened)}>
                            <Icon type="plus" /> {t('user.connect')}
                        </Button>
                    )}
                </Empty>
            )}
            <UserCreation modalOpened={addOpened} toggleModal={toggleAdd} />
            <UserDetail
                modalOpened={editOpened}
                toggleModal={toggleEdit}
                userDetails={updatedUser}
            />
            {connectOpened && (
                <UserConnection modalOpened={connectOpened} toggleModal={toggleConnect} />
            )}
        </>
    )
}
