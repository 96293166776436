import { Button } from 'antd'
import styled from 'styled-components'

interface IStyled {
    bold?: boolean
    disabled?: boolean
}

export const P = styled.p<IStyled>`
    font-weight: ${props => (props.bold ? 'bold' : '')};
    margin-bottom: 2px;
    color: ${props => (props.disabled ? '#d9d9d9' : '')};
`

export const A = styled.a<IStyled>`
    display: inline-block;
    margin-bottom: 35px;
    font-weight: ${props => (props.bold ? 'bold' : '')};
    color: #000616;
    :hover {
        color: #000616;
    }
`

export const StyledButton = styled(Button)`
    width: 100%;
    margin-bottom: 15px;
`
