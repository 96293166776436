import { Checkbox, Form } from 'antd'
import { Field, FieldConfig, FieldProps } from 'formik'
import React from 'react'
import { ErrorText } from '../shared'

const { Item } = Form

interface Props {
    style?: React.CSSProperties
    children?: JSX.Element | JSX.Element[] | string
}

const CheckboxInput = ({
    style,
    children,
    form: { errors, touched },
    field
}: Props & FieldProps) => (
    <Item
        style={style ? style : { marginBottom: 0 }}
        validateStatus={touched[field.name] && errors[field.name] ? 'error' : ''}
    >
        <Checkbox {...field} checked={field.value}>
            {children}
        </Checkbox>
        {touched[field.name] && errors[field.name] && <ErrorText>{errors[field.name]}</ErrorText>}
    </Item>
)

export default (props: Omit<FieldConfig, 'component'> & Props) => (
    <Field {...props} component={CheckboxInput} />
)
