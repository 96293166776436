import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

const options = {
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: ['translations'],
    defaultNS: 'translations',

    debug: process.env.NODE_ENV === 'development',

    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        format: (value: string, format: string) => {
            if (format === 'uppercase') return value.toUpperCase()
            return value
        }
    },
    wait: process && !process.release
}

if (process && !process.release) {
    i18n.use(XHR)
        .use(initReactI18next)
        .use(LanguageDetector)
}

if (!i18n.isInitialized) {
    // @ts-ignore
    i18n.init(options)
}

export default i18n
