import { Form, Switch } from 'antd'
import { Field, FieldConfig, FieldProps } from 'formik'
import React from 'react'
import { ErrorText } from '../shared'

const { Item } = Form

interface Props {
    checkedChildren?: string
    unCheckedChildren?: string
}

const SwitchInput = ({
    checkedChildren,
    unCheckedChildren,
    form: { errors, touched, setFieldValue },
    field: { name, value }
}: Props & FieldProps) => (
    <Item validateStatus={touched[name] && errors[name] ? 'error' : ''}>
        <Switch
            defaultChecked={value}
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            onChange={event => {
                setFieldValue(name, event)
            }}
        />
        {touched[name] && errors[name] && <ErrorText>{errors[name]}</ErrorText>}
    </Item>
)

export default (props: Omit<FieldConfig, 'component'> & Props) => (
    <Field {...props} component={SwitchInput} />
)
