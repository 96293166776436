import { Form } from 'antd'
import { Field, FieldConfig, FieldProps } from 'formik'
import React from 'react'
import { ErrorText } from '../shared'
import Editor from './Editor'

const { Item } = Form

type Props = {}

const EditorInput = ({ form: { errors, touched, setFieldValue }, field }: Props & FieldProps) => {
    const handleSetFieldValue = (value: any) => {
        setFieldValue(field.name, value)
    }

    return (
        <Item validateStatus={touched[field.name] && errors[field.name] ? 'error' : ''}>
            {touched[field.name] && errors[field.name] && (
                <ErrorText>{errors[field.name]}</ErrorText>
            )}
            <Editor onSetFieldValue={handleSetFieldValue} editorState={field.value} />
        </Item>
    )
}

export default (props: Omit<FieldConfig, 'component'> & Props) => (
    <Field {...props} component={EditorInput} />
)
