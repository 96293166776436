import React from 'react'
import moment from 'moment'
import find from 'lodash/find'
import { message, Button, Icon } from 'antd'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Formik, FieldArray, FieldArrayRenderProps } from 'formik'

import Item from '../Creation/Item'
import { getNewItems } from '../shared'
import { Cards } from '../Creation/styled'
import Input from '../../../../../components/Input'
import Switch from '../../../../../components/Switch'
import { FormH1, Label } from '../../../shared/styled'
import { initialValuesSuggestionItem } from '../shared'
import Checkbox from '../../../../../components/Checkbox'
import DatePicker from '../../../../../components/DatePicker'
import UploadPreview from '../../../../../components/UploadPreview'
import {
    H2,
    LeftSide,
    Container,
    ButtonGroup,
    LeftSideCard,
    RightSideCard,
    StyledButton
} from '../../shared/styled'
import { SuggestionFormValues, suggestionValidation } from '../shared'
import {
    useSuggestionQuery,
    useUpdateSuggestionMutation,
    useDeleteSuggestionItemMutation
} from '../../../../../generated/graphql'

const SuggestionDetail = ({
    history,
    match
}: RouteComponentProps<{ projectId: string; id: string }>) => {
    const id = match.params.id
    const projectId = match.params.projectId

    const [t] = useTranslation()

    const { data, loading, error, refetch } = useSuggestionQuery({
        variables: { id: id },
        fetchPolicy: 'network-only'
    })

    const [updateSuggestion, { loading: updateSuggestionLoading }] = useUpdateSuggestionMutation({
        onCompleted: data => {
            history.push(`/${projectId}/suggestions`)
            message.success(t('suggestion.editing.success'))
        }
    })

    const [
        deleteSuggestionItem,
        { loading: deleteSuggestionItemLoading }
    ] = useDeleteSuggestionItemMutation({
        onCompleted: data => {
            message.success(t('suggestion.editing.deleteItemsuccess'))
        }
    })

    const isLoading = updateSuggestionLoading || deleteSuggestionItemLoading

    const submit = async (values: SuggestionFormValues) => {
        if (isLoading || !data?.suggestion) return
        const newItems = getNewItems(values.data)

        try {
            await updateSuggestion({
                variables: {
                    id: id,
                    items: newItems,
                    title: values.title,
                    updatedImg: values.img,
                    img: data.suggestion.img,
                    published: values.published,
                    unlimited: values.unlimited,
                    from: values?.from ? moment(values.from).toDate() : null,
                    until: values?.until ? moment(values.until).toDate() : null
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    const removeItem = async (arrayHelpers: FieldArrayRenderProps, idx: number, id: string) => {
        if (isLoading) return
        for (let i = idx; i < arrayHelpers.form.values.data.length; i++) {
            if (i === idx) {
                arrayHelpers.remove(idx)
            } else {
                arrayHelpers.form.setFieldValue(`data.${idx}.index`, i - 1)
            }
        }

        if (data?.suggestion?.items) {
            const exists = find(data.suggestion.items, { id: id })
            if (exists) {
                const { data, errors } = await deleteSuggestionItem({ variables: { id: id } })
                if (data && !errors) {
                    await refetch()
                }
                return
            }
        }
    }

    const addItem = async (arrayHelpers: FieldArrayRenderProps) => {
        if (isLoading) return
        arrayHelpers.push(initialValuesSuggestionItem(arrayHelpers.form.values.data.length))
    }

    if (loading || !data?.suggestion) {
        return <span>loading ..</span>
    }

    if (error) {
        console.error(error)
        return <div>error ..</div>
    }

    return (
        <Formik<SuggestionFormValues>
            onSubmit={submit}
            validationSchema={suggestionValidation(t)}
            initialValues={{
                ...data.suggestion,
                projectId,
                data: data.suggestion.items.sort((a, b) => a.index - b.index),
                from: data?.suggestion?.from ? moment(data.suggestion.from) : null,
                until: data?.suggestion?.until ? moment(data.suggestion.until) : null
            }}
        >
            {({ values, isValid }) => (
                <Form>
                    <Container>
                        <LeftSide>
                            <LeftSideCard>
                                <FormH1>{t('suggestion.shared.heading')}</FormH1>
                                <Cards>
                                    <FieldArray
                                        name="data"
                                        render={arrayHelpers => (
                                            <>
                                                {values.data.map((i, idx) => (
                                                    <Item
                                                        data={i}
                                                        idx={idx}
                                                        key={i.id}
                                                        arrayHelpers={arrayHelpers}
                                                        remove={() => {
                                                            removeItem(arrayHelpers, idx, i.id)
                                                        }}
                                                    />
                                                ))}
                                                <Button
                                                    type="dashed"
                                                    size="large"
                                                    onClick={() => addItem(arrayHelpers)}
                                                >
                                                    <Icon type="plus" />{' '}
                                                    {t('suggestion.shared.addSlide')}
                                                </Button>
                                            </>
                                        )}
                                    />
                                </Cards>
                            </LeftSideCard>
                        </LeftSide>
                        <RightSideCard>
                            <H2>{t('suggestion.shared.settings')}</H2>
                            <Label>{t('suggestion.shared.visibility')}</Label>
                            <Switch
                                name="published"
                                checkedChildren={t('suggestion.published')}
                                unCheckedChildren={t('suggestion.unpublished')}
                            />

                            <Label>{t('suggestion.shared.coverTitle')}</Label>
                            <Input name="title" size="large" />

                            <Label>{t('suggestion.shared.coverImg')}</Label>
                            <UploadPreview
                                name="img"
                                imageUrl={values.img}
                                accept="image/png,image/jpg,image/jpeg"
                            />

                            <Label>{t('suggestion.shared.publishedPeriod')}</Label>
                            <Checkbox style={{ marginBottom: 15 }} name="unlimited">
                                {t('suggestion.shared.unlimited')}
                            </Checkbox>

                            <DatePicker name="from" />
                            <DatePicker name="until" />

                            <ButtonGroup>
                                <StyledButton
                                    loading={isLoading}
                                    onClick={() => history.push(`/${projectId}/suggestions`)}
                                >
                                    {t('faq.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                    disabled={!isValid}
                                >
                                    {t('faq.shared.save')}
                                </StyledButton>
                            </ButtonGroup>
                        </RightSideCard>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default SuggestionDetail
