import { message } from 'antd'
import { EditorState } from 'draft-js'
import { Form, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

import Input from '../../../../../components/Input'
import { FormValues, validation } from '../shared'
import Switch from '../../../../../components/Switch'
import { Auth } from '../../../../../context/Authentication'
import { createSelectOptions, Option } from '../../../shared'
import EditorInput from '../../../../../components/EditorInput'
import SelectInput from '../../../../../components/SelectInput'
import { ClickableLabel, FormH1, Label } from '../../../shared/styled'
import { convertStateToHTML, createLanguageOptions } from '../../shared'
import {
    H2,
    LeftSide,
    Container,
    ButtonGroup,
    LeftSideCard,
    RightSideCard,
    StyledButton
} from '../../shared/styled'
import {
    FaqsDocument,
    useCreateFaqMutation,
    useFaqCategoriesQuery
} from '../../../../../generated/graphql'

const FaqCreation = ({ history, location, match }: RouteComponentProps<{ projectId: string }>) => {
    const projectId = match.params.projectId
    const [t] = useTranslation()
    const [largeLinkVisible, toggleLargeLink] = useState(false)
    const { user } = useContext(Auth)
    const { data, loading } = useFaqCategoriesQuery({
        variables: { projectId }
    })
    const [createFaq, { loading: mutationLoading }] = useCreateFaqMutation({
        update(cache, { data }) {
            try {
                const { faqs }: any = cache.readQuery({
                    query: FaqsDocument,
                    variables: { projectId }
                })
                cache.writeQuery({
                    query: FaqsDocument,
                    variables: { projectId },
                    data: { faqs: [data?.createOneFaq, ...faqs] }
                })
            } catch {}
        },
        onCompleted: data => {
            history.push(`/${projectId}/faqs/${data.createOneFaq.id}`)
            message.success(t('faq.creation.success'))
        }
    })

    const languageOptions = createLanguageOptions(user.activeProject!.languages, t)

    const defaultLanguage =
        languageOptions.length === 1
            ? languageOptions[0].value
            : location.state
            ? location.state.selectedLanguage
            : ''

    const initialValues = {
        question: '',
        answer: EditorState.createEmpty(),
        published: true,
        language: defaultLanguage,
        category: '',
        largeLink: '',
        largeLinkTitle: ''
    }

    return (
        <Formik<FormValues>
            initialValues={initialValues}
            validationSchema={validation(t)}
            onSubmit={values => {
                const answer: string = convertStateToHTML(values.answer.getCurrentContent())

                const adjustedValues = {
                    ...values,
                    answer: answer
                }

                createFaq({ variables: { ...adjustedValues, projectId } })
            }}
        >
            {() => (
                <Form>
                    <Container>
                        <LeftSide>
                            <LeftSideCard>
                                <FormH1>{t('faq.creation.heading')}</FormH1>
                                <Label>{t('faq.shared.question')}</Label>
                                <Input name="question" size="large" />
                                <ClickableLabel onClick={() => toggleLargeLink(!largeLinkVisible)}>
                                    {t('faq.shared.largeLink')}
                                </ClickableLabel>
                                {largeLinkVisible && (
                                    <>
                                        <Input name="largeLink" size="large" />
                                        <Label>{t('faq.shared.largeLinkTitle')}</Label>
                                        <Input name="largeLinkTitle" size="large" />
                                    </>
                                )}
                                <Label>{t('faq.shared.answer')}</Label>
                                <EditorInput name="answer" />
                            </LeftSideCard>
                        </LeftSide>
                        <RightSideCard>
                            <H2>{t('faq.shared.settings')}</H2>
                            <Label>{t('faq.shared.visibility')}</Label>
                            <Switch name="published" />
                            <Label>{t('faq.shared.language')}</Label>
                            <SelectInput name="language" options={languageOptions} />
                            <Label>{t('faq.shared.category')}</Label>
                            <SelectInput
                                name="category"
                                allowClear
                                options={
                                    loading
                                        ? []
                                        : createSelectOptions(data?.faqCategories as Option[])
                                }
                            />
                            <ButtonGroup>
                                <StyledButton onClick={() => history.push(`/${projectId}/faqs`)}>
                                    {t('faq.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    htmlType="submit"
                                    type="primary"
                                    loading={mutationLoading}
                                >
                                    {t('faq.shared.save')}
                                </StyledButton>
                            </ButtonGroup>
                        </RightSideCard>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default FaqCreation
