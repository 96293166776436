import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Routes } from '../../../constants'
import { Auth } from '../../../context/Authentication'

interface Props {
    exact?: boolean
    path: string
    component: any
}

export default ({ exact, path, component: Component }: Props) => {
    const { user } = useContext(Auth)

    if (user.qAdmin) {
        return <Redirect to={Routes.ADMIN} />
    }

    if (user.token && user.activeProject) {
        return <Redirect to={`/${user.activeProject.id}`} />
    }

    return <Route exact={exact} path={path} component={Component} />
}
