/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from 'moment'
import { Icon, message } from 'antd'
import { Form, Formik } from 'formik'
import { EditorState } from 'draft-js'
import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ConnectedInput from '../../../../../components/ConnectedInput'
import DatePicker from '../../../../../components/DatePicker'
import EditorInput from '../../../../../components/EditorInput'
import Input from '../../../../../components/Input'
import SelectInput from '../../../../../components/SelectInput'
import TextAreaInput from '../../../../../components/TextAreaInput'
import UploadPreview from '../../../../../components/UploadPreview'
import Popover from '../../shared/Popover'
import { Auth } from '../../../../../context/Authentication'
import { createSelectOptions, Option } from '../../../shared'
import { ClickableLabel, FormH1, Label } from '../../../shared/styled'
import { convertStateToHTML, createLanguageOptions } from '../../shared'
import { creationValidation, FormValues, handleTitleChange, tagsSuggestions } from '../shared'
import {
    Post,
    PostsDocument,
    useCreatePostFormDataQuery,
    useCreatePostMutation
} from '../../../../../generated/graphql'
import {
    H2,
    Hint,
    Heading,
    LeftSide,
    Container,
    SoloButton,
    ButtonGroup,
    LeftSideCard,
    StyledButton,
    RightSideCard
} from '../../shared/styled'

const PostCreation = ({ history, location, match }: RouteComponentProps<{ projectId: string }>) => {
    const [t] = useTranslation()
    const { projectId } = match.params
    const [slugVisible, toggleSlug] = useState(false)
    const { user } = useContext(Auth)
    const { data, loading } = useCreatePostFormDataQuery({
        variables: { projectId }
    })
    const [createPost, { loading: mutationLoading }] = useCreatePostMutation({
        update(cache, { data }) {
            try {
                const { posts }: any = cache.readQuery({
                    query: PostsDocument,
                    variables: { projectId }
                })
                posts.push(data?.createOnePost)
                posts.sort(
                    (a: Post, b: Post) =>
                        +new Date(b.PublishedVersion.createdAt) -
                        +new Date(a.PublishedVersion.createdAt)
                )
                cache.writeQuery({
                    query: PostsDocument,
                    variables: { projectId },
                    data: { posts }
                })
            } catch {}
        },
        onCompleted: data => {
            history.push(`/${projectId}/posts/${data.createOnePost.id}`)
            message.success(t('blog.creation.success'))
        }
    })

    const languageOptions = createLanguageOptions(user.activeProject!.languages, t)

    const defaultLanguage =
        languageOptions.length === 1
            ? languageOptions[0].value
            : location.state
            ? location.state.selectedLanguage
            : ''

    const initialValues = {
        title: '',
        slug: '',
        text: EditorState.createEmpty(),
        excerpt: '',
        published: false,
        language: defaultLanguage,
        categories: [],
        createdAt: moment(),
        mainImg: '',
        author: user.uid!,
        ogTitle: '',
        ogDescription: '',
        tags: []
    }

    return (
        <Formik<FormValues>
            initialValues={initialValues}
            validationSchema={creationValidation(t)}
            onSubmit={values => {
                const adjustedValues = {
                    ...values,
                    text: convertStateToHTML(values.text.getCurrentContent()),
                    tags: values.tags.join(',')
                }
                createPost({
                    variables: {
                        ...adjustedValues,
                        projectId: user.activeProject!.id
                    }
                })
            }}
        >
            {({ setFieldValue, submitForm, handleChange }) => (
                <Form>
                    <Container>
                        <LeftSide>
                            <LeftSideCard>
                                <FormH1>{t('blog.creation.heading')}</FormH1>
                                <Label>{t('blog.shared.title')}</Label>
                                <ConnectedInput
                                    name="title"
                                    size="large"
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleTitleChange(e, setFieldValue)
                                    }
                                />
                                <ClickableLabel onClick={() => toggleSlug(!slugVisible)}>
                                    {t('blog.shared.postLink')}
                                </ClickableLabel>
                                {slugVisible && (
                                    <ConnectedInput
                                        name="slug"
                                        size="large"
                                        handleChange={handleChange}
                                    />
                                )}

                                <Label>{t('blog.shared.excerpt')}</Label>
                                <Input name="excerpt" size="large" maxLength={150} />

                                <Label>{t('blog.shared.text')}</Label>
                                <EditorInput name="text" />
                            </LeftSideCard>
                            <LeftSideCard>
                                <Heading>
                                    <H2>{t('blog.shared.social')}</H2>
                                    <Popover>
                                        <Hint>
                                            <Icon type="question-circle" />
                                            {t('blog.shared.info.title')}
                                        </Hint>
                                    </Popover>
                                </Heading>
                                <Label>{t('blog.shared.ogTitle')}</Label>
                                <Input
                                    name="ogTitle"
                                    placeholder={t('blog.shared.ogTitlePlaceholder')}
                                />
                                <Label>{t('blog.shared.ogDescription')}</Label>
                                <TextAreaInput
                                    name="ogDescription"
                                    autosize={{
                                        minRows: 3,
                                        maxRows: 8
                                    }}
                                    placeholder={t('blog.shared.ogDescriptionPlaceholder')}
                                />
                                <Label>{t('blog.shared.tags')}</Label>
                                <SelectInput name="tags" mode="tags" options={tagsSuggestions} />
                            </LeftSideCard>
                        </LeftSide>
                        <RightSideCard>
                            <H2>{t('blog.shared.settings')}</H2>
                            <Label>{t('blog.shared.language')}</Label>
                            <SelectInput name="language" options={languageOptions} />
                            <Label>{t('blog.shared.categories')}</Label>
                            <SelectInput
                                name="categories"
                                mode="multiple"
                                options={
                                    loading
                                        ? []
                                        : createSelectOptions(data?.postCategories as Option[])
                                }
                            />
                            <Label>{t('blog.shared.date')}</Label>
                            <DatePicker name="createdAt" />
                            <Label>{t('blog.shared.image')}</Label>
                            <UploadPreview name="mainImg" accept="image/png,image/jpg,image/jpeg" />
                            <Label>{t('blog.shared.author')}</Label>
                            <SelectInput
                                name="author"
                                options={
                                    loading ? [] : createSelectOptions(data?.postUsers as Option[])
                                }
                            />
                            <ButtonGroup>
                                <StyledButton onClick={() => history.push(`/${projectId}/posts`)}>
                                    {t('blog.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    htmlType="submit"
                                    type="primary"
                                    loading={mutationLoading}
                                >
                                    {t('blog.creation.save')}
                                </StyledButton>
                            </ButtonGroup>
                            <SoloButton
                                type="primary"
                                loading={mutationLoading}
                                onClick={() => {
                                    setFieldValue('published', true, false)
                                    submitForm()
                                }}
                            >
                                {t('blog.creation.publish')}
                            </SoloButton>
                        </RightSideCard>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default PostCreation
