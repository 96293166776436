/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import moment from 'moment'
import get from 'lodash/get'
import Truncate from 'react-truncate'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Empty, Icon, message, Table, Tooltip } from 'antd'

import '../styles.css'
import '../../../shared/styles.css'
import { AddButton } from '../../styled'
import PrevNext from '../../shared/PrevNext'
import Loader from '../../../../../components/Loader'
import { MenuIcon, IconContainer } from '../../FaqsManagement/shared'
import { Header, ListH1, PrimaryColumn } from '../../../shared/styled'
import ConfirmationModal from '../../../../../components/ConfirmationModal'
import {
    RestrictionsQuery,
    RestrictionsDocument,
    useRestrictionsQuery,
    useDeleteRestrictionMutation
} from '../../../../../generated/graphql'

type Restriction = RestrictionsQuery['restrictions'][0]

interface State {
    opened: boolean
    ids: string[] | []
}

export default ({ history, match }: RouteComponentProps<{ projectId: string }>) => {
    const projectId = match.params.projectId

    const [t] = useTranslation()

    const [restrictions, setRestrictions] = useState<Restriction[]>([])
    const [modalState, setModalState] = useState<State>({
        opened: false,
        ids: []
    })

    const { data, error, loading } = useRestrictionsQuery({
        variables: { projectId }
    })

    const [deleteRestriction] = useDeleteRestrictionMutation({
        update(cache, { data }) {
            const { restrictions }: any = cache.readQuery({
                query: RestrictionsDocument,
                variables: { projectId }
            })
            const deleteIndex = restrictions
                .map((r: Restriction) => r.id)
                .indexOf(data?.deleteOneRestriction?.id)
            restrictions.splice(deleteIndex, 1)
            cache.writeQuery({
                query: RestrictionsDocument,
                variables: { projectId },
                data: { restrictions }
            })
        }
    })

    useEffect(() => {
        if (!data?.restrictions) return
        setRestrictions(data.restrictions)
    }, [data])

    if (loading || !data) {
        return <Loader />
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const columns = [
        {
            width: 280,
            dataIndex: 'title',
            title: t('restriction.title'),
            render: (_: any, record: Restriction) => (
                <PrimaryColumn
                    onClick={() => {
                        history.push(`/${projectId}/restrictions/${record.id}`)
                    }}
                >
                    <Truncate lines={2} ellipsis={<span>...</span>}>
                        {getTitle(record)}
                    </Truncate>
                </PrimaryColumn>
            )
        },
        {
            width: 100,
            dataIndex: 'createdAt',
            title: t('restriction.createdAt'),
            render: (_: any, record: Restriction) => moment(record.createdAt).format('DD.MM.YYYY'),
            sorter: (a: Restriction, b: Restriction) => (a.createdAt > b.createdAt ? 1 : -1)
        },
        {
            width: 100,
            title: t('restriction.action'),
            render: (restriction: Restriction) => {
                return (
                    <IconContainer>
                        <Tooltip title={t('restriction.edit')}>
                            <MenuIcon
                                type="edit"
                                style={{ marginRight: 20 }}
                                onClick={() =>
                                    history.push(`/${projectId}/restrictions/${restriction.id}`)
                                }
                            />
                        </Tooltip>
                        <Tooltip title={t('restriction.delete')}>
                            <MenuIcon
                                type="delete"
                                onClick={() =>
                                    setModalState({ opened: true, ids: [restriction.id] })
                                }
                            />
                        </Tooltip>
                    </IconContainer>
                )
            }
        }
    ]

    const isSingular = modalState.ids.length <= 1

    return (
        <>
            <Header>
                <ListH1>{t('restriction.heading')}</ListH1>
                <AddButton onClick={() => history.push(`/${projectId}/restriction-new`)}>
                    <Icon type="plus" />
                    &nbsp;&nbsp;{t('restriction.add')}
                </AddButton>
            </Header>
            <ConfirmationModal
                title={
                    isSingular
                        ? t('restriction.deleteConfirmTitle')
                        : t('restriction.deleteConfirmTitlePl')
                }
                text={
                    isSingular
                        ? t('restriction.deleteConfirmText')
                        : t('restriction.deleteConfirmTextPl')
                }
                action={() => {
                    modalState.ids.forEach(id => deleteRestriction({ variables: { id } }))
                    message.success(
                        isSingular
                            ? t('restriction.deleteSuccess')
                            : t('restriction.deleteSuccessPl')
                    )
                }}
                visible={modalState.opened}
                setState={setModalState}
            />
            {restrictions.length ? (
                <Table<Restriction>
                    columns={columns}
                    dataSource={restrictions}
                    rowKey={record => record.id}
                    pagination={{
                        position: 'bottom',
                        style: { float: 'left' },
                        defaultPageSize: 10,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <PrevNext type="left" current={current} />
                            }
                            if (type === 'next') {
                                return <PrevNext type="right" current={current} />
                            }
                            return originalElement
                        },
                        showSizeChanger: true
                    }}
                />
            ) : (
                <Empty
                    imageStyle={{ height: 160 }}
                    description={<span>{t('restriction.none')}</span>}
                >
                    <Button
                        type="primary"
                        onClick={() => history.push(`/${projectId}/restriction-new`)}
                    >
                        <Icon type="plus" />
                        {t('restriction.add')}
                    </Button>
                </Empty>
            )}
        </>
    )
}

const getTitle = (record: Restriction): string => {
    const rv = record.versions?.find(rv => rv.language.toLowerCase() === 'en')
    if (rv?.id) {
        return get(rv, 'title', '')
    } else {
        return get(record, 'versions.0.title', '')
    }
}
