import { Button, Dropdown, Empty, Icon, Menu, message, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import Loader from "../../../../../components/Loader";
import { Routes } from "../../../../../constants";
import {
  CompaniesDocument,
  CompaniesQuery,
  useCompaniesQuery,
  useDeleteCompanyMutation
} from "../../../../../generated/graphql";
import {
  Header,
  ListH1,
  PrimaryColumn,
  TransparentButton
} from "../../../shared/styled";
import "../../../shared/styles.css";

const { Item } = Menu;

type Company = CompaniesQuery["companies"][0];

export default ({ history }: RouteComponentProps) => {
  const [t] = useTranslation();
  const { data, error, loading } = useCompaniesQuery();
  const [deleteCompany] = useDeleteCompanyMutation({
    update(cache, { data }) {
      const { companies }: any = cache.readQuery({
        query: CompaniesDocument
      });
      const deleteIndex = companies
        .map((company: any) => company.id)
        .indexOf(data?.deleteOneCompany?.id);
      companies.splice(deleteIndex, 1);
      cache.writeQuery({
        query: CompaniesDocument,
        data: { companies }
      });
    },
    onCompleted: () => {
      message.success(t("company.deleteSuccess"));
    }
  });

  if (loading || !data) {
    return <Loader />;
  }

  if (error) {
    console.log(error);
    return <div>error..</div>;
  }

  const columns = [
    {
      title: t("company.title"),
      dataIndex: "name",
      render: (name: string, record: Company) => (
        <PrimaryColumn
          onClick={() => history.push(`${Routes.COMPANIES}/${record.id}`)}
        >
          {name}
        </PrimaryColumn>
      ),
      sorter: (a: Company, b: Company) => (a.name > b.name ? 1 : -1)
    },
    {
      title: t("company.action"),
      width: 100,
      render: (company: Company) => {
        const menu = (
          <Menu>
            <Item>
              <TransparentButton
                onClick={() =>
                  history.push(`${Routes.COMPANIES}/${company.id}`)
                }
              >
                {t("company.edit")}
              </TransparentButton>
            </Item>
            <Item>
              <TransparentButton
                deleteButton
                onClick={() =>
                  deleteCompany({
                    variables: { id: company.id }
                  })
                }
              >
                {t("company.delete")}
              </TransparentButton>
            </Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["hover"]}>
            <a>
              {t("company.more")} <Icon type="down" />
            </a>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <>
      <Header>
        <ListH1>{t("company.heading")}</ListH1>
        <Link to={Routes.COMPANY_CREATION}>
          <Button type="primary">
            <Icon type="plus" /> {t("company.add")}
          </Button>
        </Link>
      </Header>
      {data.companies.length ? (
        <Table<Company>
          rowKey={record => record.id}
          columns={columns}
          dataSource={data.companies}
          pagination={false}
        />
      ) : (
        <Empty
          imageStyle={{
            height: 160
          }}
          description={<span>{t("company.none")}</span>}
        >
          <Button
            type="primary"
            onClick={() => history.push(Routes.COMPANY_CREATION)}
          >
            <Icon type="plus" /> {t("company.add")}
          </Button>
        </Empty>
      )}
    </>
  );
};
