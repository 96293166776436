import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 70%;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RowItem = styled.div`
  flex-grow: 1;
  flex-basis: 45%;
  margin-right: 15px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const StyledButton = styled(Button)`
  margin-right: 15px;
`;
