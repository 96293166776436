import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Input from '../../../components/Input'
import { Routes } from '../../../constants'
import { Auth } from '../../../context/Authentication'
import Layout from '../Layout'
import { A, P, StyledButton } from '../shared'

interface FormValues {
    email: string
}

const validation = (t: TFunction) =>
    Yup.object().shape({
        email: Yup.string()
            .email(t('validation.email'))
            .required(t('validation.required'))
    })

const ForgotPassword = () => {
    const [t] = useTranslation()
    const { sendForgotPasswordEmail } = useContext(Auth)
    return (
        <Layout heading1={t('forgotPassword.heading')} heading2={t('forgotPassword.description')}>
            <Formik<FormValues>
                initialValues={{ email: '' }}
                validationSchema={validation(t)}
                onSubmit={({ email }) => {
                    sendForgotPasswordEmail(email)
                }}
            >
                {() => (
                    <Form>
                        <P bold>{t('forgotPassword.email')}</P>
                        <Input name="email" icon="user" placeholder={t('forgotPassword.email')} />
                        <StyledButton htmlType="submit" type="primary">
                            {t('forgotPassword.reset')}
                        </StyledButton>
                    </Form>
                )}
            </Formik>
            <A href={Routes.HOME}>{t('forgotPassword.back')}</A>
        </Layout>
    )
}

export default ForgotPassword
