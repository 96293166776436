import { Modal } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'

import './styles.css'
import { ButtonGroup, StyledButton, Text } from './styled'
import { H2 } from '../../pages/Protected/Private/shared/styled'

interface Props {
    title: string
    text: string
    visible: boolean
    setState: Dispatch<SetStateAction<{ opened: boolean; ids: string[] | [] }>>
    action: () => void
}

const ConfirmationModal = ({ title, text, visible, setState, action }: Props) => {
    return (
        <Modal
            visible={visible}
            onCancel={() => setState({ opened: false, ids: [] })}
            footer={null}
        >
            <H2>{title}</H2>
            <Text>{text}</Text>
            <ButtonGroup>
                <StyledButton onClick={() => setState({ opened: false, ids: [] })}>
                    Cancel
                </StyledButton>
                <StyledButton
                    type="primary"
                    onClick={() => {
                        action()
                        setState({ opened: false, ids: [] })
                    }}
                >
                    OK
                </StyledButton>
            </ButtonGroup>
        </Modal>
    )
}

export default ConfirmationModal
