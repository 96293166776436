import { TFunction } from "i18next";
import * as Yup from "yup";

export interface FormValues {
  name: string;
}

export const validation = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t("validation.required"))
  });
