import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import queryString from 'query-string'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import * as Yup from 'yup'
import Input from '../../../components/Input'
import { Auth } from '../../../context/Authentication'
import Layout from '../Layout'
import { P, StyledButton } from '../shared'

interface FormValues {
    password: string
}

const validation = (t: TFunction) =>
    Yup.object().shape({
        password: Yup.string()
            .min(6, t('validation.password'))
            .required(t('validation.required'))
    })

const ResetPassword = ({ history, location }: RouteComponentProps) => {
    const [t] = useTranslation()
    const { confirmPasswordReset } = useContext(Auth)
    const { oobCode } = queryString.parse(location.search)
    return (
        <Layout heading1={t('resetPassword.heading')} heading2={t('resetPassword.description')}>
            <Formik<FormValues>
                initialValues={{ password: '' }}
                validationSchema={validation(t)}
                onSubmit={({ password }) => {
                    confirmPasswordReset(oobCode as string, password, history)
                }}
            >
                {() => (
                    <Form>
                        <P bold>{t('resetPassword.password')}</P>
                        <Input
                            name="password"
                            icon="lock"
                            type="password"
                            placeholder={t('resetPassword.password')}
                        />
                        <StyledButton htmlType="submit" type="primary">
                            {t('resetPassword.reset')}
                        </StyledButton>
                    </Form>
                )}
            </Formik>
        </Layout>
    )
}

export default ResetPassword
