import { Button } from 'antd'
import { XYCoord } from 'dnd-core'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArrayRenderProps } from 'formik'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'

import { Label } from '../../../../shared/styled'
import Input from '../../../../../../components/Input'
import { Wrapper, Head, Title } from './styled'
import UploadPreview from '../../../../../../components/UploadPreview'

const ItemTypes = {
    CARD: 'card'
}

interface Props {
    data: {
        img: any
        id: string
        text: string
        title: string
    }
    idx: number
    remove: () => void
    arrayHelpers: FieldArrayRenderProps
}

interface DragItem {
    type: string
    index: number
}

const SuggestionItem = ({ idx, arrayHelpers, data, remove }: Props) => {
    const [t] = useTranslation()
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            }
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = idx

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            move(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        }
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id: data.id, index: idx }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        })
    })

    const move = (dragIndex: number, hoverIndex: number) => {
        arrayHelpers.form.setFieldValue(
            `data.${dragIndex}.index`,
            arrayHelpers.form.values.data[hoverIndex].index
        )
        arrayHelpers.form.setFieldValue(
            `data.${hoverIndex}.index`,
            arrayHelpers.form.values.data[dragIndex].index
        )
        arrayHelpers.swap(dragIndex, hoverIndex)
    }

    drag(drop(ref))

    return (
        <Wrapper ref={ref} data-handler-id={handlerId}>
            <Head>
                <Title>
                    {t('suggestion.item.slide')} {idx}
                </Title>
                <Button type="danger" onClick={remove}>
                    {t('suggestion.item.delete')}
                </Button>
            </Head>
            <Label>{t('suggestion.item.title')}</Label>
            <Input name={`data.${idx}.title`} />

            <Label>{t('suggestion.item.text')}</Label>
            <Input name={`data.${idx}.text`} type="textarea" />

            <Label>{t('suggestion.item.img')}</Label>
            <UploadPreview
                imageUrl={data.img}
                name={`data.${idx}.img`}
                accept="image/png,image/jpg,image/jpeg"
            />
        </Wrapper>
    )
}

export default SuggestionItem
