import styled from 'styled-components'

export const MarginedSpan = styled.span`
    margin-left: 10px;
`

export const RowItemCollapsed = styled.div`
    margin-right: 20px;
`

export interface FormValues {
    name: string
    webUrl?: string
    logo: File | null
    logoSmall: File | null
    email: string
    companyId: string
    faq: boolean
    restriction: boolean
    suggestion: boolean
    blog: boolean
    cs?: boolean
    sk?: boolean
    en?: boolean
    de?: boolean
    pl?: boolean
    encz?: boolean
    ensk?: boolean
    enpl?: boolean
    fontFamily: string
    fontSize: string
    h1FontFamily: string
    h1FontSize: string
    h2FontFamily: string
    h2FontSize: string
    h3FontFamily: string
    h3FontSize: string
    h4FontFamily: string
    h4FontSize: string
}

export const fontFamilyOptions = [
    { text: 'Arial', value: 'Arial' },
    { text: 'Georgia', value: 'Georgia' },
    { text: 'Impact', value: 'Impact' },
    { text: 'Tahoma', value: 'Tahoma' },
    { text: 'Times New Roman', value: 'Times New Roman' },
    { text: 'Verdana', value: 'Verdana' },
    { text: 'Montserrat', value: 'Montserrat' },
    { text: 'Open Sans', value: 'Open Sans' },
    { text: 'Sans Serif', value: 'sans-serif' },
    { text: 'Etelka Text Pro', value: 'EtelkaTextPro' },
    { text: 'Etelka Light Pro', value: 'EtelkaLightPro' },
    { text: 'Proxima Nova', value: 'Proxima Nova' }
]

export const fontSizeOptions = [
    { text: '8', value: '8' },
    { text: '9', value: '9' },
    { text: '10', value: '10' },
    { text: '11', value: '11' },
    { text: '12', value: '12' },
    { text: '14', value: '14' },
    { text: '16', value: '16' },
    { text: '18', value: '18' },
    { text: '20', value: '20' },
    { text: '22', value: '22' },
    { text: '24', value: '24' },
    { text: '26', value: '26' },
    { text: '28', value: '28' },
    { text: '30', value: '30' },
    { text: '36', value: '36' },
    { text: '48', value: '48' },
    { text: '60', value: '60' },
    { text: '72', value: '72' },
    { text: '96', value: '96' }
]
