import { Card, message } from 'antd'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import Input from '../../../../../components/Input'
import {
    CompaniesDocument,
    useCompanyQuery,
    useUpdateCompanyMutation
} from '../../../../../generated/graphql'
import { FormH1, Label } from '../../../shared/styled'
import { ButtonGroup, Container, Row, RowItem, StyledButton } from '../../shared'
import { FormValues, validation } from '../shared'

const CompanyDetail = ({ history, match }: RouteComponentProps<{ id: string }>) => {
    const [t] = useTranslation()

    const { data, loading, error } = useCompanyQuery({
        variables: { id: match.params.id }
    })

    const [updateCompany, { loading: mutationLoading }] = useUpdateCompanyMutation({
        update(cache, { data }) {
            try {
                const { companies }: any = cache.readQuery({
                    query: CompaniesDocument
                })
                const updateIndex = companies.findIndex(
                    (company: any) => company.id === data?.updateOneCompany?.id
                )
                companies[updateIndex] = data?.updateOneCompany
                cache.writeQuery({
                    query: CompaniesDocument,
                    data: { companies }
                })
            } catch {}
        },
        onCompleted: () => {
            message.success(t('company.editing.success'))
        }
    })

    if (loading || !data) {
        return <div>loading..</div>
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    return (
        <Container>
            <Formik<FormValues>
                initialValues={{
                    name: data.company?.name!
                }}
                validationSchema={validation(t)}
                onSubmit={values => {
                    updateCompany({
                        variables: { ...values, id: match.params.id }
                    })
                }}
            >
                {() => (
                    <Form>
                        <Card>
                            <FormH1>{t('company.editing.heading')}</FormH1>
                            <Row>
                                <RowItem>
                                    <Label>{t('company.shared.title')}</Label>
                                    <Input name="name" size="large" />
                                </RowItem>
                            </Row>
                            <ButtonGroup>
                                <StyledButton onClick={() => history.goBack()}>
                                    {t('company.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    htmlType="submit"
                                    type="primary"
                                    loading={mutationLoading}
                                >
                                    {t('company.shared.save')}
                                </StyledButton>
                            </ButtonGroup>
                        </Card>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CompanyDetail
