/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from 'moment'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Icon, message, Row, Switch, Tag } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import React, { useContext, useState, useEffect } from 'react'

import Popover from '../../shared/Popover'
import Input from '../../../../../components/Input'
import { Auth } from '../../../../../context/Authentication'
import DatePicker from '../../../../../components/DatePicker'
import { createSelectOptions, Option } from '../../../shared'
import EditorInput from '../../../../../components/EditorInput'
import SelectInput from '../../../../../components/SelectInput'
import TextAreaInput from '../../../../../components/TextAreaInput'
import UploadPreview from '../../../../../components/UploadPreview'
import ConnectedInput from '../../../../../components/ConnectedInput'
import { ClickableLabel, FormH1, Label } from '../../../shared/styled'
import {
    Language,
    convertHTMLToState,
    convertStateToHTML,
    createLanguageOptions
} from '../../shared'
import {
    PostsDocument,
    usePostQuery,
    useUpdatePostMutation
} from '../../../../../generated/graphql'
import {
    Category,
    FormValues,
    tagsSuggestions,
    updateValidation,
    handleTitleChange
} from '../shared'
import {
    H2,
    Hint,
    Heading,
    LeftSide,
    Container,
    SoloButton,
    ButtonGroup,
    StyledButton,
    LeftSideCard,
    RightSideCard
} from '../../shared/styled'

const PostDetail = ({ history, match }: RouteComponentProps<{ id: string; projectId: string }>) => {
    const { projectId } = match.params

    const [t] = useTranslation()

    const { user } = useContext(Auth)

    const [slugVisible, toggleSlug] = useState(false)
    const [showingPublished, togglePost] = useState(true)

    const { data, error, loading } = usePostQuery({
        variables: { id: match.params.id, projectId },
        fetchPolicy: 'cache-and-network'
    })

    const [updatePost, { loading: mutationLoading }] = useUpdatePostMutation({
        refetchQueries: [
            { query: PostsDocument, variables: { projectId } },
            {
                query: PostsDocument,
                variables: { id: match.params.id, projectId }
            }
        ],
        awaitRefetchQueries: true,
        onCompleted: (data: any) => {
            message.success(t('blog.editing.success'))
            history.push(`/${projectId}/posts`)
        }
    })

    const handleTogglePost = (setValues: (values: FormValues) => void, data: TODO_ANY) => {
        if (!data?.post) return
        const text = convertHTMLToState(data.post?.PublishedVersion.text)

        if (!showingPublished) {
            setValues({
                text: text,
                title: data.post.PublishedVersion.title,
                slug: data.post.PublishedVersion.slug,
                excerpt: data.post.PublishedVersion.excerpt,
                language: data.post.PublishedVersion.language,
                published: data.post.PublishedVersion.published,
                mainImg: data.post.PublishedVersion.mainImg,
                categories: data.post.PublishedVersion.categories.map(
                    (category: Category) => category.id
                ),
                author: data.post.PublishedVersion.Author.authId,
                createdAt: moment(data.post.PublishedVersion.createdAt),
                ogTitle: data.post.PublishedVersion.ogTitle,
                ogDescription: data.post.PublishedVersion.ogDescription,
                tags: data.post.PublishedVersion.tags
                    ? data.post.PublishedVersion.tags.split(',')
                    : [],
                updatedImg: '',
                isDraft: false
            })
        } else {
            setValues({
                text: text,
                title: data.post.DraftedVersion.title,
                slug: data.post.DraftedVersion.slug,
                excerpt: data.post.DraftedVersion.excerpt,
                language: data.post.DraftedVersion.language,
                published: data.post.DraftedVersion.published,
                mainImg: data.post.DraftedVersion.mainImg,
                categories: data.post.DraftedVersion.categories.map(
                    (category: Category) => category.id
                ),
                author: data.post.DraftedVersion.Author.authId,
                createdAt: moment(data.post.DraftedVersion.createdAt),
                ogTitle: data.post.DraftedVersion.ogTitle,
                ogDescription: data.post.DraftedVersion.ogDescription,
                tags: data.post.DraftedVersion.tags ? data.post.DraftedVersion.tags.split(',') : [],
                updatedImg: '',
                isDraft: true
            })
        }

        togglePost(!showingPublished)
    }

    useEffect(() => {
        if (data?.post?.PublishedVersion && !data?.post?.PublishedVersion?.slug) {
            message.error(t('notification.slugNotExists'))
        }
    }, [data])

    if (loading || !data || !data?.post) {
        return <div>loading..</div>
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const submit = async (values: FormValues) => {
        const adjustedValues = {
            ...values,
            projectId,
            id: match.params.id,
            tags: values.tags.join(','),
            isDraft: Boolean(values?.isDraft),
            draftedPostId: data.post?.DraftedVersion.id || '',
            publishedPostId: data.post?.PublishedVersion.id || '',
            text: convertStateToHTML(values.text.getCurrentContent())
        }
        // @ts-ignore
        updatePost({ variables: adjustedValues })
    }

    return (
        <Formik<FormValues>
            initialValues={{
                text: convertHTMLToState(data.post.PublishedVersion.text),
                title: data.post?.PublishedVersion.title!,
                excerpt: data.post?.PublishedVersion.excerpt!,
                slug: data.post?.PublishedVersion.slug!,
                published: data.post?.PublishedVersion.published!,
                language: data.post?.PublishedVersion.language! as Language,
                mainImg: data.post?.PublishedVersion.mainImg!,
                author: data.post?.PublishedVersion.Author.authId!,
                categories:
                    data.post?.PublishedVersion.categories?.map(category => category.id) || [],
                createdAt: moment(data.post?.PublishedVersion.createdAt!),
                ogTitle: data.post?.PublishedVersion.ogTitle!,
                ogDescription: data.post?.PublishedVersion.ogDescription!,
                tags: data.post?.PublishedVersion.tags
                    ? data.post?.PublishedVersion.tags.split(',')
                    : [],
                updatedImg: '',
                isDraft: false
            }}
            onSubmit={submit}
            validationSchema={updateValidation(t)}
        >
            {({ values, setValues, submitForm, setFieldValue, handleChange }) => (
                <Form>
                    <Container>
                        <LeftSide>
                            <LeftSideCard>
                                <Row type="flex" justify="space-between" align="middle">
                                    <FormH1>{t('blog.editing.heading')}</FormH1>
                                    {showingPublished ? (
                                        <Tag color="green">{t('blog.editing.post')}</Tag>
                                    ) : (
                                        <Tag color="red">{t('blog.editing.draft')}</Tag>
                                    )}
                                </Row>
                                <Label>{t('blog.shared.title')}</Label>
                                <ConnectedInput
                                    name="title"
                                    size="large"
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleTitleChange(e, setFieldValue)
                                    }
                                />
                                <ClickableLabel onClick={() => toggleSlug(!slugVisible)}>
                                    {t('blog.shared.postLink')}
                                </ClickableLabel>
                                {slugVisible && (
                                    <ConnectedInput
                                        name="slug"
                                        size="large"
                                        handleChange={handleChange}
                                    />
                                )}

                                <Label>{t('blog.shared.excerpt')}</Label>
                                <Input
                                    name="excerpt"
                                    size="large"
                                    placeholder={t('blog.shared.excerpt')}
                                    maxLength={150}
                                />

                                <Label>{t('blog.shared.text')}</Label>
                                <EditorInput name="text" />
                            </LeftSideCard>
                            <LeftSideCard>
                                <Heading>
                                    <H2>{t('blog.shared.social')}</H2>
                                    <Popover>
                                        <Hint>
                                            <Icon type="question-circle" />
                                            {t('blog.shared.info.title')}
                                        </Hint>
                                    </Popover>
                                </Heading>
                                <Label>{t('blog.shared.ogTitle')}</Label>
                                <Input
                                    name="ogTitle"
                                    placeholder={t('blog.shared.ogTitlePlaceholder')}
                                />
                                <Label>{t('blog.shared.ogDescription')}</Label>
                                <TextAreaInput
                                    name="ogDescription"
                                    autosize={{
                                        minRows: 3,
                                        maxRows: 8
                                    }}
                                    placeholder={t('blog.shared.ogDescriptionPlaceholder')}
                                />
                                <Label>{t('blog.shared.tags')}</Label>
                                <SelectInput name="tags" mode="tags" options={tagsSuggestions} />
                            </LeftSideCard>
                        </LeftSide>
                        <RightSideCard>
                            <H2>{t('blog.shared.settings')}</H2>
                            <Label>{t('blog.editing.postDraft')}</Label>
                            <Switch
                                style={{ marginBottom: 15 }}
                                defaultChecked
                                onChange={() => handleTogglePost(setValues, data)}
                            />
                            <Label>{t('blog.shared.language')}</Label>
                            <SelectInput
                                name="language"
                                options={createLanguageOptions(user.activeProject!.languages, t)}
                            />
                            <Label>{t('blog.shared.categories')}</Label>
                            <SelectInput
                                name="categories"
                                mode="multiple"
                                options={
                                    loading
                                        ? []
                                        : createSelectOptions(data.postCategories as Option[])
                                }
                            />
                            <Label>{t('blog.shared.date')}</Label>
                            <DatePicker name="createdAt" />
                            <Label>{t('blog.shared.image')}</Label>
                            <UploadPreview
                                name="updatedImg"
                                accept="image/png,image/jpg,image/jpeg"
                                imageUrl={values.mainImg}
                            />
                            <Label>{t('blog.shared.author')}</Label>
                            <SelectInput
                                name="author"
                                options={
                                    loading ? [] : createSelectOptions(data.postUsers as Option[])
                                }
                            />
                            <ButtonGroup>
                                <StyledButton onClick={() => history.push(`/${projectId}/posts`)}>
                                    {t('blog.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    onClick={() => {
                                        setFieldValue('published', false, false)
                                        submitForm()
                                    }}
                                    type="primary"
                                    loading={mutationLoading}
                                >
                                    {showingPublished
                                        ? t('blog.editing.save')
                                        : t('blog.editing.saveDraft')}
                                </StyledButton>
                            </ButtonGroup>
                            <SoloButton
                                onClick={() => {
                                    setFieldValue('published', true, false)
                                    submitForm()
                                }}
                                type="primary"
                                loading={mutationLoading}
                            >
                                {t('blog.editing.publish')}
                            </SoloButton>
                        </RightSideCard>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default PostDetail
