import styled from 'styled-components'

export const AddButton = styled.button`
    background: #1890ff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;

    padding: 8px 16px;
    border: none;
`

export const CategoryLabel = styled.div`
    display: inline-block;
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;

    padding: 0 8px;
    margin: 0 8px 8px 0;

    font-size: 12px;
    line-height: 20px;
    color: #595959;
`
