import { message } from 'antd'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { FormValues, validation } from '../shared'
import Input from '../../../../../components/Input'
import Switch from '../../../../../components/Switch'
import { Auth } from '../../../../../context/Authentication'
import { createSelectOptions, Option } from '../../../shared'
import SelectInput from '../../../../../components/SelectInput'
import EditorInput from '../../../../../components/EditorInput'
import { ClickableLabel, FormH1, Label } from '../../../shared/styled'
import { FaqsDocument, useFaqQuery, useUpdateFaqMutation } from '../../../../../generated/graphql'
import {
    Language,
    convertHTMLToState,
    convertStateToHTML,
    createLanguageOptions
} from '../../shared'
import {
    H2,
    LeftSide,
    Container,
    ButtonGroup,
    StyledButton,
    LeftSideCard,
    RightSideCard
} from '../../shared/styled'

const FaqDetail = ({ history, match }: RouteComponentProps<{ id: string; projectId: string }>) => {
    const { projectId } = match.params

    const [t] = useTranslation()
    const { user } = useContext(Auth)

    const [largeLinkVisible, toggleLargeLink] = useState(false)

    const { data, loading, error } = useFaqQuery({
        variables: { id: match.params.id, projectId }
    })

    const [updateFaq, { loading: mutationLoading }] = useUpdateFaqMutation({
        update(cache, { data }) {
            try {
                const { faqs }: any = cache.readQuery({
                    query: FaqsDocument,
                    variables: { projectId }
                })
                const updateIndex = faqs.findIndex((faq: any) => faq.id === data?.updateOneFaq?.id)
                faqs[updateIndex] = data?.updateOneFaq
                cache.writeQuery({
                    query: FaqsDocument,
                    variables: { projectId },
                    data: { faqs }
                })
            } catch {}
        },
        onCompleted: () => {
            message.success(t('faq.editing.success'))
            history.push(`/${projectId}/faqs`)
        }
    })

    if (loading || !data || !data?.faq) {
        return <div>loading..</div>
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    return (
        <Formik<FormValues>
            initialValues={{
                question: data.faq?.question!,
                published: data.faq?.published!,
                category: data.faq?.Category?.id!,
                largeLink: data.faq?.largeLink!,
                language: data.faq?.language as Language,
                largeLinkTitle: data.faq?.largeLinkTitle!,
                answer: convertHTMLToState(data.faq.answer)
            }}
            validationSchema={validation(t)}
            onSubmit={values => {
                const adjustedValues = {
                    ...values,
                    answer: convertStateToHTML(values.answer.getCurrentContent()),
                    id: match.params.id
                }
                updateFaq({ variables: adjustedValues })
            }}
        >
            {() => (
                <Form>
                    <Container>
                        <LeftSide>
                            <LeftSideCard>
                                <FormH1>{t('faq.editing.heading')}</FormH1>
                                <Label>{t('faq.shared.question')}</Label>
                                <Input name="question" size="large" />
                                <ClickableLabel onClick={() => toggleLargeLink(!largeLinkVisible)}>
                                    {t('faq.shared.largeLink')}
                                </ClickableLabel>
                                {largeLinkVisible && (
                                    <>
                                        <Input name="largeLink" size="large" />
                                        <Label>{t('faq.shared.largeLinkTitle')}</Label>
                                        <Input name="largeLinkTitle" size="large" />
                                    </>
                                )}
                                <Label>{t('faq.shared.answer')}</Label>
                                <EditorInput name="answer" />
                            </LeftSideCard>
                        </LeftSide>
                        <RightSideCard>
                            <H2>{t('faq.shared.settings')}</H2>
                            <Label>{t('faq.shared.visibility')}</Label>
                            <Switch name="published" />
                            <Label>{t('faq.shared.language')}</Label>
                            <SelectInput
                                name="language"
                                options={createLanguageOptions(user.activeProject!.languages, t)}
                            />
                            <Label>{t('faq.shared.category')}</Label>
                            <SelectInput
                                name="category"
                                allowClear
                                options={
                                    loading
                                        ? []
                                        : createSelectOptions(data.faqCategories as Option[])
                                }
                            />
                            <ButtonGroup>
                                <StyledButton onClick={() => history.push(`/${projectId}/faqs`)}>
                                    {t('faq.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    htmlType="submit"
                                    type="primary"
                                    loading={mutationLoading}
                                >
                                    {t('faq.shared.save')}
                                </StyledButton>
                            </ButtonGroup>
                        </RightSideCard>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default FaqDetail
