import React from 'react'
import { Form, Select } from 'antd'
import { Field, FieldConfig, FieldProps } from 'formik'

import { ErrorText } from '../shared'

const { Option } = Select
const { Item } = Form

interface Props {
    options: { value: string; text: string }[]
    mode?: 'default' | 'multiple' | 'tags'
    required?: boolean
    onChange?: (value: string) => void
    allowClear?: boolean
    disabled?: boolean
    size?: 'small' | 'default' | 'large'
    style?: React.CSSProperties
}

const SelectInput = ({
    options,
    mode,
    required,
    allowClear,
    onChange,
    disabled,
    size,
    form: { errors, touched, setFieldTouched, setFieldValue },
    field,
    style
}: Props & FieldProps) => (
    <Item
        validateStatus={touched[field.name] && errors[field.name] ? 'error' : ''}
        required={required}
        style={style}
    >
        <Select
            {...field}
            mode={mode}
            size={size}
            disabled={disabled}
            allowClear={allowClear}
            onChange={(value: string) => {
                setFieldValue(field.name, value || null)
                if (onChange) onChange(value)
            }}
            onBlur={() => setFieldTouched(field.name)}
        >
            {options.map(option => (
                <Option key={option.value} value={option.value}>
                    {option.text}
                </Option>
            ))}
        </Select>
        {touched[field.name] && errors[field.name] && <ErrorText>{errors[field.name]}</ErrorText>}
    </Item>
)

export default (props: Omit<FieldConfig, 'component'> & Props) => {
    return <Field {...props} component={SelectInput} />
}
