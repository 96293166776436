import styled from 'styled-components'

interface Styled {
    centered?: boolean
}

export const ErrorText = styled.div<Styled>`
    color: red;
    font-size: 12px;
    text-align: ${props => (props.centered ? 'center' : '')};
`
