/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { message } from 'antd'
import { Form, Formik } from 'formik'
import { EditorState } from 'draft-js'
import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import {
    RestrictionsDocument,
    useCreateRestrictionMutation,
    Restriction
} from '../../../../../generated/graphql'
import { RowCentered } from '../../shared/styled'
import { Auth } from '../../../../../context/Authentication'
import EditorInput from '../../../../../components/EditorInput'
import SelectInput from '../../../../../components/SelectInput'
import UploadPreview from '../../../../../components/UploadPreview'
import ConnectedInput from '../../../../../components/ConnectedInput'
import { ClickableLabel, FormH1, Label } from '../../../shared/styled'
import { creationValidation, FormValues, handleTitleChange } from '../shared'
import { convertStateToHTML, createLanguageOptions } from '../../shared'
import {
    H2,
    LeftSide,
    Container,
    ButtonGroup,
    LeftSideCard,
    RightSideCard,
    StyledButton
} from '../../shared/styled'

const RestrictionCreation = ({
    history,
    location,
    match
}: RouteComponentProps<{ projectId: string }>) => {
    const { projectId } = match.params

    const [t] = useTranslation()
    const { user } = useContext(Auth)
    const [slugVisible, toggleSlug] = useState(false)

    const [createRestriction, { loading: mutationLoading }] = useCreateRestrictionMutation({
        update(cache, { data }) {
            try {
                const { restrictions }: any = cache.readQuery({
                    query: RestrictionsDocument,
                    variables: { projectId }
                })
                restrictions.push(data?.createOneRestriction)
                restrictions.sort(
                    (a: Restriction, b: Restriction) =>
                        +new Date(b.createdAt) - +new Date(a.createdAt)
                )
                cache.writeQuery({
                    query: RestrictionsDocument,
                    variables: { projectId },
                    data: { restrictions }
                })
            } catch (err) {
                console.error(err)
            }
        },
        onCompleted: data => {
            history.push(`/${projectId}/restrictions/${data.createOneRestriction.id}`)
            message.success(t('restriction.creation.success'))
        }
    })

    const languageOptions = createLanguageOptions(user.activeProject!.languages, t)

    const defaultLanguage =
        languageOptions.length === 1
            ? languageOptions[0].value
            : location.state
            ? location.state.selectedLanguage
            : ''

    const initialValues = {
        title: '',
        slug: '',
        logoImg: '',
        language: defaultLanguage,
        text: EditorState.createEmpty()
    }

    const submit = async (values: FormValues) => {
        const adjustedValues = {
            ...values,
            text: convertStateToHTML(values.text.getCurrentContent())
        }

        try {
            await createRestriction({
                variables: {
                    ...adjustedValues,
                    projectId: user.activeProject!.id
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Formik<FormValues>
            onSubmit={submit}
            initialValues={initialValues}
            validationSchema={creationValidation(t)}
        >
            {({ setFieldValue, handleChange, isValid }) => (
                <Form>
                    <Container>
                        <LeftSide>
                            <LeftSideCard>
                                <FormH1>{t('restriction.creation.heading')}</FormH1>
                                <Label>{t('restriction.shared.title')}</Label>
                                <ConnectedInput
                                    name="title"
                                    size="large"
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleTitleChange(e, setFieldValue)
                                    }
                                />
                                <ClickableLabel onClick={() => toggleSlug(!slugVisible)}>
                                    {t('restriction.shared.postLink')}
                                </ClickableLabel>
                                {slugVisible && (
                                    <ConnectedInput
                                        name="slug"
                                        size="large"
                                        handleChange={handleChange}
                                    />
                                )}
                                <Label>{t('restriction.shared.text')}</Label>
                                <EditorInput name="text" />
                            </LeftSideCard>
                        </LeftSide>
                        <RightSideCard>
                            <H2>{t('restriction.shared.settings')}</H2>
                            <Label>{t('restriction.shared.language')}</Label>
                            <SelectInput name="language" options={languageOptions} />
                            <Label>{t('restriction.shared.image')}</Label>
                            <UploadPreview name="logoImg" accept="image/png,image/jpg,image/jpeg" />
                            <ButtonGroup>
                                <StyledButton
                                    onClick={() => history.push(`/${projectId}/restrictions`)}
                                >
                                    {t('restriction.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!isValid}
                                    loading={mutationLoading}
                                >
                                    {t('restriction.creation.publish')}
                                </StyledButton>
                            </ButtonGroup>
                        </RightSideCard>
                    </Container>
                </Form>
            )}
        </Formik>
    )
}

export default RestrictionCreation
