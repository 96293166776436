import { useTranslation } from 'react-i18next'
import { Dropdown, Icon, Layout, Menu } from 'antd'
import { Link, RouteComponentProps } from 'react-router-dom'
import React, { Dispatch, useContext, useState } from 'react'

import cs from '../../../../assets/czech.svg'
import en from '../../../../assets/english.svg'
import arrow from '../../../../assets/arrow-black.svg'

import '../../shared/styles.css'
import { getMatchedKey } from '../../shared'
import { Routes } from '../../../../constants'
import ProjectDropdown from './ProjectDropdown'
import { DropdownFade } from './ProjectDropdown/styled'
import { Auth } from '../../../../context/Authentication'
import {
    Logo,
    RightSide,
    ProfileImg,
    StyledSider,
    StyledAvatar,
    StyledHeader,
    StyledLayout,
    StyledContent,
    StyledDownUpIcon,
    ProjectLogoActive,
    ProjectLogoActiveSmall
} from '../../shared/layout'
import {
    ProjectLogo,
    ProfileArrow,
    PopoverHeader,
    ProfileDropdown,
    LeftMenuContainer
} from './styled'

const { Item } = Menu

type Props = {
    children: JSX.Element[] | JSX.Element
    collapsed: boolean
    toggleCollapse: Dispatch<any>
    isSmScreen: boolean
}

const PrivateLayout = ({
    children,
    history,
    collapsed,
    toggleCollapse,
    isSmScreen
}: Props & RouteComponentProps) => {
    const [t, i18n] = useTranslation()
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const { signOut, user, setActiveProject } = useContext(Auth)

    if (!user || !user.projects || !user.activeProject) {
        return null
    }

    const { activeProject } = user

    const language = i18n.language.substring(0, 2)

    const profileMenu = (
        <Menu>
            <Item key="layout.profile" onClick={() => history.push(Routes.PROFILE)}>
                {t('layout.profile')}
            </Item>
            <Item key="layout.signout" onClick={() => signOut()}>
                {t('layout.signout')}
            </Item>
        </Menu>
    )

    const languageMenu = (
        <Menu>
            <Item onClick={() => i18n.changeLanguage(language === 'cs' ? 'en' : 'cs')}>
                <img src={language === 'cs' ? en : cs} alt={language} />
            </Item>
        </Menu>
    )

    const popoverContent = (
        <>
            <PopoverHeader>{t('layout.projectSelect')}</PopoverHeader>
            <Menu style={{ backgroundColor: '#fff' }}>
                {user.projects &&
                    user.projects.map(project => (
                        <Item
                            key={project.id}
                            title={project.name}
                            onClick={() => setActiveProject(project, history)}
                        >
                            {project.logo ? <ProjectLogo src={project.logo} /> : project.name}
                        </Item>
                    ))}
            </Menu>
        </>
    )

    return (
        <StyledLayout>
            <StyledSider trigger={null} collapsible collapsed={collapsed}>
                <DropdownFade open={dropdownOpen} onClick={() => setDropdownOpen(false)} />
                {!collapsed ? (
                    user.projects.length === 1 ? (
                        <Link to={`/${activeProject.id}`}>
                            <Logo>
                                <ProjectLogoActive src={activeProject.logoSmall} alt="logo" />
                            </Logo>
                        </Link>
                    ) : (
                        <ProjectDropdown
                            collapsed={false}
                            activeProject={activeProject}
                            userProjects={user.projects}
                            history={history}
                            dropdownOpen={dropdownOpen}
                            setDropdownOpen={setDropdownOpen}
                        />
                    )
                ) : user.projects.length === 1 ? (
                    <Link to={`/${activeProject.id}`}>
                        <Logo>
                            <ProjectLogoActiveSmall src={activeProject.logoSmall} alt="logo" />
                        </Logo>
                    </Link>
                ) : (
                    <ProjectDropdown
                        collapsed={true}
                        activeProject={activeProject}
                        userProjects={user.projects}
                        history={history}
                        dropdownOpen={dropdownOpen}
                        setDropdownOpen={setDropdownOpen}
                    />
                )}

                <LeftMenuContainer>
                    <Menu
                        theme="light"
                        mode="inline"
                        selectedKeys={[getMatchedKey(location.pathname)]}
                    >
                        <Item key={Routes.DASHBOARD}>
                            <Link to={`/${activeProject.id}`}>
                                <Icon type="dashboard" />
                                <span>{t('layout.dashboard')}</span>
                            </Link>
                        </Item>
                        {user.activeProject.blogFeature && (
                            <Item key={Routes.POSTS}>
                                <Link to={`/${activeProject.id}/posts`}>
                                    <Icon type="read" />
                                    <span>{t('layout.blog')}</span>
                                </Link>
                            </Item>
                        )}
                        {user.activeProject.faqFeature && (
                            <Item key={Routes.FAQS}>
                                <Link to={`/${activeProject.id}/faqs`}>
                                    <Icon type="question-circle" />
                                    <span>{t('layout.faq')}</span>
                                </Link>
                            </Item>
                        )}
                        {user.activeProject.restrictionFeature && (
                            <Item key={Routes.RESTRICTIONS}>
                                <Link to={`/${activeProject.id}/restrictions`}>
                                    <Icon type="unordered-list" />
                                    <span>{t('layout.restriction')}</span>
                                </Link>
                            </Item>
                        )}
                        {user.activeProject.suggestionFeature && (
                            <Item key={Routes.SUGGESTIONS}>
                                <Link to={`/${activeProject.id}/suggestions`}>
                                    <Icon type="cluster" />
                                    <span>{t('layout.suggestion')}</span>
                                </Link>
                            </Item>
                        )}

                        {/* <Item key={`/${activeProject.id}/seo`}>
                            <Icon type="global" />
                            <span>SEO</span>
                        </Item>
                        <Item key="/files">
                            <Icon type="file" />
                            <span>My files</span>
                        </Item> */}
                        {user.activeProject.admin && (
                            <Item key={Routes.USERS}>
                                <Link to={`/${activeProject.id}/users`}>
                                    <Icon type="usergroup-add" />
                                    <span>{t('layout.user')}</span>
                                </Link>
                            </Item>
                        )}
                        {user.activeProject.webUrl && (
                            <Item key="urlLink">
                                <a href={user.activeProject.webUrl} target="__blank">
                                    <Icon type="appstore" />
                                    <span>{t('layout.website')}</span>
                                </a>
                            </Item>
                        )}
                    </Menu>

                    <Dropdown overlay={profileMenu} placement="topCenter">
                        <ProfileDropdown collapsed={collapsed}>
                            {user.profileImg ? (
                                <ProfileImg alt="profile_img" src={user.profileImg} />
                            ) : (
                                <StyledAvatar icon="user" />
                            )}
                            {!collapsed ? user.name : null}
                            <ProfileArrow src={arrow} alt="dropdown arrow" collapsed={collapsed} />
                        </ProfileDropdown>
                    </Dropdown>
                </LeftMenuContainer>
            </StyledSider>

            <Layout>
                <StyledHeader collapsed={collapsed ? 1 : 0}>
                    {!isSmScreen ? (
                        <Icon
                            type={collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={() => toggleCollapse(!collapsed)}
                        />
                    ) : (
                        <div />
                    )}
                    <RightSide>
                        <Dropdown overlay={languageMenu}>
                            <div>
                                <img src={language === 'cs' ? cs : en} alt={language} />
                                <StyledDownUpIcon type="caret-down" style={{ color: '#000' }} />
                            </div>
                        </Dropdown>
                    </RightSide>
                </StyledHeader>
                <StyledContent collapsed={collapsed ? 1 : 0}>{children}</StyledContent>
            </Layout>
        </StyledLayout>
    )
}

export default PrivateLayout
