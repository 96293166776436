import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Auth } from '../../../../context/Authentication'
import Card from './Card'

const H1 = styled.h1`
    font-size: 38px;
`
const P = styled.p`
    margin-bottom: 50px;
`

const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const StyledLink = styled(Link)`
    max-width: 190px;
    width: 100%;
`

const Dashboard = (props: RouteComponentProps<{ projectId: string }>) => {
    const { user } = useContext(Auth)
    const [t] = useTranslation()
    const { projectId } = props.match.params

    const cards = [
        {
            icon: 'solution',
            text: t('dashboard.shortcuts.blog'),
            link: `/${projectId}/post-new`
        },
        {
            icon: 'question-circle',
            text: t('dashboard.shortcuts.faq'),
            link: `/${projectId}/faq-new`
        }
        //   { icon: "global", text: "Setup SEO", link: "" },
        //   { icon: "bell", text: "Send Notifications", link: "" },
        //    { icon: "file", text: "My files", link: "" }
    ]

    return (
        <>
            <H1>{t('dashboard.greeting', { user: user.name })}</H1>
            <P>{t('dashboard.welcome')}</P>
            <h2>{t('dashboard.shortcuts.title')}</h2>
            <Cards>
                {cards.map((card, index) => {
                    if (!user.activeProject!.faqFeature && card.link.includes('faq')) return null
                    if (
                        !user.activeProject!.restrictionFeature &&
                        card.link.includes('restriction')
                    )
                        return null
                    if (!user.activeProject!.blogFeature && card.link.includes('post')) return null
                    return (
                        <StyledLink key={index} to={card.link}>
                            <Card
                                icon={card.icon}
                                text={card.text}
                                index={index % 2 === 0 ? 0 : 1}
                            />
                        </StyledLink>
                    )
                })}
            </Cards>
        </>
    )
}

export default Dashboard
