import { Modal } from 'antd'
import { FieldArray, Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import React, { useContext } from 'react'
import { useApolloClient } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import * as Yup from 'yup'
import Input from '../../../../../components/Input'
import { Auth } from '../../../../../context/Authentication'
import { Label } from '../../../shared/styled'
import { H2 } from '../../shared/styled'
import { AddButton, ButtonGroup, Item, StyledButton, StyledIcon, Text } from './styled'
import './styles.css'

interface FormValues {
    emails: string[]
}

interface Props {
    modalOpened: boolean
    toggleModal: (modalOpened: boolean) => void
}

const validation = (t: TFunction) =>
    Yup.object().shape({
        emails: Yup.array()
            .of(
                Yup.string()
                    .email(t('validation.email'))
                    .required(t('validation.required'))
            )
            .required(t('validation.oneEmail'))
    })

const UserCreation = ({
    match,
    modalOpened,
    toggleModal
}: RouteComponentProps<{ projectId: string }> & Props) => {
    const { projectId } = match.params

    const [t] = useTranslation()
    const client = useApolloClient()

    const { inviteUsers } = useContext(Auth)

    return (
        <Modal visible={modalOpened} onCancel={() => toggleModal(!modalOpened)} footer={null}>
            <Formik<FormValues>
                initialValues={{ emails: [''] }}
                validationSchema={validation(t)}
                onSubmit={(values, { resetForm }) => {
                    inviteUsers(values.emails, projectId, client)
                    toggleModal(!modalOpened)
                    resetForm({ emails: [''] })
                }}
            >
                {({ values }) => (
                    <Form>
                        <H2>{t('user.adding.heading')}</H2>
                        <Text>{t('user.adding.description')}</Text>
                        <FieldArray
                            name="emails"
                            render={arrayHelpers => (
                                <>
                                    {values.emails.map((email, index) => (
                                        <div key={index}>
                                            <Label>{t('user.adding.email')}</Label>
                                            <Item>
                                                <Input
                                                    name={`${arrayHelpers.name}.${index}`}
                                                    placeholder={t('user.adding.email')}
                                                    style={{ flexGrow: 1 }}
                                                />
                                                <StyledIcon
                                                    type="close"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                />
                                            </Item>
                                        </div>
                                    ))}
                                    {values.emails.length < 5 && (
                                        <AddButton
                                            type="dashed"
                                            onClick={() => arrayHelpers.push('')}
                                        >
                                            {t('user.adding.another')}
                                        </AddButton>
                                    )}
                                </>
                            )}
                        />
                        <ButtonGroup>
                            <StyledButton onClick={() => toggleModal(!modalOpened)}>
                                {t('user.cancel')}
                            </StyledButton>
                            <StyledButton htmlType="submit" type="primary">
                                {t('user.save')}
                            </StyledButton>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default withRouter(UserCreation)
