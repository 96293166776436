import { Card, message } from 'antd'
import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import React from 'react'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import * as Yup from 'yup'
import Checkbox from '../../../../../components/Checkbox'
import Input from '../../../../../components/Input'
import SelectInput from '../../../../../components/SelectInput'
import Upload from '../../../../../components/Upload'
import {
    CompaniesDocument,
    ProjectsDocument,
    useCreateProjectMutation
} from '../../../../../generated/graphql'
import { createSelectOptions } from '../../../shared'
import { FormH1, Label } from '../../../shared/styled'
import { ButtonGroup, Container, Row, RowItem, StyledButton } from '../../shared'
import {
    fontFamilyOptions,
    fontSizeOptions,
    FormValues,
    MarginedSpan,
    RowItemCollapsed
} from '../shared'

const validation = (t: TFunction) =>
    Yup.object().shape({
        name: Yup.string().required(t('validation.required')),
        logo: Yup.mixed()
            .test('fileSize', t('validation.size'), value => {
                if (value) return value.size <= 2000000
                else return true
            })
            .required(t('validation.required')),
        logoSmall: Yup.mixed()
            .test('fileSize', t('validation.size'), value => {
                if (value) return value.size <= 2000000
                else return true
            })
            .required(t('validation.required')),
        email: Yup.string()
            .email(t('validation.email'))
            .required(t('validation.required')),
        companyId: Yup.string().required(t('validation.required')),
        fontFamily: Yup.string().required(t('validation.required')),
        fontSize: Yup.string().required(t('validation.required'))
    })

const initialValues = {
    name: '',
    webUrl: '',
    logo: null,
    logoSmall: null,
    email: '',
    companyId: '',
    faq: false,
    restriction: false,
    suggestion: false,
    blog: false,
    cs: false,
    en: false,
    de: false,
    pl: false,
    encz: false,
    ensk: false,
    enpl: false,
    fontFamily: '',
    fontSize: '',
    h1FontFamily: '',
    h1FontSize: '',
    h2FontFamily: '',
    h2FontSize: '',
    h3FontFamily: '',
    h3FontSize: '',
    h4FontFamily: '',
    h4FontSize: ''
}

const ProjectCreation = ({ history }: RouteComponentProps) => {
    const [t] = useTranslation()

    const { data, loading } = useQuery(CompaniesDocument, {
        onError: error => console.error(error.message)
    })

    const [createProject, { loading: mutationLoading }] = useCreateProjectMutation({
        onCompleted: () => message.success(t('project.creation.success')),
        update(cache, { data }) {
            try {
                const { projects }: any = cache.readQuery({
                    query: ProjectsDocument
                })
                cache.writeQuery({
                    query: ProjectsDocument,
                    data: {
                        projects: projects.concat([data?.createOneProject])
                    }
                })
            } catch {}
        }
    })

    return (
        <Container>
            <Formik<FormValues>
                initialValues={initialValues as FormValues}
                validationSchema={validation(t)}
                onSubmit={values => {
                    createProject({
                        variables: {
                            ...values,
                            companyId: values.companyId,
                            languages: JSON.stringify({
                                cs: values.cs,
                                sk: values.sk,
                                en: values.en,
                                de: values.de,
                                pl: values.pl,
                                encz: values.encz,
                                ensk: values.ensk,
                                enpl: values.enpl
                            }),
                            editorConfig: JSON.stringify({
                                fontFamily: values.fontFamily,
                                fontSize: values.fontSize,
                                h1FontFamily: values.h1FontFamily,
                                h1FontSize: values.h1FontSize,
                                h2FontFamily: values.h2FontFamily,
                                h2FontSize: values.h2FontSize,
                                h3FontFamily: values.h3FontFamily,
                                h3FontSize: values.h3FontSize,
                                h4FontFamily: values.h4FontFamily,
                                h4FontSize: values.h4FontSize
                            })
                        }
                    })
                }}
            >
                {() => (
                    <Form>
                        <Card>
                            <FormH1>{t('project.shared.information')}</FormH1>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.name')}</Label>
                                    <Input name="name" size="large" />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.website')}</Label>
                                    <Input name="webUrl" size="large" />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.manager')}</Label>
                                    <Input
                                        name="email"
                                        placeholder={t('project.shared.email')}
                                        size="large"
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.company')}</Label>
                                    <SelectInput
                                        name="companyId"
                                        size="large"
                                        options={loading ? [] : createSelectOptions(data.companies)}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.logo')}</Label>
                                    <Upload name="logo" accept="image/png,image/jpg,image/jpeg" />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.favicon')}</Label>
                                    <Upload
                                        name="logoSmall"
                                        accept="image/png,image/jpg,image/jpeg"
                                    />
                                </RowItem>
                            </Row>
                            <FormH1>{t('project.shared.features')}</FormH1>
                            <Row style={{ marginBottom: '30px' }}>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="blog" />
                                        <MarginedSpan>{t('project.shared.blog')}</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="restriction" />
                                        <MarginedSpan>
                                            {t('project.shared.restriction')}
                                        </MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="suggestion" />
                                        <MarginedSpan>
                                            {t('project.shared.suggestion')}
                                        </MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="faq" />
                                        <MarginedSpan>{t('project.shared.faq')}</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                            </Row>
                            <FormH1>{t('project.shared.languages')}</FormH1>
                            <Row style={{ marginBottom: '30px' }}>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="cs" />
                                        <MarginedSpan>CS</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="sk" />
                                        <MarginedSpan>SK</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="en" />
                                        <MarginedSpan>EN</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="de" />
                                        <MarginedSpan>DE</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="pl" />
                                        <MarginedSpan>PL</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="encz" />
                                        <MarginedSpan>EN-CZ</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="ensk" />
                                        <MarginedSpan>EN-SK</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="enpl" />
                                        <MarginedSpan>EN-PL</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                            </Row>
                            <FormH1>{t('project.shared.editor')}</FormH1>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.pFontFamily')}</Label>
                                    <SelectInput
                                        name="fontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.pFontSize')}</Label>
                                    <SelectInput
                                        name="fontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.h1FontFamily')}</Label>
                                    <SelectInput
                                        name="h1FontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.h1FontSize')}</Label>
                                    <SelectInput
                                        name="h1FontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.h2FontFamily')}</Label>
                                    <SelectInput
                                        name="h2FontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.h2FontSize')}</Label>
                                    <SelectInput
                                        name="h2FontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.h3FontFamily')}</Label>
                                    <SelectInput
                                        name="h3FontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.h3FontSize')}</Label>
                                    <SelectInput
                                        name="h3FontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.h4FontFamily')}</Label>
                                    <SelectInput
                                        name="h4FontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.h4FontSize')}</Label>
                                    <SelectInput
                                        name="h4FontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <ButtonGroup>
                                <StyledButton onClick={() => history.goBack()}>
                                    {t('project.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    htmlType="submit"
                                    type="primary"
                                    loading={mutationLoading}
                                >
                                    {t('project.shared.save')}
                                </StyledButton>
                            </ButtonGroup>
                        </Card>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default ProjectCreation
