import { Button } from "antd";
import styled from "styled-components";

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  :first-child {
    margin-right: 10px;
  }
`;

export const Text = styled.p`
  margin-bottom: 20px;
`;
