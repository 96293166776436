import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Auth } from "../../../../context/Authentication";

const H1 = styled.h1`
  font-size: 38px;
`;
const P = styled.p`
  margin-bottom: 50px;
`;

const AdminDashboard = () => {
  const [t] = useTranslation();
  const { user } = useContext(Auth);

  return (
    <>
      <H1>{t("dashboard.greeting", { user: user.name })}</H1>
      <P>{t("dashboard.welcome")}</P>
      <h2>{t("dashboard.shortcuts.title")}</h2>
    </>
  );
};

export default AdminDashboard;
