export enum Routes {
    HOME = '/',
    ADMIN = '/admin',
    DASHBOARD = '/:projectId',
    REGISTER = '/register',
    USERS = '/:projectId/users',
    FORGOT_PASSWORD = '/forgot-password',
    RESET_PASSWORD = '/reset-password',
    POST_CREATION = '/:projectId/post-new',
    POSTS = '/:projectId/posts',
    POST_DETAIL = '/:projectId/posts/:id',
    RESTRICTIONS = '/:projectId/restrictions',
    RESTRICTION_DETAIL = '/:projectId/restrictions/:id',
    RESTRICTION_CREATION = '/:projectId/restriction-new',
    SUGGESTIONS = '/:projectId/suggestions',
    SUGGESTIONS_DETAIL = '/:projectId/suggestions/:id',
    SUGGESTIONS_CREATION = '/:projectId/suggestion-new',
    FAQ_DETAIL = '/:projectId/faqs/:id',
    FAQ_CREATION = '/:projectId/faq-new',
    FAQS = '/:projectId/faqs',
    PROFILE = '/profile',
    COMPANIES = '/admin/companies',
    COMPANY_CREATION = '/admin/company-new',
    COMPANY_DETAIL = '/admin/companies/:id',
    PROJECTS = '/admin/projects',
    PROJECT_CREATION = '/admin/project-new',
    PROJECT_DETAIL = '/admin/projects/:id'
}
