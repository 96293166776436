/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Card, Row } from 'antd'
import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as Yup from 'yup'
import Input from '../../../../components/Input'
import Upload from '../../../../components/Upload'
import { Auth } from '../../../../context/Authentication'

interface Styled {
    disabled?: boolean
}

const StyledCard = styled(Card)`
    width: 80%;
    margin-bottom: 30px;
`

const H1 = styled.h1`
    font-size: 32px;
`

const Label = styled.p<Styled>`
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: ${({ disabled }) => (disabled ? '' : 'bold')};
    color: ${({ disabled }) => (disabled ? '#d9d9d9' : 'rgba(0, 0, 0, 0.85)')};
`

const InputItem = styled.div`
    flex-grow: 1;
    margin-right: 20px;
    :last-child {
        margin: 0;
    }
`

const profileValidation = (t: TFunction) =>
    Yup.object().shape({
        name: Yup.string()
            .min(3, t('validation.name'))
            .required(t('validation.required')),
        profileImage: Yup.mixed().test('fileSize', t('validation.size'), value => {
            if (value) return value.size <= 2000000
            else return true
        })
    })

const passwordValidation = (t: TFunction) =>
    Yup.object().shape({
        oldPassword: Yup.string()
            .min(6, t('validation.password'))
            .required(t('validation.required')),
        password: Yup.string()
            .min(6, t('validation.password'))
            .required(t('validation.required')),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], t('validation.mismatch'))
            .required(t('validation.required'))
    })

const passwordFormInitial = {
    oldPassword: '',
    password: '',
    passwordConfirm: ''
}

interface ProfileFormValues {
    name: string
    email: string
    profileImage: File | null
}

interface PasswordFormValues {
    oldPassword: string
    password: string
    passwordConfirm: string
}

export default () => {
    const [t] = useTranslation()
    const { user, updateUser, changeUserPassword } = useContext(Auth)
    const [loading, setLoading] = useState(false)
    if (!user) {
        return null
    }

    return (
        <>
            <StyledCard>
                <H1>{t('profile.title')}</H1>
                <Formik<ProfileFormValues>
                    initialValues={{
                        name: user.name!,
                        email: user.email!,
                        profileImage: null
                    }}
                    validationSchema={profileValidation(t)}
                    onSubmit={async ({ name, profileImage }) => {
                        setLoading(true)
                        await updateUser(name, profileImage)
                        setLoading(false)
                    }}
                >
                    {() => (
                        <Form>
                            <Row type="flex">
                                <InputItem>
                                    <Label>{t('profile.name')}</Label>
                                    <Input name="name" />
                                </InputItem>
                                <InputItem>
                                    <Label disabled>{t('profile.email')}</Label>
                                    <Input name="email" icon="user" disabled />
                                </InputItem>
                                <InputItem>
                                    <Label>{t('profile.picture')}</Label>
                                    <Upload
                                        name="profileImage"
                                        accept="image/png,image/jpg,image/jpeg"
                                    />
                                </InputItem>
                            </Row>
                            <Row type="flex" justify="end">
                                <Button htmlType="submit" type="primary" loading={loading}>
                                    {t('profile.save')}
                                </Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </StyledCard>
            <StyledCard>
                <H1>{t('profile.password')}</H1>
                <Formik<PasswordFormValues>
                    initialValues={passwordFormInitial}
                    validationSchema={passwordValidation(t)}
                    onSubmit={async (values, { resetForm }) => {
                        setLoading(true)
                        await changeUserPassword({
                            email: user.email!,
                            oldPassword: values.oldPassword,
                            newPassword: values.password
                        })
                        setLoading(false)
                        resetForm(passwordFormInitial)
                    }}
                >
                    {() => (
                        <Form>
                            <Row type="flex">
                                <InputItem>
                                    <Label>{t('profile.oldPassword')}</Label>
                                    <Input name="oldPassword" type="password" />
                                </InputItem>
                                <InputItem>
                                    <Label>{t('profile.newPassword')}</Label>
                                    <Input name="password" type="password" />
                                </InputItem>
                                <InputItem>
                                    <Label>{t('profile.repeatPassword')}</Label>
                                    <Input name="passwordConfirm" type="password" />
                                </InputItem>
                            </Row>
                            <Row type="flex" justify="end">
                                <Button htmlType="submit" type="primary" loading={loading}>
                                    {t('profile.save')}
                                </Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </StyledCard>
        </>
    )
}

/*             <StyledCard>
                <Row type="flex" justify="space-between" align="middle">
                    <StyledP>
                        Would you like to delete your profile? All your data
                        would be permanently deleted.
                    </StyledP>
                    <Button type="danger">Delete account</Button>
                </Row>
            </StyledCard> */
