import { message, Modal } from 'antd'
import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import * as Yup from 'yup'
import SelectInput from '../../../../../components/SelectInput'
import {
    useConnectUserMutation,
    UsersDocument,
    useUsersToConnectQuery
} from '../../../../../generated/graphql'
import { createSelectOptions, Option } from '../../../shared'
import { H2 } from '../../shared/styled'
import { ButtonGroup, StyledButton, Text } from './styled'
import './styles.css'

interface FormValues {
    userId: string
}

interface Props {
    modalOpened: boolean
    toggleModal: (modalOpened: boolean) => void
}

const validation = (t: TFunction) =>
    Yup.object().shape({
        userId: Yup.string().required(t('validation.required'))
    })

const UserCreation = ({
    match,
    modalOpened,
    toggleModal
}: RouteComponentProps<{ projectId: string }> & Props) => {
    const [t] = useTranslation()
    const { projectId } = match.params
    const { data, loading } = useUsersToConnectQuery({
        variables: { projectId },
        fetchPolicy: 'network-only'
    })
    const [connectUser, { loading: mutationLoading }] = useConnectUserMutation({
        update(cache, { data }) {
            const { users }: any = cache.readQuery({
                query: UsersDocument,
                variables: { projectId }
            })
            cache.writeQuery({
                query: UsersDocument,
                variables: { projectId },
                data: { users: users.concat([data?.connectUser]) }
            })
        },
        onCompleted: () => {
            message.success(t('user.connecting.success'))
        }
    })

    return (
        <Modal visible={modalOpened} onCancel={() => toggleModal(!modalOpened)} footer={null}>
            <Formik<FormValues>
                initialValues={{ userId: '' }}
                validationSchema={validation(t)}
                onSubmit={({ userId }, { resetForm }) => {
                    connectUser({ variables: { userId, projectId } })
                    resetForm({ userId: '' })
                    setTimeout(() => toggleModal(!modalOpened), 0)
                }}
            >
                {() => (
                    <Form>
                        <H2>{t('user.connecting.heading')}</H2>
                        <Text>{t('user.connecting.description')}</Text>
                        <SelectInput
                            name="userId"
                            options={
                                loading || !data?.usersToConnect
                                    ? []
                                    : createSelectOptions(data?.usersToConnect as Option[])
                            }
                        />
                        <ButtonGroup>
                            <StyledButton onClick={() => toggleModal(!modalOpened)}>
                                {t('user.cancel')}
                            </StyledButton>
                            <StyledButton
                                htmlType="submit"
                                type="primary"
                                loading={mutationLoading}
                            >
                                {t('user.save')}
                            </StyledButton>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default withRouter(UserCreation)
