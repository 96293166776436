/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import uniqBy from 'lodash/uniqBy'
import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Empty, Icon, Menu, message, Table, Tooltip } from 'antd'

import '../../../shared/styles.css'
import { AddButton } from '../../styled'
import PrevNext from '../../shared/PrevNext'
import { languageIcons } from '../../shared'
import { MenuIcon, IconContainer } from '../shared'
import Loader from '../../../../../components/Loader'
import { Auth } from '../../../../../context/Authentication'
import { FilterIconWrapper, FilterTitle } from '../../shared/styled'
import ConfirmationModal from '../../../../../components/ConfirmationModal'
import {
    FaqsDocument,
    FaqsQuery,
    useDeleteFaqMutation,
    useFaqsQuery
} from '../../../../../generated/graphql'
import {
    Header,
    ListH1,
    PrimaryColumn,
    StatusIcon,
    StyledMenu,
    TableHeader,
    TransparentButton
} from '../../../shared/styled'

const { Item } = Menu

type Faq = FaqsQuery['faqs'][0]

export default ({ history, match }: RouteComponentProps<{ projectId: string }>) => {
    const { projectId } = match.params

    const [t] = useTranslation()
    const { user } = useContext(Auth)

    const [languageFilter, setLanguageFilter] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [modalState, setModalState] = useState<{
        opened: boolean
        ids: string[] | []
    }>({ opened: false, ids: [] })

    const parsedLanguages = JSON.parse(user.activeProject!.languages)

    const { data, error, loading } = useFaqsQuery({
        variables: { projectId }
    })

    const [deleteFaq] = useDeleteFaqMutation({
        update(cache, { data }) {
            const { faqs }: any = cache.readQuery({
                query: FaqsDocument,
                variables: { projectId }
            })
            const deleteIndex = faqs.map((faq: Faq) => faq.id).indexOf(data?.deleteOneFaq?.id)
            faqs.splice(deleteIndex, 1)
            cache.writeQuery({
                query: FaqsDocument,
                variables: { projectId },
                data: { faqs }
            })
        }
    })

    if (loading || !data) {
        return <Loader />
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const columns = [
        {
            title: t('faq.title'),
            dataIndex: 'question',
            render: (question: string, record: Faq) => (
                <PrimaryColumn onClick={() => history.push(`/${projectId}/faqs/${record.id}`)}>
                    {question}
                </PrimaryColumn>
            ),
            sorter: (a: Faq, b: Faq) => (a.question > b.question ? 1 : -1)
        },
        {
            title: t('faq.language'),
            dataIndex: 'language',
            width: 100,
            render: (lan: string) => <img src={languageIcons[lan]} alt={lan} />,
            filteredValue: [languageFilter],
            onFilter: (value: string, record: Faq) =>
                languageFilter.length ? record.language === languageFilter : true
        },
        {
            dataIndex: 'category',
            width: 150,
            render: (category: Faq['Category']) => (category ? category.name : ''),
            filterIcon: (filtered: boolean) => (
                <FilterIconWrapper>
                    <FilterTitle>{t('faq.category')}</FilterTitle>
                    <Icon type="filter" theme={filtered ? 'twoTone' : 'outlined'} />
                </FilterIconWrapper>
            ),
            filters: uniqBy(
                data.faqs
                    .filter((faq: Faq) => !!faq.Category)
                    .map((faq: Faq) => ({
                        text: faq.Category && faq.Category.name,
                        value: faq.Category && faq.Category.id
                    })),
                'value'
            ),
            onFilter: (value: string, record: Faq) =>
                record.Category && record.Category.id.indexOf(value) === 0
        },
        {
            title: t('faq.status'),
            width: 100,
            render: (faq: Faq) => {
                return (
                    <Tooltip title={faq.published ? 'Posted' : 'Unposted'}>
                        <StatusIcon published={faq.published} />
                    </Tooltip>
                )
            },
            sorter: (a: Faq, b: Faq) => (a.published === b.published ? 0 : a.published ? -1 : 1)
        },
        {
            title: t('faq.action'),
            width: 100,
            render: (faq: Faq) => {
                return (
                    <IconContainer>
                        <Tooltip title="Edit">
                            <MenuIcon
                                type="edit"
                                onClick={() => history.push(`/${projectId}/faqs/${faq.id}`)}
                                style={{ marginRight: 20 }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <MenuIcon
                                type="delete"
                                onClick={() => setModalState({ opened: true, ids: [faq.id] })}
                            />
                        </Tooltip>
                    </IconContainer>
                )
            }
        }
    ]

    const isSingular = modalState.ids.length <= 1

    return (
        <>
            <Header>
                <ListH1>{t('faq.heading')}</ListH1>
                <AddButton
                    onClick={() =>
                        history.push(`/${projectId}/faq-new`, {
                            selectedLanguage: languageFilter
                        })
                    }
                >
                    <Icon type="plus" />
                    &nbsp;&nbsp;{t('faq.add')}
                </AddButton>
            </Header>
            <ConfirmationModal
                title={isSingular ? t('faq.deleteConfirmTitle') : t('faq.deleteConfirmTitlePl')}
                text={isSingular ? t('faq.deleteConfirmText') : t('faq.deleteConfirmTextPl')}
                action={() => {
                    modalState.ids.forEach(id =>
                        deleteFaq({
                            variables: {
                                id
                            }
                        })
                    )
                    setSelectedRowKeys([])
                    message.success(isSingular ? t('faq.deleteSuccess') : t('faq.deleteSuccessPl'))
                }}
                visible={modalState.opened}
                setState={setModalState}
            />
            {data.faqs.length ? (
                <Table<Faq>
                    rowKey={record => record.id}
                    // @ts-ignore
                    columns={columns}
                    dataSource={data.faqs}
                    pagination={{
                        position: 'bottom',
                        style: { float: 'left' },
                        defaultPageSize: 10,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <PrevNext type="left" current={current} />
                            }
                            if (type === 'next') {
                                return <PrevNext type="right" current={current} />
                            }
                            return originalElement
                        },
                        showSizeChanger: true
                    }}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: selectedKeys => setSelectedRowKeys(selectedKeys as string[])
                    }}
                    title={() => (
                        <TableHeader>
                            <StyledMenu mode="horizontal" defaultSelectedKeys={['all']}>
                                <Item key="all" onClick={() => setLanguageFilter('')}>
                                    <TransparentButton>{t('faq.all')}</TransparentButton>
                                </Item>
                                {parsedLanguages.cs && (
                                    <Item onClick={() => setLanguageFilter('CS')}>
                                        <TransparentButton>
                                            <img src={languageIcons.CS} alt="cs" />
                                            {t('faq.czech')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.sk && (
                                    <Item onClick={() => setLanguageFilter('SK')}>
                                        <TransparentButton>
                                            <img src={languageIcons.SK} alt="sk" />{' '}
                                            {t('faq.slovak')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.en && (
                                    <Item onClick={() => setLanguageFilter('EN')}>
                                        <TransparentButton>
                                            <img src={languageIcons.EN} alt="en" />{' '}
                                            {t('faq.english')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.de && (
                                    <Item onClick={() => setLanguageFilter('DE')}>
                                        <TransparentButton>
                                            <img src={languageIcons.DE} alt="de" />{' '}
                                            {t('faq.german')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.pl && (
                                    <Item onClick={() => setLanguageFilter('PL')}>
                                        <TransparentButton>
                                            <img src={languageIcons.PL} alt="pl" />{' '}
                                            {t('faq.polish')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.encz && (
                                    <Item onClick={() => setLanguageFilter('ENCZ')}>
                                        <TransparentButton>
                                            <img src={languageIcons.ENCZ} alt="encz" /> EN-CZ
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.ensk && (
                                    <Item onClick={() => setLanguageFilter('ENSK')}>
                                        <TransparentButton>
                                            <img src={languageIcons.ENSK} alt="ensk" /> EN-SK
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.enpl && (
                                    <Item onClick={() => setLanguageFilter('ENPL')}>
                                        <TransparentButton>
                                            <img src={languageIcons.ENPL} alt="enpl" /> EN-PL
                                        </TransparentButton>
                                    </Item>
                                )}
                            </StyledMenu>
                            <Button
                                type="danger"
                                disabled={!selectedRowKeys.length}
                                onClick={() =>
                                    setModalState({
                                        opened: true,
                                        ids: selectedRowKeys
                                    })
                                }
                            >
                                {t('faq.delete')}
                            </Button>
                        </TableHeader>
                    )}
                />
            ) : (
                <Empty imageStyle={{ height: 160 }} description={<span>{t('faq.none')}</span>}>
                    <Button type="primary" onClick={() => history.push(`/${projectId}/faq-new`)}>
                        <Icon type="plus" /> {t('faq.add')}
                    </Button>
                </Empty>
            )}
        </>
    )
}
