import { message, Modal } from 'antd'
import { Form, Formik, FormikValues } from 'formik'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import * as Yup from 'yup'
import Input from '../../../../../components/Input'
import { UsersDocument, useUpdateUserMutation } from '../../../../../generated/graphql'
import { Label } from '../../../shared/styled'
import { H2 } from '../../shared/styled'
import { ButtonGroup, StyledButton, Text } from './styled'
import './styles.css'

interface FormValues {
    name: string
}

interface Props {
    modalOpened: boolean
    toggleModal: (modalOpened: boolean) => void
    userDetails: { id: string; name: string }
}

const validation = (t: TFunction) =>
    Yup.object().shape({
        name: Yup.string()
            .min(3, t('validation.name'))
            .required(t('validation.required'))
    })

const UserDetail = ({
    match,
    modalOpened,
    toggleModal,
    userDetails
}: RouteComponentProps<{ projectId: string }> & Props) => {
    const [t] = useTranslation()
    const { projectId } = match.params
    const [updateUser, { loading: mutationLoading }] = useUpdateUserMutation({
        update(cache, { data }) {
            try {
                const { users }: any = cache.readQuery({
                    query: UsersDocument,
                    variables: { projectId }
                })
                const updateIndex = users.findIndex(
                    (user: any) => user.id === data?.updateUserName?.id
                )
                users[updateIndex] = data?.updateUserName
                cache.writeQuery({
                    query: UsersDocument,
                    variables: { projectId },
                    data: { users }
                })
            } catch {}
        },
        onCompleted: () => {
            message.success(t('user.editing.success'))
        }
    })

    return (
        <Modal visible={modalOpened} onCancel={() => toggleModal(!modalOpened)} footer={null}>
            <Formik<FormValues>
                enableReinitialize={true}
                initialValues={{ name: userDetails.name }}
                validationSchema={validation(t)}
                onSubmit={({ name }) => {
                    updateUser({ variables: { id: userDetails.id, name } })
                    setTimeout(() => toggleModal(!modalOpened), 0)
                }}
            >
                {({ values }): FormikValues => (
                    <Form>
                        <H2>{t('user.editing.heading')}</H2>
                        <Text>{t('user.editing.description')}</Text>
                        <Label>{t('user.editing.name')}</Label>
                        <Input name="name" />
                        <ButtonGroup>
                            <StyledButton onClick={() => toggleModal(!modalOpened)}>
                                {t('user.cancel')}
                            </StyledButton>
                            <StyledButton
                                htmlType="submit"
                                type="primary"
                                loading={mutationLoading}
                            >
                                {t('user.save')}
                            </StyledButton>
                        </ButtonGroup>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default withRouter(UserDetail)
