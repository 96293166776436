import gql from 'graphql-tag'
import { message } from 'antd'
import embed from 'embed-video'
import { useMutation } from 'react-apollo'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Editor } from 'react-draft-wysiwyg'
import { ContentBlock, EditorState } from 'draft-js'
import React, { useContext, useEffect, useRef } from 'react'

import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import './styles.css'
import { Auth } from '../../../context/Authentication'

interface Props {
    onSetFieldValue(value: any): void
    editorState: EditorState
}

const EditorComponent = ({
    onSetFieldValue,
    editorState,
    match
}: Props & RouteComponentProps<{ projectId?: string }>) => {
    const { projectId } = match.params

    const editorEl = useRef(null)
    const { user } = useContext(Auth)

    // Project ID is require in order to be able to connect
    // uploaded images to the project
    if (!projectId || !user.activeProject) {
        throw Error('Missing Project ID or active project')
    }

    const parsedEditorConfig = JSON.parse(user.activeProject.editorConfig)

    useEffect(() => {
        const style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = `.editor-header-one { font-size: ${parsedEditorConfig.h1FontSize}px; font-family: ${parsedEditorConfig.h1FontFamily}; } 
            .editor-header-two { font-size: ${parsedEditorConfig.h2FontSize}px; font-family ${parsedEditorConfig.h2FontFamily}; }
            .editor-header-three { font-size: ${parsedEditorConfig.h3FontSize}px; font-family: ${parsedEditorConfig.h3FontFamily}; }`
        document.getElementsByTagName('head')[0].appendChild(style)
    }, [])

    const setBlockStyles = (contentBlock: ContentBlock) => {
        const alignCheck = contentBlock
            .getData()
            .entries()
            .next().value
        let alignment = 'left'

        if (alignCheck && alignCheck[0] === 'text-align') {
            alignment = alignCheck[1]
        }

        const type = contentBlock.getType()
        if (type === 'header-one') return `editor-header-one rdw-${alignment}-aligned-block`
        else if (type === 'header-two') return `editor-header-two rdw-${alignment}-aligned-block`
        else if (type === 'header-three')
            return `editor-header-three rdw-${alignment}-aligned-block`
        else return `rdw-${alignment}-aligned-block`
    }

    const [uploadFile] = useMutation<{
        uploadFile: { url: string }
    }>(gql`
        mutation Upload($file: Upload!, $projectId: String!) {
            uploadFile(file: $file, projectId: $projectId) {
                id
                url
                type
                name
            }
        }
    `)

    const onEditorStateChange = (editorState: EditorState) => {
        onSetFieldValue(editorState)
    }

    const handleImageInsert = async (img: File) => {
        try {
            if (img.size >= 2000000) {
                message.error('File cannot be larger than 2MB')
                return
            }

            // @ts-ignore
            const { data } = await uploadFile({
                variables: {
                    file: img,
                    projectId
                }
            })

            if (data && data.uploadFile) {
                return { data: { link: data.uploadFile.url } }
            }
            throw Error('No url returned')
        } catch (error) {
            throw Error(error)
        }
    }

    const handleEmbedInsert = (link: string) => {
        const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link))
        return (detectedSrc && detectedSrc[1]) || link
    }

    const {
        h1FontFamily,
        h1FontSize,
        h2FontFamily,
        h2FontSize,
        h3FontFamily,
        h3FontSize,
        h4FontFamily,
        h4FontSize
    } = parsedEditorConfig

    const H1Option = (h1FontFamily || h1FontSize) && 'H1'
    const H2Option = (h2FontFamily || h2FontSize) && 'H2'
    const H3Option = (h3FontFamily || h3FontSize) && 'H3'
    const H4Option = (h4FontFamily || h4FontSize) && 'H4'

    const toolbarOptions = {
        blockType: {
            inDropdown: true,
            options: ['Normal', H1Option, H2Option, H3Option, H4Option, 'Code']
        },
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'monospace']
        },
        list: {
            inDropdown: false,
            options: ['unordered', 'ordered']
        },
        options: [
            'inline',
            'blockType',
            'list',
            'textAlign',
            'link',
            'image',
            'embedded',
            'history'
        ],
        image: {
            urlEnabled: true,
            uploadEnabled: true,
            uploadCallback: handleImageInsert,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: true, mandatory: false },
            defaultSize: {
                height: '100%',
                width: '100%'
            }
        },
        embedded: {
            embedCallback: handleEmbedInsert
        }
    }

    return (
        <Editor
            ref={editorEl}
            editorStyle={{
                fontSize: Number(parsedEditorConfig.fontSize),
                fontFamily: parsedEditorConfig.fontFamily
            }}
            // @ts-ignore
            blockStyleFn={setBlockStyles}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbarOptions}
            handlePastedText={() => false}
            stripPastedStyles={false}
        />
    )
}

export default withRouter(EditorComponent)
