import { Card, message } from 'antd'
import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import * as Yup from 'yup'
import Checkbox from '../../../../../components/Checkbox'
import Input from '../../../../../components/Input'
import SelectInput from '../../../../../components/SelectInput'
import Upload from '../../../../../components/Upload'
import {
    ProjectsDocument,
    useProjectQuery,
    useUpdateProjectMutation
} from '../../../../../generated/graphql'
import { FormH1, Label } from '../../../shared/styled'
import { ButtonGroup, Container, Row, RowItem, StyledButton } from '../../shared'
import {
    fontFamilyOptions,
    fontSizeOptions,
    FormValues,
    MarginedSpan,
    RowItemCollapsed
} from '../shared'

const validation = (t: TFunction) =>
    Yup.object().shape({
        name: Yup.string().required(t('validation.required'))
    })

const ProjectDetail = ({ history, match }: RouteComponentProps<{ id: string }>) => {
    const [t] = useTranslation()
    const { id } = match.params

    const { data, loading, error } = useProjectQuery({
        variables: { id }
    })

    const [updateProject, { loading: mutationLoading }] = useUpdateProjectMutation({
        update(cache, { data }) {
            try {
                const { projects }: any = cache.readQuery({
                    query: ProjectsDocument,
                    variables: { id }
                })
                const updateIndex = projects.findIndex(
                    (project: any) => project.id === data?.updateOneProject?.id
                )
                projects[updateIndex] = data?.updateOneProject
                cache.writeQuery({
                    query: ProjectsDocument,
                    variables: { id },
                    data: { projects }
                })
            } catch {}
        },
        onCompleted: () => {
            message.success(t('project.editing.success'))
        }
    })

    if (loading || !data) {
        return <div>loading..</div>
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const parsedLanguages = JSON.parse(data.project?.languages!)
    const parsedEditorConfig = JSON.parse(data.project?.editorConfig!)

    return (
        <Container>
            <Formik<Omit<FormValues, 'companyId' | 'email'>>
                initialValues={{
                    name: data.project?.name!,
                    webUrl: data.project?.webUrl!,
                    logo: null,
                    logoSmall: null,
                    faq: data.project?.faqFeature!,
                    restriction: data.project?.restrictionFeature!,
                    suggestion: data.project?.suggestionFeature!,
                    blog: data.project?.blogFeature!,
                    cs: parsedLanguages.cs,
                    sk: parsedLanguages.sk,
                    en: parsedLanguages.en,
                    de: parsedLanguages.de,
                    pl: parsedLanguages.pl,
                    encz: parsedLanguages.encz,
                    ensk: parsedLanguages.ensk,
                    enpl: parsedLanguages.enpl,
                    fontFamily: parsedEditorConfig.fontFamily,
                    fontSize: parsedEditorConfig.fontSize,
                    h1FontFamily: parsedEditorConfig.h1FontFamily,
                    h1FontSize: parsedEditorConfig.h1FontSize,
                    h2FontFamily: parsedEditorConfig.h2FontFamily,
                    h2FontSize: parsedEditorConfig.h2FontSize,
                    h3FontFamily: parsedEditorConfig.h3FontFamily,
                    h3FontSize: parsedEditorConfig.h3FontSize,
                    h4FontFamily: parsedEditorConfig.h4FontFamily,
                    h4FontSize: parsedEditorConfig.h4FontSize
                }}
                validationSchema={validation(t)}
                onSubmit={values => {
                    updateProject({
                        variables: {
                            ...values,
                            id,
                            languages: JSON.stringify({
                                cs: values.cs,
                                sk: values.sk,
                                en: values.en,
                                de: values.de,
                                pl: values.pl,
                                encz: values.encz,
                                ensk: values.ensk,
                                enpl: values.enpl
                            }),
                            editorConfig: JSON.stringify({
                                fontFamily: values.fontFamily,
                                fontSize: values.fontSize,
                                h1FontFamily: values.h1FontFamily,
                                h1FontSize: values.h1FontSize,
                                h2FontFamily: values.h2FontFamily,
                                h2FontSize: values.h2FontSize,
                                h3FontFamily: values.h3FontFamily,
                                h3FontSize: values.h3FontSize,
                                h4FontFamily: values.h4FontFamily,
                                h4FontSize: values.h4FontSize
                            })
                        }
                    })
                }}
            >
                {() => (
                    <Form>
                        <Card>
                            <FormH1>{t('project.shared.information')}</FormH1>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.name')}</Label>
                                    <Input name="name" size="large" />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.website')}</Label>
                                    <Input name="webUrl" size="large" />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.logo')}</Label>
                                    <Upload name="logo" accept="image/png,image/jpg,image/jpeg" />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.favicon')}</Label>
                                    <Upload
                                        name="logoSmall"
                                        accept="image/png,image/jpg,image/jpeg"
                                    />
                                </RowItem>
                            </Row>
                            <FormH1>{t('project.shared.features')}</FormH1>
                            <Row style={{ marginBottom: '30px' }}>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="blog" />
                                        <MarginedSpan>{t('project.shared.blog')}</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="restriction" />
                                        <MarginedSpan>
                                            {t('project.shared.restriction')}
                                        </MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="suggestion" />
                                        <MarginedSpan>
                                            {t('project.shared.suggestion')}
                                        </MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="faq" />
                                        <MarginedSpan>{t('project.shared.faq')}</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                            </Row>
                            <FormH1>{t('project.shared.languages')}</FormH1>
                            <Row style={{ marginBottom: '30px' }}>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="cs" />
                                        <MarginedSpan>CS</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="sk" />
                                        <MarginedSpan>SK</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="en" />
                                        <MarginedSpan>EN</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="de" />
                                        <MarginedSpan>DE</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="pl" />
                                        <MarginedSpan>PL</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="encz" />
                                        <MarginedSpan>EN-CZ</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="ensk" />
                                        <MarginedSpan>EN-SK</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                                <RowItemCollapsed>
                                    <Row>
                                        <Checkbox name="enpl" />
                                        <MarginedSpan>EN-PL</MarginedSpan>
                                    </Row>
                                </RowItemCollapsed>
                            </Row>
                            <FormH1>{t('project.shared.editor')}</FormH1>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.pFontFamily')}</Label>
                                    <SelectInput
                                        name="fontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.pFontSize')}</Label>
                                    <SelectInput
                                        name="fontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.h1FontFamily')}</Label>
                                    <SelectInput
                                        name="h1FontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.h1FontSize')}</Label>
                                    <SelectInput
                                        name="h1FontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.h2FontFamily')}</Label>
                                    <SelectInput
                                        name="h2FontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.h2FontSize')}</Label>
                                    <SelectInput
                                        name="h2FontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.h3FontFamily')}</Label>
                                    <SelectInput
                                        name="h3FontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.h3FontSize')}</Label>
                                    <SelectInput
                                        name="h3FontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <Row>
                                <RowItem>
                                    <Label>{t('project.shared.h4FontFamily')}</Label>
                                    <SelectInput
                                        name="h4FontFamily"
                                        size="large"
                                        options={fontFamilyOptions}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label>{t('project.shared.h4FontSize')}</Label>
                                    <SelectInput
                                        name="h4FontSize"
                                        size="large"
                                        options={fontSizeOptions}
                                    />
                                </RowItem>
                            </Row>
                            <ButtonGroup>
                                <StyledButton onClick={() => history.goBack()}>
                                    {t('project.shared.cancel')}
                                </StyledButton>
                                <StyledButton
                                    htmlType="submit"
                                    type="primary"
                                    loading={mutationLoading}
                                >
                                    {t('project.shared.save')}
                                </StyledButton>
                            </ButtonGroup>
                        </Card>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default ProjectDetail
