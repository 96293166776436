import { Avatar, Icon, Layout } from 'antd'
import styled from 'styled-components'

const { Header, Sider, Content } = Layout

interface Styled {
    collapsed: boolean | 1 | 0
}

export const StyledSider = styled(Sider)<Styled>`
    position: fixed;
    height: 100%;
    background-color: #fafafa;

    max-width: ${({ collapsed }) => (collapsed ? '80px' : '220px')} !important;
    min-width: ${({ collapsed }) => (collapsed ? '80px' : '220px')} !important;
    width: ${({ collapsed }) => (collapsed ? '80px' : '220px')} !important;

    padding: 0 15px;

    > * {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    li {
        background-color: #fafafa !important;
        border: none !important;
        margin: 0 0 20px 0 !important;
        padding: 0 0 0 25px !important;
        width: 100% !important;

        &::after {
            border: none !important;
        }
    }
`

export const StyledDownUpIcon = styled(Icon)`
    font-size: 10px;
    margin: 0 5px;
`

export const StyledAvatar = styled(Avatar)`
    margin-right: 10px;
    margin-left: 15px;
`

export const ProfileImg = styled.img`
    width: 32px;
    border-radius: 32px;
    margin-right: 10px;
    margin-left: 15px;
`

export const ProjectLogoActive = styled.img`
    width: 120px;
    height: 28px;
`

export const ProjectLogoActiveSmall = styled.img`
    width: 32px;
    height: 32px;
`

export const SiderFooter = styled.div<Styled>`
    margin: 40px auto;
    width: ${props => (!props.collapsed ? '180px' : '80px')};
    height: ${props => (!props.collapsed ? '200px' : '0')};
    position: relative;
    border-radius: 10px;
    background-color: #fafafa;
`

export const SiderFooterText = styled.div`
    margin-top: 10px;
    margin-left: 10px;
    > p:first-child {
        font-weight: bold;
        margin-bottom: 7px;
    }
    > p {
        margin-bottom: 0;
    }
`

export const WomanImg = styled.img`
    position: absolute;
    bottom: -3px;
    right: -2px;
`

export const QAdminImg = styled.img`
    position: absolute;
    bottom: 15px;
    left: 10px;
`

export const QAdminSmallImg = styled.img`
    position: absolute;
    bottom: 0;
    left: 25px;
`

export const BubbleImg = styled.img`
    position: absolute;
    bottom: -10px;
    right: 30px;
    transform: rotate(150deg);
`

export const Logo = styled.div`
    margin: 50px auto;
    display: flex;
    justify-content: center;
`

export const RightSide = styled.div`
    display: flex;
    margin-right: 20px;
`

export const StyledContent = styled(Content)<Styled>`
    margin-left: ${props => (props.collapsed ? '80px' : '200px')};
    margin-top: 64px;
    padding: 60px 50px;
    background-color: #fafafa;
    transition-duration: 0.2s;
`

export const StyledHeader = styled(Header)<Styled>`
    margin-left: ${props => (props.collapsed ? '80px' : '220px')};
    width: ${props => (!props.collapsed ? 'calc(100% - 220px)' : 'calc(100% - 80px)')};
    z-index: 1000;
    position: fixed;
    background-color: #f5f5f5;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition-duration: 0.2s;
`

export const StyledLayout = styled(Layout)`
    min-height: 100vh;
`
