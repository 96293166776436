/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
import Truncate from 'react-truncate'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import React, { useEffect, useContext, useState } from 'react'
import { Button, Empty, Icon, Menu, message, Table, Tooltip } from 'antd'

import '../styles.css'
import '../../../shared/styles.css'
import PrevNext from '../../shared/PrevNext'
import { languageIcons } from '../../shared'
import Loader from '../../../../../components/Loader'
import { AddButton, CategoryLabel } from '../../styled'
import { Auth } from '../../../../../context/Authentication'
import { FilterIconWrapper, FilterTitle } from '../../shared/styled'
import { MenuIcon, IconContainer } from '../../FaqsManagement/shared'
import ConfirmationModal from '../../../../../components/ConfirmationModal'
import {
    PostsDocument,
    PostsQuery,
    useDeletePostMutation,
    useDuplicatePostMutation,
    usePostsQuery
} from '../../../../../generated/graphql'
import {
    Header,
    ListH1,
    PrimaryColumn,
    StatusIcon,
    StyledMenu,
    TableHeader,
    TransparentButton
} from '../../../shared/styled'

const { Item } = Menu

type Post = PostsQuery['posts'][0]

export default ({ history, match }: RouteComponentProps<{ projectId: string }>) => {
    const projectId = match.params.projectId

    const [t] = useTranslation()
    const { user } = useContext(Auth)

    const [modalState, setModalState] = useState<{
        opened: boolean
        ids: string[] | []
    }>({ opened: false, ids: [] })
    const [posts, setPosts] = useState<Post[]>([])
    const [languageFilter, setLanguageFilter] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [categoryFilters, setCategoryFilters] = useState<{ text: string; value: string }[]>([])

    const parsedLanguages = JSON.parse(user.activeProject!.languages)

    const { data, error, loading } = usePostsQuery({
        variables: { projectId }
    })

    const [duplicatePost] = useDuplicatePostMutation({
        update(cache, { data }) {
            const { posts }: any = cache.readQuery({
                query: PostsDocument,
                variables: { projectId }
            })
            posts.push(data?.duplicatePost)
            posts.sort(
                (a: Post, b: Post) =>
                    +new Date(b.PublishedVersion.createdAt) -
                    +new Date(a.PublishedVersion.createdAt)
            )
            cache.writeQuery({
                query: PostsDocument,
                variables: { projectId },
                data: { posts }
            })
        },
        onCompleted: () => message.success(t('blog.duplicateSuccess'))
    })

    const [deletePost] = useDeletePostMutation({
        update(cache, { data }) {
            const { posts }: any = cache.readQuery({
                query: PostsDocument,
                variables: { projectId }
            })
            const deleteIndex = posts.map((post: Post) => post.id).indexOf(data?.deleteOnePost?.id)
            posts.splice(deleteIndex, 1)
            cache.writeQuery({
                data: { posts },
                query: PostsDocument,
                variables: { projectId }
            })
        }
    })

    useEffect(() => {
        if (!data) return

        if (!languageFilter) {
            setPosts(data.posts)
        } else {
            const newPosts: Post[] = []
            data.posts.forEach(post => {
                if (
                    post &&
                    post.PublishedVersion.language.toLocaleLowerCase() ===
                        languageFilter.toLocaleLowerCase()
                ) {
                    newPosts.push(post)
                }
            })

            setPosts(newPosts)
        }

        data.posts.forEach(post =>
            post.PublishedVersion.categories.forEach(category => {
                setCategoryFilters([
                    ...categoryFilters,
                    {
                        text: category.name,
                        value: category.id
                    }
                ])
            })
        )
    }, [data, languageFilter])

    if (loading || !data) {
        return <Loader />
    }

    if (error) {
        console.log(error)
        return <div>error..</div>
    }

    const columns = [
        {
            title: t('blog.title'),
            dataIndex: 'title',
            width: 280,
            render: (_: any, record: Post) => (
                <PrimaryColumn onClick={() => history.push(`/${projectId}/posts/${record.id}`)}>
                    <Truncate lines={2} ellipsis={<span>...</span>}>
                        {record.PublishedVersion.title}
                    </Truncate>
                    <img
                        src={languageIcons[record.PublishedVersion.language]}
                        alt={record.PublishedVersion.language}
                    />
                </PrimaryColumn>
            ),
            sorter: (a: Post, b: Post) =>
                a.PublishedVersion.title > b.PublishedVersion.title ? 1 : -1
        },
        {
            dataIndex: 'author',
            render: (_: any, record: Post) => record.PublishedVersion.Author.name,
            width: 160,
            filterIcon: (filtered: boolean) => (
                <FilterIconWrapper>
                    <FilterTitle>{t('blog.author')}</FilterTitle>
                    <Icon
                        type="filter"
                        theme={filtered ? 'filled' : 'outlined'}
                        style={{ color: '#000' }}
                    />
                </FilterIconWrapper>
            ),
            filters: uniqBy(
                data.posts.map(post => ({
                    text: post.PublishedVersion.Author.name,
                    value: post.PublishedVersion.Author.id
                })),
                'value'
            ),
            onFilter: (value: string, record: Post) =>
                record.PublishedVersion.Author.id.indexOf(value) === 0
        },
        {
            title: t('blog.createdAt'),
            dataIndex: 'createdAt',
            width: 120,
            render: (_: any, record: Post) =>
                moment(record.PublishedVersion.createdAt).format('DD.MM.YYYY'),
            sorter: (a: Post, b: Post) =>
                a.PublishedVersion.createdAt > b.PublishedVersion.createdAt ? 1 : -1
        },
        {
            dataIndex: 'categories',
            className: 'categories-column',
            width: 150,
            filterIcon: (filtered: boolean) => (
                <FilterIconWrapper>
                    <FilterTitle>{t('blog.categories')}</FilterTitle>
                    <Icon
                        type="filter"
                        theme={filtered ? 'filled' : 'outlined'}
                        style={{ color: '#000' }}
                    />
                </FilterIconWrapper>
            ),
            render: (_: any, record: Post) => {
                return record.PublishedVersion.categories.map(category => (
                    <CategoryLabel key={category.id}>{category.name}</CategoryLabel>
                ))
            },
            filters: uniqBy(categoryFilters, 'value'),
            onFilter: (value: string, record: Post) =>
                !!record.PublishedVersion.categories.filter(category => category.id === value)
                    .length
        },
        {
            title: t('blog.status'),
            width: 85,
            render: (post: Post) => {
                return (
                    <Tooltip title={post.PublishedVersion.published ? 'Posted' : 'Unposted'}>
                        <StatusIcon published={post.PublishedVersion.published} />
                    </Tooltip>
                )
            },
            sorter: (a: Post, b: Post) =>
                a.PublishedVersion.published === b.PublishedVersion.published
                    ? 0
                    : a.PublishedVersion.published
                    ? -1
                    : 1
        },
        {
            title: t('blog.action'),
            width: 100,
            render: (post: Post) => {
                return (
                    <IconContainer>
                        <Tooltip title={t('blog.edit')}>
                            <MenuIcon
                                type="edit"
                                onClick={() => history.push(`/${projectId}/posts/${post.id}`)}
                                style={{ marginRight: 20 }}
                            />
                        </Tooltip>
                        <Tooltip title={t('blog.duplicate')}>
                            <MenuIcon
                                type="copy"
                                onClick={() => duplicatePost({ variables: { id: post.id } })}
                                style={{ marginRight: 20 }}
                            />
                        </Tooltip>
                        <Tooltip title={t('blog.delete')}>
                            <MenuIcon
                                type="delete"
                                onClick={() =>
                                    setModalState({
                                        opened: true,
                                        ids: [post.id]
                                    })
                                }
                            />
                        </Tooltip>
                    </IconContainer>
                )
            }
        }
    ]

    const isSingular = modalState.ids.length <= 1

    return (
        <>
            <Header>
                <ListH1>{t('blog.heading')}</ListH1>
                <AddButton
                    onClick={() =>
                        history.push(`/${projectId}/post-new`, {
                            selectedLanguage: languageFilter
                        })
                    }
                >
                    <Icon type="plus" />
                    &nbsp;&nbsp;{t('blog.add')}
                </AddButton>
            </Header>

            <ConfirmationModal
                setState={setModalState}
                visible={modalState.opened}
                title={isSingular ? t('blog.deleteConfirmTitle') : t('blog.deleteConfirmTitlePl')}
                text={isSingular ? t('blog.deleteConfirmText') : t('blog.deleteConfirmTextPl')}
                action={() => {
                    modalState.ids.forEach(id =>
                        deletePost({
                            variables: {
                                id
                            }
                        })
                    )
                    setSelectedRowKeys([])
                    message.success(
                        isSingular ? t('blog.deleteSuccess') : t('blog.deleteSuccessPl')
                    )
                }}
            />

            {data.posts.length ? (
                <Table<Post>
                    rowKey={record => record.id}
                    columns={columns}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: selectedKeys => {
                            setSelectedRowKeys(selectedKeys as string[])
                        }
                    }}
                    dataSource={posts}
                    pagination={{
                        position: 'bottom',
                        style: { float: 'left' },
                        defaultPageSize: 10,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <PrevNext type="left" current={current} />
                            }
                            if (type === 'next') {
                                return <PrevNext type="right" current={current} />
                            }
                            return originalElement
                        },
                        showSizeChanger: true
                    }}
                    title={() => (
                        <TableHeader>
                            <StyledMenu mode="horizontal" defaultSelectedKeys={['all']}>
                                <Item key="all" onClick={() => setLanguageFilter('')}>
                                    <TransparentButton>{t('blog.all')}</TransparentButton>
                                </Item>
                                {parsedLanguages.cs && (
                                    <Item onClick={() => setLanguageFilter('CS')}>
                                        <TransparentButton>
                                            <img src={languageIcons.CS} alt="cs" />
                                            {t('blog.czech')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.sk && (
                                    <Item onClick={() => setLanguageFilter('SK')}>
                                        <TransparentButton>
                                            <img src={languageIcons.SK} alt="sk" />
                                            {t('blog.slovak')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.en && (
                                    <Item onClick={() => setLanguageFilter('EN')}>
                                        <TransparentButton>
                                            <img src={languageIcons.EN} alt="en" />
                                            {t('blog.english')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.de && (
                                    <Item onClick={() => setLanguageFilter('DE')}>
                                        <TransparentButton>
                                            <img src={languageIcons.DE} alt="de" />
                                            {t('blog.german')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.pl && (
                                    <Item onClick={() => setLanguageFilter('PL')}>
                                        <TransparentButton>
                                            <img src={languageIcons.PL} alt="pl" />
                                            {t('blog.polish')}
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.encz && (
                                    <Item onClick={() => setLanguageFilter('ENCZ')}>
                                        <TransparentButton>
                                            <img src={languageIcons.ENCZ} alt="encz" /> EN-CZ
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.ensk && (
                                    <Item onClick={() => setLanguageFilter('ENSK')}>
                                        <TransparentButton>
                                            <img src={languageIcons.ENSK} alt="ensk" /> EN-SK
                                        </TransparentButton>
                                    </Item>
                                )}
                                {parsedLanguages.enpl && (
                                    <Item onClick={() => setLanguageFilter('ENPL')}>
                                        <TransparentButton>
                                            <img src={languageIcons.ENPL} alt="enpl" /> EN-PL
                                        </TransparentButton>
                                    </Item>
                                )}
                            </StyledMenu>
                            <Button
                                type="danger"
                                disabled={!selectedRowKeys.length}
                                onClick={() =>
                                    setModalState({
                                        opened: true,
                                        ids: selectedRowKeys
                                    })
                                }
                            >
                                {t('blog.delete')}
                            </Button>
                        </TableHeader>
                    )}
                />
            ) : (
                <Empty imageStyle={{ height: 160 }} description={<span>{t('blog.none')}</span>}>
                    <Button type="primary" onClick={() => history.push(`/${projectId}/post-new`)}>
                        <Icon type="plus" />
                        {t('blog.add')}
                    </Button>
                </Empty>
            )}
        </>
    )
}
