import React from 'react'
import { ContainerLink } from './styled'
import { Icon } from 'antd'

const PrevNext = ({ type, current }: { type: 'left' | 'right', current: number }) => {
  return (
    <ContainerLink page={current} type={type}>
      <Icon type={type} />
    </ContainerLink>
  )
}

export default PrevNext