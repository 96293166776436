import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import queryString from 'query-string'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import * as Yup from 'yup'

import Layout from '../Layout'
import Input from '../../../components/Input'
import { A, P, StyledButton } from '../shared'
import Checkbox from '../../../components/Checkbox'
import { Auth } from '../../../context/Authentication'

interface FormValues {
    email: string
    name: string
    password: string
    consent: boolean
}

const registerValidation = (t: TFunction) =>
    Yup.object().shape({
        email: Yup.string()
            .email(t('validation.email'))
            .required(t('validation.required')),
        name: Yup.string()
            .min(3, t('validation.name'))
            .required(t('validation.required')),
        password: Yup.string()
            .min(6, t('validation.password'))
            .required(t('validation.required')),
        consent: Yup.boolean().oneOf([true], t('validation.check'))
    })

const Register = ({ location }: RouteComponentProps) => {
    const [t] = useTranslation()
    const { confirmUser } = useContext(Auth)
    const { email } = queryString.parse(location.search)

    return (
        <Layout heading1={t('register.heading')} heading2={t('register.description')}>
            <Formik<FormValues>
                initialValues={{
                    email: email as string,
                    name: '',
                    password: '',
                    consent: false
                }}
                validationSchema={registerValidation(t)}
                onSubmit={values => {
                    confirmUser(values)
                }}
            >
                {() => (
                    <Form>
                        <P disabled>{t('register.email')}</P>
                        <Input
                            name="email"
                            icon="user"
                            placeholder={t('register.email')}
                            disabled
                        />
                        <P disabled>{t('register.name')}</P>
                        <Input name="name" icon="user" placeholder={t('register.name')} />
                        <P bold>{t('register.password')}</P>
                        <Input
                            name="password"
                            icon="lock"
                            type="password"
                            placeholder={t('register.password')}
                        />
                        <Checkbox name="consent">
                            <>
                                {t('register.consent1')} <A href="">{t('register.consent2')}</A>
                            </>
                        </Checkbox>
                        <StyledButton htmlType="submit" type="primary">
                            {t('register.register')}
                        </StyledButton>
                    </Form>
                )}
            </Formik>
            <P>{t('register.troubles')}</P>
            <P>{t('register.contact')}</P>
        </Layout>
    )
}

export default Register
