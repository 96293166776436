import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DropdownContainer,
    ArrowDown,
    ProjectLogo,
    DropdownHead,
    DropdownItem,
    DropdownContent,
    ProjectName
} from './styled'

import arrow from '../../../../../assets/arrow-black.svg'
import { Auth } from '../../../../../context/Authentication'

const ProjectDropdown = ({
    activeProject,
    userProjects,
    history,
    dropdownOpen,
    setDropdownOpen,
    collapsed
}: {
    activeProject: any
    userProjects: any[]
    history: any
    dropdownOpen: boolean
    setDropdownOpen: (open: boolean) => void
    collapsed: boolean
}) => {
    const [t] = useTranslation()
    const { setActiveProject } = useContext(Auth)

    return (
        <DropdownContainer
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
        >
            <DropdownHead open={dropdownOpen} collapsed={collapsed}>
                <ProjectLogo src={activeProject.logoSmall} alt="logo" />
                {!collapsed ? <ProjectName>{activeProject.name}</ProjectName> : null}
                <ArrowDown src={arrow} alt="dropdown arrow" open={dropdownOpen} />
            </DropdownHead>
            <DropdownContent open={dropdownOpen} collapsed={collapsed}>
                {!collapsed ? <h4>{t('layout.projectSelect')}</h4> : null}
                {userProjects.map(project => (
                    <DropdownItem
                        key={project.id}
                        title={project.name}
                        onClick={() => setActiveProject(project, history)}
                        collapsed={collapsed}
                    >
                        <ProjectLogo src={project.logoSmall} />
                        <ProjectName>{project.name}</ProjectName>
                    </DropdownItem>
                ))}
            </DropdownContent>
        </DropdownContainer>
    )
}

export default ProjectDropdown
