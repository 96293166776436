import { Form, Input } from 'antd'
import { Field, FieldConfig, FieldProps } from 'formik'
import React from 'react'
import { ErrorText } from '../shared'

const { Item } = Form
const { TextArea } = Input

interface Props {
    required?: boolean
    placeholder?: string
    autosize?: { minRows: number; maxRows: number }
}

const TextInput = ({
    required,
    placeholder,
    autosize,
    form: { errors, touched },
    field
}: Props & FieldProps) => (
    <Item
        validateStatus={touched[field.name] && errors[field.name] ? 'error' : ''}
        required={required}
    >
        <TextArea {...field} placeholder={placeholder} autosize={autosize} />
        {touched[field.name] && errors[field.name] && <ErrorText>{errors[field.name]}</ErrorText>}
    </Item>
)

export default (props: Omit<FieldConfig, 'component'> & Props) => (
    <Field {...props} component={TextInput} />
)
