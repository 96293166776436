import { Dropdown, Icon, Layout, Menu } from "antd";
import React, { Dispatch, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import bubble from "../../../../assets/bubble.svg";
import cs from "../../../../assets/czech.svg";
import en from "../../../../assets/english.svg";
import qAdminSmall from "../../../../assets/q-admin-small.svg";
import qAdmin from "../../../../assets/q-admin.svg";
import woman from "../../../../assets/woman.svg";
import { Routes } from "../../../../constants";
import { Auth } from "../../../../context/Authentication";
import { getMatchedKey } from "../../shared";
import {
  BubbleImg,
  Logo,
  ProfileImg,
  ProjectLogoActive,
  ProjectLogoActiveSmall,
  QAdminImg,
  QAdminSmallImg,
  RightSide,
  SiderFooter,
  SiderFooterText,
  StyledAvatar,
  StyledContent,
  StyledDownUpIcon,
  StyledHeader,
  StyledLayout,
  StyledSider,
  WomanImg
} from "../../shared/layout";
import "../../shared/styles.css";

const { Item } = Menu;

interface Props {
  children: JSX.Element[] | JSX.Element;
  collapsed: boolean;
  toggleCollapse: Dispatch<any>;
  isSmScreen: boolean;
}

const AdminLayout = ({
  children,
  history,
  collapsed,
  toggleCollapse,
  isSmScreen
}: Props & RouteComponentProps) => {
  const [t, i18n] = useTranslation();
  const { signOut, user } = useContext(Auth);
  if (!user) {
    return null;
  }

  const language = i18n.language.substring(0, 2);

  const profileMenu = (
    <Menu>
      <Item onClick={() => history.push(Routes.PROFILE)}>
        {t("layout.profile")}
      </Item>
      <Item onClick={() => signOut()}>{t("layout.signout")}</Item>
    </Menu>
  );

  const languageMenu = (
    <Menu>
      <Item
        onClick={() => i18n.changeLanguage(language === "cs" ? "en" : "cs")}
      >
        <img src={language === "cs" ? en : cs} alt={language} />
      </Item>
    </Menu>
  );

  return (
    <StyledLayout>
      <StyledSider trigger={null} collapsible collapsed={collapsed}>
        <div>
          <Link to={Routes.ADMIN}>
            {!collapsed ? (
              <Logo>
                <ProjectLogoActive src={qAdmin} alt="logo" />
              </Logo>
            ) : (
              <Logo>
                <ProjectLogoActiveSmall src={qAdminSmall} alt="logo" />
              </Logo>
            )}
          </Link>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[getMatchedKey(location.pathname)]}
          >
            <Item key={Routes.ADMIN}>
              <Link to={Routes.ADMIN}>
                <Icon type="dashboard" />
                <span>{t("layout.dashboard")}</span>
              </Link>
            </Item>
            <Item key={Routes.COMPANIES}>
              <Link to={Routes.COMPANIES}>
                <Icon type="team" />
                <span>{t("layout.company")}</span>
              </Link>
            </Item>
            <Item key={Routes.PROJECTS}>
              <Link to={Routes.PROJECTS}>
                <Icon type="solution" />
                <span>{t("layout.project")}</span>
              </Link>
            </Item>
            <Item key="/qusion">
              <a href="https://qusion.com" target="__blank">
                <Icon type="appstore" />
                <span>{t("layout.website")}</span>
              </a>
            </Item>
          </Menu>
        </div>
        <SiderFooter collapsed={collapsed}>
          {!collapsed ? (
            <>
              <SiderFooterText>
                <p>{t("layout.help")}</p>
                <p>{t("layout.helpName")}</p>
                <p>{t("layout.helpEmail")}</p>
                <p>{t("layout.helpPhone")}</p>
              </SiderFooterText>
              <WomanImg src={woman} alt="woman" />
              <BubbleImg src={bubble} alt="bubble" />
              <QAdminImg src={qAdmin} alt="qAdmin" />
            </>
          ) : (
            <QAdminSmallImg src={qAdminSmall} alt="qAdmin" />
          )}
        </SiderFooter>
      </StyledSider>
      <Layout>
        <StyledHeader collapsed={collapsed ? 1 : 0}>
          {!isSmScreen ? (
            <Icon
              type={collapsed ? "menu-unfold" : "menu-fold"}
              onClick={() => toggleCollapse(!collapsed)}
            />
          ) : (
            <div />
          )}
          <RightSide>
            <Dropdown overlay={languageMenu}>
              <div>
                <img src={language === "cs" ? cs : en} alt={language} />
                <StyledDownUpIcon type="down" />
              </div>
            </Dropdown>
            <Dropdown overlay={profileMenu}>
              <div>
                {user.profileImg ? (
                  <ProfileImg alt="profile_img" src={user.profileImg} />
                ) : (
                  <StyledAvatar icon="user" />
                )}
                {user.name}
                <StyledDownUpIcon type="down" />
              </div>
            </Dropdown>
          </RightSide>
        </StyledHeader>
        <StyledContent collapsed={collapsed ? 1 : 0}>{children}</StyledContent>
      </Layout>
    </StyledLayout>
  );
};

export default AdminLayout;
