import { DatePicker, Form } from 'antd'
import { Field, FieldConfig, FieldProps } from 'formik'
import React from 'react'
import { ErrorText } from '../shared'

const { Item } = Form

interface Props {
    required?: boolean
}

const DateInput = ({
    required,
    form: { errors, touched, setFieldTouched, setFieldValue },
    field
}: Props & FieldProps) => (
    <Item
        validateStatus={touched[field.name] && errors[field.name] ? 'error' : ''}
        required={required}
    >
        <DatePicker
            {...field}
            style={{ width: '100%' }}
            allowClear={false}
            format="M.DD.YYYY"
            // @ts-ignore
            onBlur={() => setFieldTouched(field.name)}
            onChange={dateString => setFieldValue(field.name, dateString)}
        />
        {touched[field.name] && errors[field.name] && <ErrorText>{errors[field.name]}</ErrorText>}
    </Item>
)

export default (props: Omit<FieldConfig, 'component'> & Props) => (
    <Field {...props} component={DateInput} />
)
