import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};

export type BatchMeta = {
  itemsPerPage: Scalars['Int'],
  page: Scalars['Int'],
  pages: Scalars['Int'],
  items: Scalars['Int'],
};

export type BooleanFilter = {
  equals?: Maybe<Scalars['Boolean']>,
  not?: Maybe<Scalars['Boolean']>,
};

export type ClientFaq = {
  id: Scalars['String'],
  answer: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  question: Scalars['String'],
  language: Scalars['String'],
  published: Scalars['Boolean'],
  largeLink?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>,
};

export type ClientPost = {
  id: Scalars['String'],
  url: Scalars['String'],
  slug: Scalars['String'],
  text: Scalars['String'],
  title: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  mainImg: Scalars['String'],
  language: Scalars['String'],
  tags?: Maybe<Scalars['String']>,
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  /** Minutes */
  readingTime: Scalars['Int'],
  categories: Array<ClientPostCategory>,
  project: ClientProject,
  author: ClientPostAuthor,
  relatedPosts?: Maybe<Array<ClientPost>>,
};

export type ClientPostAuthor = {
  id: Scalars['String'],
  name: Scalars['String'],
  profileImg?: Maybe<Scalars['String']>,
};

export type ClientPostCategory = {
  id: Scalars['String'],
  name: Scalars['String'],
};

export type ClientPosts = {
  data: Array<ClientPost>,
  meta: BatchMeta,
};

export type ClientProject = {
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
};

export type ClientRestriction = {
  id: Scalars['String'],
  logoImg: Scalars['String'],
  slug: Scalars['String'],
  createdAt: Scalars['DateTime'],
  versions?: Maybe<Array<ClientRestrictionVersion>>,
};

export type ClientRestrictionVersion = {
  id: Scalars['String'],
  language: Scalars['String'],
  title: Scalars['String'],
  text: Scalars['String'],
};

export type ClientSuggestion = {
  id: Scalars['String'],
  title: Scalars['String'],
  img: Scalars['String'],
  items?: Maybe<Array<ClientSuggestionItem>>,
};

export type ClientSuggestionItem = {
  id: Scalars['String'],
  img: Scalars['String'],
  index: Scalars['Int'],
  text: Scalars['String'],
  title: Scalars['String'],
};

export type Company = {
  id: Scalars['String'],
  name: Scalars['String'],
  projects: Array<Project>,
};


export type CompanyProjectsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<ProjectWhereUniqueInput>,
  after?: Maybe<ProjectWhereUniqueInput>
};

export type CompanyCreateInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  projects?: Maybe<ProjectCreateManyWithoutCompanyInput>,
};

export type CompanyCreateOneWithoutProjectsInput = {
  create?: Maybe<CompanyCreateWithoutProjectsInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
};

export type CompanyCreateWithoutProjectsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
};

export type CompanyUpdateInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  projects?: Maybe<ProjectUpdateManyWithoutCompanyInput>,
};

export type CompanyUpdateOneRequiredWithoutProjectsInput = {
  create?: Maybe<CompanyCreateWithoutProjectsInput>,
  connect?: Maybe<CompanyWhereUniqueInput>,
  update?: Maybe<CompanyUpdateWithoutProjectsDataInput>,
  upsert?: Maybe<CompanyUpsertWithoutProjectsInput>,
};

export type CompanyUpdateWithoutProjectsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type CompanyUpsertWithoutProjectsInput = {
  update: CompanyUpdateWithoutProjectsDataInput,
  create: CompanyCreateWithoutProjectsInput,
};

export type CompanyWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  projects?: Maybe<ProjectFilter>,
  AND?: Maybe<Array<CompanyWhereInput>>,
  OR?: Maybe<Array<CompanyWhereInput>>,
  NOT?: Maybe<Array<CompanyWhereInput>>,
};

export type CompanyWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};


export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>,
  not?: Maybe<Scalars['DateTime']>,
  in?: Maybe<Array<Scalars['DateTime']>>,
  notIn?: Maybe<Array<Scalars['DateTime']>>,
  lt?: Maybe<Scalars['DateTime']>,
  lte?: Maybe<Scalars['DateTime']>,
  gt?: Maybe<Scalars['DateTime']>,
  gte?: Maybe<Scalars['DateTime']>,
};

export type Faq = {
  id: Scalars['String'],
  question: Scalars['String'],
  answer: Scalars['String'],
  language: Scalars['String'],
  published: Scalars['Boolean'],
  largeLink?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  Category?: Maybe<FaqCategory>,
  Author: User,
  Project: Project,
};

export type FaqCategory = {
  id: Scalars['String'],
  name: Scalars['String'],
  Project: Project,
};

export type FaqCategoryCreateInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  Project: ProjectCreateOneWithoutFaqCategoriesInput,
  faqs?: Maybe<FaqCreateManyWithoutCategoryInput>,
};

export type FaqCategoryCreateManyWithoutProjectInput = {
  create?: Maybe<Array<FaqCategoryCreateWithoutProjectInput>>,
  connect?: Maybe<Array<FaqCategoryWhereUniqueInput>>,
};

export type FaqCategoryCreateOneWithoutFaqsInput = {
  create?: Maybe<FaqCategoryCreateWithoutFaqsInput>,
  connect?: Maybe<FaqCategoryWhereUniqueInput>,
};

export type FaqCategoryCreateWithoutFaqsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  Project: ProjectCreateOneWithoutFaqCategoriesInput,
};

export type FaqCategoryCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  faqs?: Maybe<FaqCreateManyWithoutCategoryInput>,
};

export type FaqCategoryFilter = {
  every?: Maybe<FaqCategoryWhereInput>,
  some?: Maybe<FaqCategoryWhereInput>,
  none?: Maybe<FaqCategoryWhereInput>,
};

export type FaqCategoryScalarWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  faqs?: Maybe<FaqFilter>,
  AND?: Maybe<Array<FaqCategoryScalarWhereInput>>,
  OR?: Maybe<Array<FaqCategoryScalarWhereInput>>,
  NOT?: Maybe<Array<FaqCategoryScalarWhereInput>>,
};

export type FaqCategoryUpdateInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutFaqCategoriesInput>,
  faqs?: Maybe<FaqUpdateManyWithoutCategoryInput>,
};

export type FaqCategoryUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type FaqCategoryUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<FaqCategoryCreateWithoutProjectInput>>,
  connect?: Maybe<Array<FaqCategoryWhereUniqueInput>>,
  set?: Maybe<Array<FaqCategoryWhereUniqueInput>>,
  disconnect?: Maybe<Array<FaqCategoryWhereUniqueInput>>,
  delete?: Maybe<Array<FaqCategoryWhereUniqueInput>>,
  update?: Maybe<Array<FaqCategoryUpdateWithWhereUniqueWithoutProjectInput>>,
  updateMany?: Maybe<Array<FaqCategoryUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<FaqCategoryScalarWhereInput>>,
  upsert?: Maybe<Array<FaqCategoryUpsertWithWhereUniqueWithoutProjectInput>>,
};

export type FaqCategoryUpdateManyWithWhereNestedInput = {
  where: FaqCategoryScalarWhereInput,
  data: FaqCategoryUpdateManyDataInput,
};

export type FaqCategoryUpdateOneWithoutFaqsInput = {
  create?: Maybe<FaqCategoryCreateWithoutFaqsInput>,
  connect?: Maybe<FaqCategoryWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<FaqCategoryUpdateWithoutFaqsDataInput>,
  upsert?: Maybe<FaqCategoryUpsertWithoutFaqsInput>,
};

export type FaqCategoryUpdateWithoutFaqsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutFaqCategoriesInput>,
};

export type FaqCategoryUpdateWithoutProjectDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  faqs?: Maybe<FaqUpdateManyWithoutCategoryInput>,
};

export type FaqCategoryUpdateWithWhereUniqueWithoutProjectInput = {
  where: FaqCategoryWhereUniqueInput,
  data: FaqCategoryUpdateWithoutProjectDataInput,
};

export type FaqCategoryUpsertWithoutFaqsInput = {
  update: FaqCategoryUpdateWithoutFaqsDataInput,
  create: FaqCategoryCreateWithoutFaqsInput,
};

export type FaqCategoryUpsertWithWhereUniqueWithoutProjectInput = {
  where: FaqCategoryWhereUniqueInput,
  update: FaqCategoryUpdateWithoutProjectDataInput,
  create: FaqCategoryCreateWithoutProjectInput,
};

export type FaqCategoryWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  faqs?: Maybe<FaqFilter>,
  AND?: Maybe<Array<FaqCategoryWhereInput>>,
  OR?: Maybe<Array<FaqCategoryWhereInput>>,
  NOT?: Maybe<Array<FaqCategoryWhereInput>>,
  Project?: Maybe<ProjectWhereInput>,
};

export type FaqCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type FaqCreateManyWithoutAuthorInput = {
  create?: Maybe<Array<FaqCreateWithoutAuthorInput>>,
  connect?: Maybe<Array<FaqWhereUniqueInput>>,
};

export type FaqCreateManyWithoutCategoryInput = {
  create?: Maybe<Array<FaqCreateWithoutCategoryInput>>,
  connect?: Maybe<Array<FaqWhereUniqueInput>>,
};

export type FaqCreateManyWithoutProjectInput = {
  create?: Maybe<Array<FaqCreateWithoutProjectInput>>,
  connect?: Maybe<Array<FaqWhereUniqueInput>>,
};

export type FaqCreateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>,
  question: Scalars['String'],
  answer: Scalars['String'],
  published: Scalars['Boolean'],
  language: Scalars['String'],
  largeLinkTitle?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  Category?: Maybe<FaqCategoryCreateOneWithoutFaqsInput>,
  Project: ProjectCreateOneWithoutFaqsInput,
};

export type FaqCreateWithoutCategoryInput = {
  id?: Maybe<Scalars['String']>,
  question: Scalars['String'],
  answer: Scalars['String'],
  published: Scalars['Boolean'],
  language: Scalars['String'],
  largeLinkTitle?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  Project: ProjectCreateOneWithoutFaqsInput,
  Author: UserCreateOneWithoutFaqsInput,
};

export type FaqCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>,
  question: Scalars['String'],
  answer: Scalars['String'],
  published: Scalars['Boolean'],
  language: Scalars['String'],
  largeLinkTitle?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  Category?: Maybe<FaqCategoryCreateOneWithoutFaqsInput>,
  Author: UserCreateOneWithoutFaqsInput,
};

export type FaqFilter = {
  every?: Maybe<FaqWhereInput>,
  some?: Maybe<FaqWhereInput>,
  none?: Maybe<FaqWhereInput>,
};

export type FaqScalarWhereInput = {
  id?: Maybe<StringFilter>,
  question?: Maybe<StringFilter>,
  answer?: Maybe<StringFilter>,
  category?: Maybe<NullableStringFilter>,
  published?: Maybe<BooleanFilter>,
  language?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  author?: Maybe<StringFilter>,
  largeLinkTitle?: Maybe<NullableStringFilter>,
  largeLink?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<FaqScalarWhereInput>>,
  OR?: Maybe<Array<FaqScalarWhereInput>>,
  NOT?: Maybe<Array<FaqScalarWhereInput>>,
};

export type FaqUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  question?: Maybe<Scalars['String']>,
  answer?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  language?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type FaqUpdateManyWithoutAuthorInput = {
  create?: Maybe<Array<FaqCreateWithoutAuthorInput>>,
  connect?: Maybe<Array<FaqWhereUniqueInput>>,
  set?: Maybe<Array<FaqWhereUniqueInput>>,
  disconnect?: Maybe<Array<FaqWhereUniqueInput>>,
  delete?: Maybe<Array<FaqWhereUniqueInput>>,
  update?: Maybe<Array<FaqUpdateWithWhereUniqueWithoutAuthorInput>>,
  updateMany?: Maybe<Array<FaqUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<FaqScalarWhereInput>>,
  upsert?: Maybe<Array<FaqUpsertWithWhereUniqueWithoutAuthorInput>>,
};

export type FaqUpdateManyWithoutCategoryInput = {
  create?: Maybe<Array<FaqCreateWithoutCategoryInput>>,
  connect?: Maybe<Array<FaqWhereUniqueInput>>,
  set?: Maybe<Array<FaqWhereUniqueInput>>,
  disconnect?: Maybe<Array<FaqWhereUniqueInput>>,
  delete?: Maybe<Array<FaqWhereUniqueInput>>,
  update?: Maybe<Array<FaqUpdateWithWhereUniqueWithoutCategoryInput>>,
  updateMany?: Maybe<Array<FaqUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<FaqScalarWhereInput>>,
  upsert?: Maybe<Array<FaqUpsertWithWhereUniqueWithoutCategoryInput>>,
};

export type FaqUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<FaqCreateWithoutProjectInput>>,
  connect?: Maybe<Array<FaqWhereUniqueInput>>,
  set?: Maybe<Array<FaqWhereUniqueInput>>,
  disconnect?: Maybe<Array<FaqWhereUniqueInput>>,
  delete?: Maybe<Array<FaqWhereUniqueInput>>,
  update?: Maybe<Array<FaqUpdateWithWhereUniqueWithoutProjectInput>>,
  updateMany?: Maybe<Array<FaqUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<FaqScalarWhereInput>>,
  upsert?: Maybe<Array<FaqUpsertWithWhereUniqueWithoutProjectInput>>,
};

export type FaqUpdateManyWithWhereNestedInput = {
  where: FaqScalarWhereInput,
  data: FaqUpdateManyDataInput,
};

export type FaqUpdateWithoutAuthorDataInput = {
  id?: Maybe<Scalars['String']>,
  question?: Maybe<Scalars['String']>,
  answer?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  language?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  Category?: Maybe<FaqCategoryUpdateOneWithoutFaqsInput>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutFaqsInput>,
};

export type FaqUpdateWithoutCategoryDataInput = {
  id?: Maybe<Scalars['String']>,
  question?: Maybe<Scalars['String']>,
  answer?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  language?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutFaqsInput>,
  Author?: Maybe<UserUpdateOneRequiredWithoutFaqsInput>,
};

export type FaqUpdateWithoutProjectDataInput = {
  id?: Maybe<Scalars['String']>,
  question?: Maybe<Scalars['String']>,
  answer?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  language?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  Category?: Maybe<FaqCategoryUpdateOneWithoutFaqsInput>,
  Author?: Maybe<UserUpdateOneRequiredWithoutFaqsInput>,
};

export type FaqUpdateWithWhereUniqueWithoutAuthorInput = {
  where: FaqWhereUniqueInput,
  data: FaqUpdateWithoutAuthorDataInput,
};

export type FaqUpdateWithWhereUniqueWithoutCategoryInput = {
  where: FaqWhereUniqueInput,
  data: FaqUpdateWithoutCategoryDataInput,
};

export type FaqUpdateWithWhereUniqueWithoutProjectInput = {
  where: FaqWhereUniqueInput,
  data: FaqUpdateWithoutProjectDataInput,
};

export type FaqUpsertWithWhereUniqueWithoutAuthorInput = {
  where: FaqWhereUniqueInput,
  update: FaqUpdateWithoutAuthorDataInput,
  create: FaqCreateWithoutAuthorInput,
};

export type FaqUpsertWithWhereUniqueWithoutCategoryInput = {
  where: FaqWhereUniqueInput,
  update: FaqUpdateWithoutCategoryDataInput,
  create: FaqCreateWithoutCategoryInput,
};

export type FaqUpsertWithWhereUniqueWithoutProjectInput = {
  where: FaqWhereUniqueInput,
  update: FaqUpdateWithoutProjectDataInput,
  create: FaqCreateWithoutProjectInput,
};

export type FaqWhereInput = {
  id?: Maybe<StringFilter>,
  question?: Maybe<StringFilter>,
  answer?: Maybe<StringFilter>,
  category?: Maybe<NullableStringFilter>,
  published?: Maybe<BooleanFilter>,
  language?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  author?: Maybe<StringFilter>,
  largeLinkTitle?: Maybe<NullableStringFilter>,
  largeLink?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<FaqWhereInput>>,
  OR?: Maybe<Array<FaqWhereInput>>,
  NOT?: Maybe<Array<FaqWhereInput>>,
  Category?: Maybe<FaqCategoryWhereInput>,
  Project?: Maybe<ProjectWhereInput>,
  Author?: Maybe<UserWhereInput>,
};

export type FaqWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type File = {
  id: Scalars['String'],
  name: Scalars['String'],
  project: Scalars['String'],
  Project: Project,
  url: Scalars['String'],
  type: Scalars['String'],
};

export type FileCreateManyWithoutProjectInput = {
  create?: Maybe<Array<FileCreateWithoutProjectInput>>,
  connect?: Maybe<Array<FileWhereUniqueInput>>,
};

export type FileCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  url: Scalars['String'],
  type: Scalars['String'],
};

export type FileFilter = {
  every?: Maybe<FileWhereInput>,
  some?: Maybe<FileWhereInput>,
  none?: Maybe<FileWhereInput>,
};

export type FileScalarWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  url?: Maybe<StringFilter>,
  type?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  AND?: Maybe<Array<FileScalarWhereInput>>,
  OR?: Maybe<Array<FileScalarWhereInput>>,
  NOT?: Maybe<Array<FileScalarWhereInput>>,
};

export type FileUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type FileUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<FileCreateWithoutProjectInput>>,
  connect?: Maybe<Array<FileWhereUniqueInput>>,
  set?: Maybe<Array<FileWhereUniqueInput>>,
  disconnect?: Maybe<Array<FileWhereUniqueInput>>,
  delete?: Maybe<Array<FileWhereUniqueInput>>,
  update?: Maybe<Array<FileUpdateWithWhereUniqueWithoutProjectInput>>,
  updateMany?: Maybe<Array<FileUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<FileScalarWhereInput>>,
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueWithoutProjectInput>>,
};

export type FileUpdateManyWithWhereNestedInput = {
  where: FileScalarWhereInput,
  data: FileUpdateManyDataInput,
};

export type FileUpdateWithoutProjectDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type FileUpdateWithWhereUniqueWithoutProjectInput = {
  where: FileWhereUniqueInput,
  data: FileUpdateWithoutProjectDataInput,
};

export type FileUpsertWithWhereUniqueWithoutProjectInput = {
  where: FileWhereUniqueInput,
  update: FileUpdateWithoutProjectDataInput,
  create: FileCreateWithoutProjectInput,
};

export type FileWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  url?: Maybe<StringFilter>,
  type?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  AND?: Maybe<Array<FileWhereInput>>,
  OR?: Maybe<Array<FileWhereInput>>,
  NOT?: Maybe<Array<FileWhereInput>>,
  Project?: Maybe<ProjectWhereInput>,
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>,
  not?: Maybe<Scalars['Int']>,
  in?: Maybe<Array<Scalars['Int']>>,
  notIn?: Maybe<Array<Scalars['Int']>>,
  lt?: Maybe<Scalars['Int']>,
  lte?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  gte?: Maybe<Scalars['Int']>,
};

export type Mutation = {
  uploadFile: File,
  createOneRestriction: ClientRestriction,
  updateOneRestriction: ClientRestriction,
  deleteOneRestriction?: Maybe<ClientRestriction>,
  createOneFaqCategory: FaqCategory,
  deleteOneFaqCategory?: Maybe<FaqCategory>,
  updateOneFaqCategory?: Maybe<FaqCategory>,
  createOneFaq: Faq,
  updateOneFaq: Faq,
  deleteOneFaq?: Maybe<Faq>,
  createOneCompany: Company,
  deleteOneCompany?: Maybe<Company>,
  updateOneCompany?: Maybe<Company>,
  createOnePost: Post,
  duplicatePost?: Maybe<Post>,
  updateOnePost?: Maybe<Post>,
  deleteOnePost?: Maybe<Post>,
  createOnePostCategory: PostCategory,
  deleteOnePostCategory?: Maybe<PostCategory>,
  updateOnePostCategory?: Maybe<PostCategory>,
  createOneProject: Project,
  updateOneProject: Project,
  deleteOneProject?: Maybe<Project>,
  createOneUser: User,
  confirmUser: User,
  updateUserName: User,
  updateUserProfile: User,
  connectUser: User,
  disconnectUser: User,
  makeQAdmin: Scalars['Boolean'],
  createOneSuggestionItem: SuggestionItem,
  updateOneSuggestionItem: SuggestionItem,
  deleteOneSuggestionItem: SuggestionItem,
  createOneSuggestion: Suggestion,
  updateOneSuggestion?: Maybe<Suggestion>,
  deleteOneSuggestion: Suggestion,
};


export type MutationUploadFileArgs = {
  projectId?: Maybe<Scalars['String']>,
  file: Scalars['Upload']
};


export type MutationCreateOneRestrictionArgs = {
  text: Scalars['String'],
  slug: Scalars['String'],
  title: Scalars['String'],
  language: Scalars['String'],
  projectId: Scalars['String'],
  logoImg: Scalars['Upload']
};


export type MutationUpdateOneRestrictionArgs = {
  id: Scalars['String'],
  language: Scalars['String'],
  text?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  logoImg?: Maybe<Scalars['Upload']>
};


export type MutationDeleteOneRestrictionArgs = {
  where: RestrictionWhereUniqueInput
};


export type MutationCreateOneFaqCategoryArgs = {
  data: FaqCategoryCreateInput
};


export type MutationDeleteOneFaqCategoryArgs = {
  where: FaqCategoryWhereUniqueInput
};


export type MutationUpdateOneFaqCategoryArgs = {
  data: FaqCategoryUpdateInput,
  where: FaqCategoryWhereUniqueInput
};


export type MutationCreateOneFaqArgs = {
  published: Scalars['Boolean'],
  question: Scalars['String'],
  language: Scalars['String'],
  answer: Scalars['String'],
  projectId: Scalars['String'],
  largeLinkTitle?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>
};


export type MutationUpdateOneFaqArgs = {
  id?: Maybe<Scalars['String']>,
  published: Scalars['Boolean'],
  question: Scalars['String'],
  answer: Scalars['String'],
  largeLink?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>,
  language: Scalars['String'],
  category?: Maybe<Scalars['String']>
};


export type MutationDeleteOneFaqArgs = {
  where: FaqWhereUniqueInput
};


export type MutationCreateOneCompanyArgs = {
  data: CompanyCreateInput
};


export type MutationDeleteOneCompanyArgs = {
  where: CompanyWhereUniqueInput
};


export type MutationUpdateOneCompanyArgs = {
  data: CompanyUpdateInput,
  where: CompanyWhereUniqueInput
};


export type MutationCreateOnePostArgs = {
  title: Scalars['String'],
  slug: Scalars['String'],
  text: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  published: Scalars['Boolean'],
  language: Scalars['String'],
  categories: Array<Scalars['String']>,
  projectId: Scalars['String'],
  createdAt: Scalars['DateTime'],
  mainImg: Scalars['Upload'],
  author: Scalars['String'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>
};


export type MutationDuplicatePostArgs = {
  id: Scalars['String']
};


export type MutationUpdateOnePostArgs = {
  id: Scalars['String'],
  title: Scalars['String'],
  slug: Scalars['String'],
  text: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  published: Scalars['Boolean'],
  language: Scalars['String'],
  categories: Array<Scalars['String']>,
  projectId: Scalars['String'],
  createdAt: Scalars['String'],
  mainImg: Scalars['String'],
  updatedImg?: Maybe<Scalars['Upload']>,
  author: Scalars['String'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  isDraft: Scalars['Boolean'],
  draftedPostId: Scalars['String'],
  publishedPostId: Scalars['String']
};


export type MutationDeleteOnePostArgs = {
  where: PostWhereUniqueInput
};


export type MutationCreateOnePostCategoryArgs = {
  data: PostCategoryCreateInput
};


export type MutationDeleteOnePostCategoryArgs = {
  where: PostCategoryWhereUniqueInput
};


export type MutationUpdateOnePostCategoryArgs = {
  data: PostCategoryUpdateInput,
  where: PostCategoryWhereUniqueInput
};


export type MutationCreateOneProjectArgs = {
  name: Scalars['String'],
  companyId: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo: Scalars['Upload'],
  logoSmall: Scalars['Upload'],
  email: Scalars['String'],
  blog: Scalars['Boolean'],
  suggestion: Scalars['Boolean'],
  faq: Scalars['Boolean'],
  restriction: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String']
};


export type MutationUpdateOneProjectArgs = {
  name: Scalars['String'],
  id: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['Upload']>,
  logoSmall?: Maybe<Scalars['Upload']>,
  blog: Scalars['Boolean'],
  faq: Scalars['Boolean'],
  restriction: Scalars['Boolean'],
  suggestion: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String']
};


export type MutationDeleteOneProjectArgs = {
  where?: Maybe<ProjectWhereUniqueInput>
};


export type MutationCreateOneUserArgs = {
  projectId: Scalars['String'],
  email: Scalars['String']
};


export type MutationConfirmUserArgs = {
  authId: Scalars['String'],
  email: Scalars['String'],
  name?: Maybe<Scalars['String']>
};


export type MutationUpdateUserNameArgs = {
  id: Scalars['String'],
  name: Scalars['String']
};


export type MutationUpdateUserProfileArgs = {
  name: Scalars['String'],
  file?: Maybe<Scalars['Upload']>
};


export type MutationConnectUserArgs = {
  projectId: Scalars['String'],
  userId: Scalars['String']
};


export type MutationDisconnectUserArgs = {
  projectId: Scalars['String'],
  userId: Scalars['String']
};


export type MutationMakeQAdminArgs = {
  email: Scalars['String']
};


export type MutationCreateOneSuggestionItemArgs = {
  index: Scalars['Int'],
  text: Scalars['String'],
  title: Scalars['String'],
  img: Scalars['Upload'],
  suggestionId: Scalars['String']
};


export type MutationUpdateOneSuggestionItemArgs = {
  index: Scalars['Int'],
  id: Scalars['String'],
  img: Scalars['String'],
  text: Scalars['String'],
  title: Scalars['String'],
  updatedImg?: Maybe<Scalars['Upload']>
};


export type MutationDeleteOneSuggestionItemArgs = {
  id: Scalars['String']
};


export type MutationCreateOneSuggestionArgs = {
  title: Scalars['String'],
  projectId: Scalars['String'],
  img: Scalars['Upload'],
  from?: Maybe<Scalars['DateTime']>,
  until?: Maybe<Scalars['DateTime']>,
  unlimited: Scalars['Boolean'],
  published: Scalars['Boolean'],
  items?: Maybe<Array<SuggestionItemInputCreate>>
};


export type MutationUpdateOneSuggestionArgs = {
  id: Scalars['String'],
  title: Scalars['String'],
  img: Scalars['String'],
  published: Scalars['Boolean'],
  unlimited: Scalars['Boolean'],
  updatedImg?: Maybe<Scalars['Upload']>,
  from?: Maybe<Scalars['DateTime']>,
  until?: Maybe<Scalars['DateTime']>,
  items?: Maybe<Array<SuggestionItemInputUpdate>>
};


export type MutationDeleteOneSuggestionArgs = {
  where: SuggestionWhereUniqueInput
};

export type NullableDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>,
  not?: Maybe<Scalars['DateTime']>,
  in?: Maybe<Array<Scalars['DateTime']>>,
  notIn?: Maybe<Array<Scalars['DateTime']>>,
  lt?: Maybe<Scalars['DateTime']>,
  lte?: Maybe<Scalars['DateTime']>,
  gt?: Maybe<Scalars['DateTime']>,
  gte?: Maybe<Scalars['DateTime']>,
};

export type NullableStringFilter = {
  equals?: Maybe<Scalars['String']>,
  not?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  notIn?: Maybe<Array<Scalars['String']>>,
  lt?: Maybe<Scalars['String']>,
  lte?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  gte?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
};

export type Post = {
  id: Scalars['String'],
  DraftedVersion: PostVersion,
  PublishedVersion: PostVersion,
};

export type PostCategory = {
  id: Scalars['String'],
  name: Scalars['String'],
  Project: Project,
};

export type PostCategoryCreateInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  Project: ProjectCreateOneWithoutPostCategoriesInput,
  posts?: Maybe<PostVersionCreateManyWithoutCategoriesInput>,
};

export type PostCategoryCreateManyWithoutPostsInput = {
  create?: Maybe<Array<PostCategoryCreateWithoutPostsInput>>,
  connect?: Maybe<Array<PostCategoryWhereUniqueInput>>,
};

export type PostCategoryCreateManyWithoutProjectInput = {
  create?: Maybe<Array<PostCategoryCreateWithoutProjectInput>>,
  connect?: Maybe<Array<PostCategoryWhereUniqueInput>>,
};

export type PostCategoryCreateWithoutPostsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  Project: ProjectCreateOneWithoutPostCategoriesInput,
};

export type PostCategoryCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  posts?: Maybe<PostVersionCreateManyWithoutCategoriesInput>,
};

export type PostCategoryFilter = {
  every?: Maybe<PostCategoryWhereInput>,
  some?: Maybe<PostCategoryWhereInput>,
  none?: Maybe<PostCategoryWhereInput>,
};

export type PostCategoryScalarWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  posts?: Maybe<PostVersionFilter>,
  AND?: Maybe<Array<PostCategoryScalarWhereInput>>,
  OR?: Maybe<Array<PostCategoryScalarWhereInput>>,
  NOT?: Maybe<Array<PostCategoryScalarWhereInput>>,
};

export type PostCategoryUpdateInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutPostCategoriesInput>,
  posts?: Maybe<PostVersionUpdateManyWithoutCategoriesInput>,
};

export type PostCategoryUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type PostCategoryUpdateManyWithoutPostsInput = {
  create?: Maybe<Array<PostCategoryCreateWithoutPostsInput>>,
  connect?: Maybe<Array<PostCategoryWhereUniqueInput>>,
  set?: Maybe<Array<PostCategoryWhereUniqueInput>>,
  disconnect?: Maybe<Array<PostCategoryWhereUniqueInput>>,
  delete?: Maybe<Array<PostCategoryWhereUniqueInput>>,
  update?: Maybe<Array<PostCategoryUpdateWithWhereUniqueWithoutPostsInput>>,
  updateMany?: Maybe<Array<PostCategoryUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PostCategoryScalarWhereInput>>,
  upsert?: Maybe<Array<PostCategoryUpsertWithWhereUniqueWithoutPostsInput>>,
};

export type PostCategoryUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<PostCategoryCreateWithoutProjectInput>>,
  connect?: Maybe<Array<PostCategoryWhereUniqueInput>>,
  set?: Maybe<Array<PostCategoryWhereUniqueInput>>,
  disconnect?: Maybe<Array<PostCategoryWhereUniqueInput>>,
  delete?: Maybe<Array<PostCategoryWhereUniqueInput>>,
  update?: Maybe<Array<PostCategoryUpdateWithWhereUniqueWithoutProjectInput>>,
  updateMany?: Maybe<Array<PostCategoryUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PostCategoryScalarWhereInput>>,
  upsert?: Maybe<Array<PostCategoryUpsertWithWhereUniqueWithoutProjectInput>>,
};

export type PostCategoryUpdateManyWithWhereNestedInput = {
  where: PostCategoryScalarWhereInput,
  data: PostCategoryUpdateManyDataInput,
};

export type PostCategoryUpdateWithoutPostsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutPostCategoriesInput>,
};

export type PostCategoryUpdateWithoutProjectDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  posts?: Maybe<PostVersionUpdateManyWithoutCategoriesInput>,
};

export type PostCategoryUpdateWithWhereUniqueWithoutPostsInput = {
  where: PostCategoryWhereUniqueInput,
  data: PostCategoryUpdateWithoutPostsDataInput,
};

export type PostCategoryUpdateWithWhereUniqueWithoutProjectInput = {
  where: PostCategoryWhereUniqueInput,
  data: PostCategoryUpdateWithoutProjectDataInput,
};

export type PostCategoryUpsertWithWhereUniqueWithoutPostsInput = {
  where: PostCategoryWhereUniqueInput,
  update: PostCategoryUpdateWithoutPostsDataInput,
  create: PostCategoryCreateWithoutPostsInput,
};

export type PostCategoryUpsertWithWhereUniqueWithoutProjectInput = {
  where: PostCategoryWhereUniqueInput,
  update: PostCategoryUpdateWithoutProjectDataInput,
  create: PostCategoryCreateWithoutProjectInput,
};

export type PostCategoryWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  posts?: Maybe<PostVersionFilter>,
  AND?: Maybe<Array<PostCategoryWhereInput>>,
  OR?: Maybe<Array<PostCategoryWhereInput>>,
  NOT?: Maybe<Array<PostCategoryWhereInput>>,
  Project?: Maybe<ProjectWhereInput>,
};

export type PostCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type PostCreateOneWithoutDraftedVersionInput = {
  create?: Maybe<PostCreateWithoutDraftedVersionInput>,
  connect?: Maybe<PostWhereUniqueInput>,
};

export type PostCreateOneWithoutPublishedVersionInput = {
  create?: Maybe<PostCreateWithoutPublishedVersionInput>,
  connect?: Maybe<PostWhereUniqueInput>,
};

export type PostCreateWithoutDraftedVersionInput = {
  id?: Maybe<Scalars['String']>,
  PublishedVersion: PostVersionCreateOneWithoutPublishedPostInput,
};

export type PostCreateWithoutPublishedVersionInput = {
  id?: Maybe<Scalars['String']>,
  DraftedVersion: PostVersionCreateOneWithoutDraftedPostInput,
};

export type PostUpdateOneWithoutDraftedVersionInput = {
  create?: Maybe<PostCreateWithoutDraftedVersionInput>,
  connect?: Maybe<PostWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<PostUpdateWithoutDraftedVersionDataInput>,
  upsert?: Maybe<PostUpsertWithoutDraftedVersionInput>,
};

export type PostUpdateOneWithoutPublishedVersionInput = {
  create?: Maybe<PostCreateWithoutPublishedVersionInput>,
  connect?: Maybe<PostWhereUniqueInput>,
  disconnect?: Maybe<Scalars['Boolean']>,
  delete?: Maybe<Scalars['Boolean']>,
  update?: Maybe<PostUpdateWithoutPublishedVersionDataInput>,
  upsert?: Maybe<PostUpsertWithoutPublishedVersionInput>,
};

export type PostUpdateWithoutDraftedVersionDataInput = {
  id?: Maybe<Scalars['String']>,
  PublishedVersion?: Maybe<PostVersionUpdateOneRequiredWithoutPublishedPostInput>,
};

export type PostUpdateWithoutPublishedVersionDataInput = {
  id?: Maybe<Scalars['String']>,
  DraftedVersion?: Maybe<PostVersionUpdateOneRequiredWithoutDraftedPostInput>,
};

export type PostUpsertWithoutDraftedVersionInput = {
  update: PostUpdateWithoutDraftedVersionDataInput,
  create: PostCreateWithoutDraftedVersionInput,
};

export type PostUpsertWithoutPublishedVersionInput = {
  update: PostUpdateWithoutPublishedVersionDataInput,
  create: PostCreateWithoutPublishedVersionInput,
};

export type PostVersion = {
  id: Scalars['String'],
  draftedPost?: Maybe<Post>,
  publishedPost?: Maybe<Post>,
  published: Scalars['Boolean'],
  title: Scalars['String'],
  slug: Scalars['String'],
  text: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  language: Scalars['String'],
  mainImg: Scalars['String'],
  categories: Array<PostCategory>,
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  Project: Project,
  Author: User,
};


export type PostVersionCategoriesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<PostCategoryWhereUniqueInput>,
  after?: Maybe<PostCategoryWhereUniqueInput>
};

export type PostVersionCreateManyWithoutAuthorInput = {
  create?: Maybe<Array<PostVersionCreateWithoutAuthorInput>>,
  connect?: Maybe<Array<PostVersionWhereUniqueInput>>,
};

export type PostVersionCreateManyWithoutCategoriesInput = {
  create?: Maybe<Array<PostVersionCreateWithoutCategoriesInput>>,
  connect?: Maybe<Array<PostVersionWhereUniqueInput>>,
};

export type PostVersionCreateManyWithoutProjectInput = {
  create?: Maybe<Array<PostVersionCreateWithoutProjectInput>>,
  connect?: Maybe<Array<PostVersionWhereUniqueInput>>,
};

export type PostVersionCreateOneWithoutDraftedPostInput = {
  create?: Maybe<PostVersionCreateWithoutDraftedPostInput>,
  connect?: Maybe<PostVersionWhereUniqueInput>,
};

export type PostVersionCreateOneWithoutPublishedPostInput = {
  create?: Maybe<PostVersionCreateWithoutPublishedPostInput>,
  connect?: Maybe<PostVersionWhereUniqueInput>,
};

export type PostVersionCreateWithoutAuthorInput = {
  id?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  slug: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  language: Scalars['String'],
  mainImg: Scalars['String'],
  published: Scalars['Boolean'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  draftedPost?: Maybe<PostCreateOneWithoutDraftedVersionInput>,
  publishedPost?: Maybe<PostCreateOneWithoutPublishedVersionInput>,
  categories?: Maybe<PostCategoryCreateManyWithoutPostsInput>,
  Project: ProjectCreateOneWithoutPostsInput,
};

export type PostVersionCreateWithoutCategoriesInput = {
  id?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  slug: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  language: Scalars['String'],
  mainImg: Scalars['String'],
  published: Scalars['Boolean'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  draftedPost?: Maybe<PostCreateOneWithoutDraftedVersionInput>,
  publishedPost?: Maybe<PostCreateOneWithoutPublishedVersionInput>,
  Author: UserCreateOneWithoutPostVersionsInput,
  Project: ProjectCreateOneWithoutPostsInput,
};

export type PostVersionCreateWithoutDraftedPostInput = {
  id?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  slug: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  language: Scalars['String'],
  mainImg: Scalars['String'],
  published: Scalars['Boolean'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  publishedPost?: Maybe<PostCreateOneWithoutPublishedVersionInput>,
  categories?: Maybe<PostCategoryCreateManyWithoutPostsInput>,
  Author: UserCreateOneWithoutPostVersionsInput,
  Project: ProjectCreateOneWithoutPostsInput,
};

export type PostVersionCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  slug: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  language: Scalars['String'],
  mainImg: Scalars['String'],
  published: Scalars['Boolean'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  draftedPost?: Maybe<PostCreateOneWithoutDraftedVersionInput>,
  publishedPost?: Maybe<PostCreateOneWithoutPublishedVersionInput>,
  categories?: Maybe<PostCategoryCreateManyWithoutPostsInput>,
  Author: UserCreateOneWithoutPostVersionsInput,
};

export type PostVersionCreateWithoutPublishedPostInput = {
  id?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  slug: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  text: Scalars['String'],
  language: Scalars['String'],
  mainImg: Scalars['String'],
  published: Scalars['Boolean'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  draftedPost?: Maybe<PostCreateOneWithoutDraftedVersionInput>,
  categories?: Maybe<PostCategoryCreateManyWithoutPostsInput>,
  Author: UserCreateOneWithoutPostVersionsInput,
  Project: ProjectCreateOneWithoutPostsInput,
};

export type PostVersionFilter = {
  every?: Maybe<PostVersionWhereInput>,
  some?: Maybe<PostVersionWhereInput>,
  none?: Maybe<PostVersionWhereInput>,
};

export type PostVersionScalarWhereInput = {
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  slug?: Maybe<StringFilter>,
  excerpt?: Maybe<NullableStringFilter>,
  text?: Maybe<StringFilter>,
  language?: Maybe<StringFilter>,
  categories?: Maybe<PostCategoryFilter>,
  mainImg?: Maybe<StringFilter>,
  author?: Maybe<StringFilter>,
  published?: Maybe<BooleanFilter>,
  project?: Maybe<StringFilter>,
  ogTitle?: Maybe<NullableStringFilter>,
  ogDescription?: Maybe<NullableStringFilter>,
  tags?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  readingTime?: Maybe<IntFilter>,
  AND?: Maybe<Array<PostVersionScalarWhereInput>>,
  OR?: Maybe<Array<PostVersionScalarWhereInput>>,
  NOT?: Maybe<Array<PostVersionScalarWhereInput>>,
};

export type PostVersionUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  excerpt?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  mainImg?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
};

export type PostVersionUpdateManyWithoutAuthorInput = {
  create?: Maybe<Array<PostVersionCreateWithoutAuthorInput>>,
  connect?: Maybe<Array<PostVersionWhereUniqueInput>>,
  set?: Maybe<Array<PostVersionWhereUniqueInput>>,
  disconnect?: Maybe<Array<PostVersionWhereUniqueInput>>,
  delete?: Maybe<Array<PostVersionWhereUniqueInput>>,
  update?: Maybe<Array<PostVersionUpdateWithWhereUniqueWithoutAuthorInput>>,
  updateMany?: Maybe<Array<PostVersionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PostVersionScalarWhereInput>>,
  upsert?: Maybe<Array<PostVersionUpsertWithWhereUniqueWithoutAuthorInput>>,
};

export type PostVersionUpdateManyWithoutCategoriesInput = {
  create?: Maybe<Array<PostVersionCreateWithoutCategoriesInput>>,
  connect?: Maybe<Array<PostVersionWhereUniqueInput>>,
  set?: Maybe<Array<PostVersionWhereUniqueInput>>,
  disconnect?: Maybe<Array<PostVersionWhereUniqueInput>>,
  delete?: Maybe<Array<PostVersionWhereUniqueInput>>,
  update?: Maybe<Array<PostVersionUpdateWithWhereUniqueWithoutCategoriesInput>>,
  updateMany?: Maybe<Array<PostVersionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PostVersionScalarWhereInput>>,
  upsert?: Maybe<Array<PostVersionUpsertWithWhereUniqueWithoutCategoriesInput>>,
};

export type PostVersionUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<PostVersionCreateWithoutProjectInput>>,
  connect?: Maybe<Array<PostVersionWhereUniqueInput>>,
  set?: Maybe<Array<PostVersionWhereUniqueInput>>,
  disconnect?: Maybe<Array<PostVersionWhereUniqueInput>>,
  delete?: Maybe<Array<PostVersionWhereUniqueInput>>,
  update?: Maybe<Array<PostVersionUpdateWithWhereUniqueWithoutProjectInput>>,
  updateMany?: Maybe<Array<PostVersionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<PostVersionScalarWhereInput>>,
  upsert?: Maybe<Array<PostVersionUpsertWithWhereUniqueWithoutProjectInput>>,
};

export type PostVersionUpdateManyWithWhereNestedInput = {
  where: PostVersionScalarWhereInput,
  data: PostVersionUpdateManyDataInput,
};

export type PostVersionUpdateOneRequiredWithoutDraftedPostInput = {
  create?: Maybe<PostVersionCreateWithoutDraftedPostInput>,
  connect?: Maybe<PostVersionWhereUniqueInput>,
  update?: Maybe<PostVersionUpdateWithoutDraftedPostDataInput>,
  upsert?: Maybe<PostVersionUpsertWithoutDraftedPostInput>,
};

export type PostVersionUpdateOneRequiredWithoutPublishedPostInput = {
  create?: Maybe<PostVersionCreateWithoutPublishedPostInput>,
  connect?: Maybe<PostVersionWhereUniqueInput>,
  update?: Maybe<PostVersionUpdateWithoutPublishedPostDataInput>,
  upsert?: Maybe<PostVersionUpsertWithoutPublishedPostInput>,
};

export type PostVersionUpdateWithoutAuthorDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  excerpt?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  mainImg?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  draftedPost?: Maybe<PostUpdateOneWithoutDraftedVersionInput>,
  publishedPost?: Maybe<PostUpdateOneWithoutPublishedVersionInput>,
  categories?: Maybe<PostCategoryUpdateManyWithoutPostsInput>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutPostsInput>,
};

export type PostVersionUpdateWithoutCategoriesDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  excerpt?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  mainImg?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  draftedPost?: Maybe<PostUpdateOneWithoutDraftedVersionInput>,
  publishedPost?: Maybe<PostUpdateOneWithoutPublishedVersionInput>,
  Author?: Maybe<UserUpdateOneRequiredWithoutPostVersionsInput>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutPostsInput>,
};

export type PostVersionUpdateWithoutDraftedPostDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  excerpt?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  mainImg?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  publishedPost?: Maybe<PostUpdateOneWithoutPublishedVersionInput>,
  categories?: Maybe<PostCategoryUpdateManyWithoutPostsInput>,
  Author?: Maybe<UserUpdateOneRequiredWithoutPostVersionsInput>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutPostsInput>,
};

export type PostVersionUpdateWithoutProjectDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  excerpt?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  mainImg?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  draftedPost?: Maybe<PostUpdateOneWithoutDraftedVersionInput>,
  publishedPost?: Maybe<PostUpdateOneWithoutPublishedVersionInput>,
  categories?: Maybe<PostCategoryUpdateManyWithoutPostsInput>,
  Author?: Maybe<UserUpdateOneRequiredWithoutPostVersionsInput>,
};

export type PostVersionUpdateWithoutPublishedPostDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  excerpt?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  mainImg?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  readingTime?: Maybe<Scalars['Int']>,
  draftedPost?: Maybe<PostUpdateOneWithoutDraftedVersionInput>,
  categories?: Maybe<PostCategoryUpdateManyWithoutPostsInput>,
  Author?: Maybe<UserUpdateOneRequiredWithoutPostVersionsInput>,
  Project?: Maybe<ProjectUpdateOneRequiredWithoutPostsInput>,
};

export type PostVersionUpdateWithWhereUniqueWithoutAuthorInput = {
  where: PostVersionWhereUniqueInput,
  data: PostVersionUpdateWithoutAuthorDataInput,
};

export type PostVersionUpdateWithWhereUniqueWithoutCategoriesInput = {
  where: PostVersionWhereUniqueInput,
  data: PostVersionUpdateWithoutCategoriesDataInput,
};

export type PostVersionUpdateWithWhereUniqueWithoutProjectInput = {
  where: PostVersionWhereUniqueInput,
  data: PostVersionUpdateWithoutProjectDataInput,
};

export type PostVersionUpsertWithoutDraftedPostInput = {
  update: PostVersionUpdateWithoutDraftedPostDataInput,
  create: PostVersionCreateWithoutDraftedPostInput,
};

export type PostVersionUpsertWithoutPublishedPostInput = {
  update: PostVersionUpdateWithoutPublishedPostDataInput,
  create: PostVersionCreateWithoutPublishedPostInput,
};

export type PostVersionUpsertWithWhereUniqueWithoutAuthorInput = {
  where: PostVersionWhereUniqueInput,
  update: PostVersionUpdateWithoutAuthorDataInput,
  create: PostVersionCreateWithoutAuthorInput,
};

export type PostVersionUpsertWithWhereUniqueWithoutCategoriesInput = {
  where: PostVersionWhereUniqueInput,
  update: PostVersionUpdateWithoutCategoriesDataInput,
  create: PostVersionCreateWithoutCategoriesInput,
};

export type PostVersionUpsertWithWhereUniqueWithoutProjectInput = {
  where: PostVersionWhereUniqueInput,
  update: PostVersionUpdateWithoutProjectDataInput,
  create: PostVersionCreateWithoutProjectInput,
};

export type PostVersionWhereInput = {
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  slug?: Maybe<StringFilter>,
  excerpt?: Maybe<NullableStringFilter>,
  text?: Maybe<StringFilter>,
  language?: Maybe<StringFilter>,
  categories?: Maybe<PostCategoryFilter>,
  mainImg?: Maybe<StringFilter>,
  author?: Maybe<StringFilter>,
  published?: Maybe<BooleanFilter>,
  project?: Maybe<StringFilter>,
  ogTitle?: Maybe<NullableStringFilter>,
  ogDescription?: Maybe<NullableStringFilter>,
  tags?: Maybe<NullableStringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  readingTime?: Maybe<IntFilter>,
  AND?: Maybe<Array<PostVersionWhereInput>>,
  OR?: Maybe<Array<PostVersionWhereInput>>,
  NOT?: Maybe<Array<PostVersionWhereInput>>,
  draftedPost?: Maybe<PostWhereInput>,
  publishedPost?: Maybe<PostWhereInput>,
  Author?: Maybe<UserWhereInput>,
  Project?: Maybe<ProjectWhereInput>,
};

export type PostVersionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type PostWhereInput = {
  id?: Maybe<StringFilter>,
  draftedVersion?: Maybe<StringFilter>,
  publishedVersion?: Maybe<StringFilter>,
  AND?: Maybe<Array<PostWhereInput>>,
  OR?: Maybe<Array<PostWhereInput>>,
  NOT?: Maybe<Array<PostWhereInput>>,
  DraftedVersion?: Maybe<PostVersionWhereInput>,
  PublishedVersion?: Maybe<PostVersionWhereInput>,
};

export type PostWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Project = {
  id: Scalars['String'],
  name: Scalars['String'],
  admins: Array<User>,
  logo: Scalars['String'],
  logoSmall: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  faqFeature: Scalars['Boolean'],
  restrictionFeature: Scalars['Boolean'],
  suggestionFeature: Scalars['Boolean'],
  blogFeature: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String'],
  Company: Company,
};


export type ProjectAdminsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<UserWhereUniqueInput>,
  after?: Maybe<UserWhereUniqueInput>
};

export type ProjectCreateManyWithoutAdminsInput = {
  create?: Maybe<Array<ProjectCreateWithoutAdminsInput>>,
  connect?: Maybe<Array<ProjectWhereUniqueInput>>,
};

export type ProjectCreateManyWithoutCompanyInput = {
  create?: Maybe<Array<ProjectCreateWithoutCompanyInput>>,
  connect?: Maybe<Array<ProjectWhereUniqueInput>>,
};

export type ProjectCreateManyWithoutUsersInput = {
  create?: Maybe<Array<ProjectCreateWithoutUsersInput>>,
  connect?: Maybe<Array<ProjectWhereUniqueInput>>,
};

export type ProjectCreateOneWithoutFaqCategoriesInput = {
  create?: Maybe<ProjectCreateWithoutFaqCategoriesInput>,
  connect?: Maybe<ProjectWhereUniqueInput>,
};

export type ProjectCreateOneWithoutFaqsInput = {
  create?: Maybe<ProjectCreateWithoutFaqsInput>,
  connect?: Maybe<ProjectWhereUniqueInput>,
};

export type ProjectCreateOneWithoutPostCategoriesInput = {
  create?: Maybe<ProjectCreateWithoutPostCategoriesInput>,
  connect?: Maybe<ProjectWhereUniqueInput>,
};

export type ProjectCreateOneWithoutPostsInput = {
  create?: Maybe<ProjectCreateWithoutPostsInput>,
  connect?: Maybe<ProjectWhereUniqueInput>,
};

export type ProjectCreateWithoutAdminsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo: Scalars['String'],
  logoSmall: Scalars['String'],
  faqFeature: Scalars['Boolean'],
  restrictionFeature: Scalars['Boolean'],
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String'],
  Company: CompanyCreateOneWithoutProjectsInput,
  postCategories?: Maybe<PostCategoryCreateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryCreateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionCreateManyWithoutProjectInput>,
  faqs?: Maybe<FaqCreateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionCreateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionCreateManyWithoutProjectInput>,
  users?: Maybe<UserCreateManyWithoutProjectsInput>,
  files?: Maybe<FileCreateManyWithoutProjectInput>,
};

export type ProjectCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo: Scalars['String'],
  logoSmall: Scalars['String'],
  faqFeature: Scalars['Boolean'],
  restrictionFeature: Scalars['Boolean'],
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String'],
  postCategories?: Maybe<PostCategoryCreateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryCreateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionCreateManyWithoutProjectInput>,
  faqs?: Maybe<FaqCreateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionCreateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionCreateManyWithoutProjectInput>,
  users?: Maybe<UserCreateManyWithoutProjectsInput>,
  admins?: Maybe<UserCreateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileCreateManyWithoutProjectInput>,
};

export type ProjectCreateWithoutFaqCategoriesInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo: Scalars['String'],
  logoSmall: Scalars['String'],
  faqFeature: Scalars['Boolean'],
  restrictionFeature: Scalars['Boolean'],
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String'],
  Company: CompanyCreateOneWithoutProjectsInput,
  postCategories?: Maybe<PostCategoryCreateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionCreateManyWithoutProjectInput>,
  faqs?: Maybe<FaqCreateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionCreateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionCreateManyWithoutProjectInput>,
  users?: Maybe<UserCreateManyWithoutProjectsInput>,
  admins?: Maybe<UserCreateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileCreateManyWithoutProjectInput>,
};

export type ProjectCreateWithoutFaqsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo: Scalars['String'],
  logoSmall: Scalars['String'],
  faqFeature: Scalars['Boolean'],
  restrictionFeature: Scalars['Boolean'],
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String'],
  Company: CompanyCreateOneWithoutProjectsInput,
  postCategories?: Maybe<PostCategoryCreateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryCreateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionCreateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionCreateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionCreateManyWithoutProjectInput>,
  users?: Maybe<UserCreateManyWithoutProjectsInput>,
  admins?: Maybe<UserCreateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileCreateManyWithoutProjectInput>,
};

export type ProjectCreateWithoutPostCategoriesInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo: Scalars['String'],
  logoSmall: Scalars['String'],
  faqFeature: Scalars['Boolean'],
  restrictionFeature: Scalars['Boolean'],
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String'],
  Company: CompanyCreateOneWithoutProjectsInput,
  faqCategories?: Maybe<FaqCategoryCreateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionCreateManyWithoutProjectInput>,
  faqs?: Maybe<FaqCreateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionCreateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionCreateManyWithoutProjectInput>,
  users?: Maybe<UserCreateManyWithoutProjectsInput>,
  admins?: Maybe<UserCreateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileCreateManyWithoutProjectInput>,
};

export type ProjectCreateWithoutPostsInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo: Scalars['String'],
  logoSmall: Scalars['String'],
  faqFeature: Scalars['Boolean'],
  restrictionFeature: Scalars['Boolean'],
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String'],
  Company: CompanyCreateOneWithoutProjectsInput,
  postCategories?: Maybe<PostCategoryCreateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryCreateManyWithoutProjectInput>,
  faqs?: Maybe<FaqCreateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionCreateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionCreateManyWithoutProjectInput>,
  users?: Maybe<UserCreateManyWithoutProjectsInput>,
  admins?: Maybe<UserCreateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileCreateManyWithoutProjectInput>,
};

export type ProjectCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo: Scalars['String'],
  logoSmall: Scalars['String'],
  faqFeature: Scalars['Boolean'],
  restrictionFeature: Scalars['Boolean'],
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String'],
  Company: CompanyCreateOneWithoutProjectsInput,
  postCategories?: Maybe<PostCategoryCreateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryCreateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionCreateManyWithoutProjectInput>,
  faqs?: Maybe<FaqCreateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionCreateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionCreateManyWithoutProjectInput>,
  admins?: Maybe<UserCreateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileCreateManyWithoutProjectInput>,
};

export type ProjectFilter = {
  every?: Maybe<ProjectWhereInput>,
  some?: Maybe<ProjectWhereInput>,
  none?: Maybe<ProjectWhereInput>,
};

export type ProjectScalarWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  company?: Maybe<StringFilter>,
  webUrl?: Maybe<NullableStringFilter>,
  logo?: Maybe<StringFilter>,
  logoSmall?: Maybe<StringFilter>,
  postCategories?: Maybe<PostCategoryFilter>,
  faqCategories?: Maybe<FaqCategoryFilter>,
  posts?: Maybe<PostVersionFilter>,
  faqs?: Maybe<FaqFilter>,
  restrictions?: Maybe<RestrictionFilter>,
  suggestions?: Maybe<SuggestionFilter>,
  users?: Maybe<UserFilter>,
  admins?: Maybe<UserFilter>,
  files?: Maybe<FileFilter>,
  faqFeature?: Maybe<BooleanFilter>,
  restrictionFeature?: Maybe<BooleanFilter>,
  suggestionFeature?: Maybe<BooleanFilter>,
  blogFeature?: Maybe<BooleanFilter>,
  languages?: Maybe<StringFilter>,
  editorConfig?: Maybe<StringFilter>,
  AND?: Maybe<Array<ProjectScalarWhereInput>>,
  OR?: Maybe<Array<ProjectScalarWhereInput>>,
  NOT?: Maybe<Array<ProjectScalarWhereInput>>,
};

export type ProjectUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  logoSmall?: Maybe<Scalars['String']>,
  faqFeature?: Maybe<Scalars['Boolean']>,
  restrictionFeature?: Maybe<Scalars['Boolean']>,
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature?: Maybe<Scalars['Boolean']>,
  languages?: Maybe<Scalars['String']>,
  editorConfig?: Maybe<Scalars['String']>,
};

export type ProjectUpdateManyWithoutAdminsInput = {
  create?: Maybe<Array<ProjectCreateWithoutAdminsInput>>,
  connect?: Maybe<Array<ProjectWhereUniqueInput>>,
  set?: Maybe<Array<ProjectWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProjectWhereUniqueInput>>,
  delete?: Maybe<Array<ProjectWhereUniqueInput>>,
  update?: Maybe<Array<ProjectUpdateWithWhereUniqueWithoutAdminsInput>>,
  updateMany?: Maybe<Array<ProjectUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProjectScalarWhereInput>>,
  upsert?: Maybe<Array<ProjectUpsertWithWhereUniqueWithoutAdminsInput>>,
};

export type ProjectUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<ProjectCreateWithoutCompanyInput>>,
  connect?: Maybe<Array<ProjectWhereUniqueInput>>,
  set?: Maybe<Array<ProjectWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProjectWhereUniqueInput>>,
  delete?: Maybe<Array<ProjectWhereUniqueInput>>,
  update?: Maybe<Array<ProjectUpdateWithWhereUniqueWithoutCompanyInput>>,
  updateMany?: Maybe<Array<ProjectUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProjectScalarWhereInput>>,
  upsert?: Maybe<Array<ProjectUpsertWithWhereUniqueWithoutCompanyInput>>,
};

export type ProjectUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<ProjectCreateWithoutUsersInput>>,
  connect?: Maybe<Array<ProjectWhereUniqueInput>>,
  set?: Maybe<Array<ProjectWhereUniqueInput>>,
  disconnect?: Maybe<Array<ProjectWhereUniqueInput>>,
  delete?: Maybe<Array<ProjectWhereUniqueInput>>,
  update?: Maybe<Array<ProjectUpdateWithWhereUniqueWithoutUsersInput>>,
  updateMany?: Maybe<Array<ProjectUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<ProjectScalarWhereInput>>,
  upsert?: Maybe<Array<ProjectUpsertWithWhereUniqueWithoutUsersInput>>,
};

export type ProjectUpdateManyWithWhereNestedInput = {
  where: ProjectScalarWhereInput,
  data: ProjectUpdateManyDataInput,
};

export type ProjectUpdateOneRequiredWithoutFaqCategoriesInput = {
  create?: Maybe<ProjectCreateWithoutFaqCategoriesInput>,
  connect?: Maybe<ProjectWhereUniqueInput>,
  update?: Maybe<ProjectUpdateWithoutFaqCategoriesDataInput>,
  upsert?: Maybe<ProjectUpsertWithoutFaqCategoriesInput>,
};

export type ProjectUpdateOneRequiredWithoutFaqsInput = {
  create?: Maybe<ProjectCreateWithoutFaqsInput>,
  connect?: Maybe<ProjectWhereUniqueInput>,
  update?: Maybe<ProjectUpdateWithoutFaqsDataInput>,
  upsert?: Maybe<ProjectUpsertWithoutFaqsInput>,
};

export type ProjectUpdateOneRequiredWithoutPostCategoriesInput = {
  create?: Maybe<ProjectCreateWithoutPostCategoriesInput>,
  connect?: Maybe<ProjectWhereUniqueInput>,
  update?: Maybe<ProjectUpdateWithoutPostCategoriesDataInput>,
  upsert?: Maybe<ProjectUpsertWithoutPostCategoriesInput>,
};

export type ProjectUpdateOneRequiredWithoutPostsInput = {
  create?: Maybe<ProjectCreateWithoutPostsInput>,
  connect?: Maybe<ProjectWhereUniqueInput>,
  update?: Maybe<ProjectUpdateWithoutPostsDataInput>,
  upsert?: Maybe<ProjectUpsertWithoutPostsInput>,
};

export type ProjectUpdateWithoutAdminsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  logoSmall?: Maybe<Scalars['String']>,
  faqFeature?: Maybe<Scalars['Boolean']>,
  restrictionFeature?: Maybe<Scalars['Boolean']>,
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature?: Maybe<Scalars['Boolean']>,
  languages?: Maybe<Scalars['String']>,
  editorConfig?: Maybe<Scalars['String']>,
  Company?: Maybe<CompanyUpdateOneRequiredWithoutProjectsInput>,
  postCategories?: Maybe<PostCategoryUpdateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryUpdateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionUpdateManyWithoutProjectInput>,
  faqs?: Maybe<FaqUpdateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionUpdateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionUpdateManyWithoutProjectInput>,
  users?: Maybe<UserUpdateManyWithoutProjectsInput>,
  files?: Maybe<FileUpdateManyWithoutProjectInput>,
};

export type ProjectUpdateWithoutCompanyDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  logoSmall?: Maybe<Scalars['String']>,
  faqFeature?: Maybe<Scalars['Boolean']>,
  restrictionFeature?: Maybe<Scalars['Boolean']>,
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature?: Maybe<Scalars['Boolean']>,
  languages?: Maybe<Scalars['String']>,
  editorConfig?: Maybe<Scalars['String']>,
  postCategories?: Maybe<PostCategoryUpdateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryUpdateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionUpdateManyWithoutProjectInput>,
  faqs?: Maybe<FaqUpdateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionUpdateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionUpdateManyWithoutProjectInput>,
  users?: Maybe<UserUpdateManyWithoutProjectsInput>,
  admins?: Maybe<UserUpdateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileUpdateManyWithoutProjectInput>,
};

export type ProjectUpdateWithoutFaqCategoriesDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  logoSmall?: Maybe<Scalars['String']>,
  faqFeature?: Maybe<Scalars['Boolean']>,
  restrictionFeature?: Maybe<Scalars['Boolean']>,
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature?: Maybe<Scalars['Boolean']>,
  languages?: Maybe<Scalars['String']>,
  editorConfig?: Maybe<Scalars['String']>,
  Company?: Maybe<CompanyUpdateOneRequiredWithoutProjectsInput>,
  postCategories?: Maybe<PostCategoryUpdateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionUpdateManyWithoutProjectInput>,
  faqs?: Maybe<FaqUpdateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionUpdateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionUpdateManyWithoutProjectInput>,
  users?: Maybe<UserUpdateManyWithoutProjectsInput>,
  admins?: Maybe<UserUpdateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileUpdateManyWithoutProjectInput>,
};

export type ProjectUpdateWithoutFaqsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  logoSmall?: Maybe<Scalars['String']>,
  faqFeature?: Maybe<Scalars['Boolean']>,
  restrictionFeature?: Maybe<Scalars['Boolean']>,
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature?: Maybe<Scalars['Boolean']>,
  languages?: Maybe<Scalars['String']>,
  editorConfig?: Maybe<Scalars['String']>,
  Company?: Maybe<CompanyUpdateOneRequiredWithoutProjectsInput>,
  postCategories?: Maybe<PostCategoryUpdateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryUpdateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionUpdateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionUpdateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionUpdateManyWithoutProjectInput>,
  users?: Maybe<UserUpdateManyWithoutProjectsInput>,
  admins?: Maybe<UserUpdateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileUpdateManyWithoutProjectInput>,
};

export type ProjectUpdateWithoutPostCategoriesDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  logoSmall?: Maybe<Scalars['String']>,
  faqFeature?: Maybe<Scalars['Boolean']>,
  restrictionFeature?: Maybe<Scalars['Boolean']>,
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature?: Maybe<Scalars['Boolean']>,
  languages?: Maybe<Scalars['String']>,
  editorConfig?: Maybe<Scalars['String']>,
  Company?: Maybe<CompanyUpdateOneRequiredWithoutProjectsInput>,
  faqCategories?: Maybe<FaqCategoryUpdateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionUpdateManyWithoutProjectInput>,
  faqs?: Maybe<FaqUpdateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionUpdateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionUpdateManyWithoutProjectInput>,
  users?: Maybe<UserUpdateManyWithoutProjectsInput>,
  admins?: Maybe<UserUpdateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileUpdateManyWithoutProjectInput>,
};

export type ProjectUpdateWithoutPostsDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  logoSmall?: Maybe<Scalars['String']>,
  faqFeature?: Maybe<Scalars['Boolean']>,
  restrictionFeature?: Maybe<Scalars['Boolean']>,
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature?: Maybe<Scalars['Boolean']>,
  languages?: Maybe<Scalars['String']>,
  editorConfig?: Maybe<Scalars['String']>,
  Company?: Maybe<CompanyUpdateOneRequiredWithoutProjectsInput>,
  postCategories?: Maybe<PostCategoryUpdateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryUpdateManyWithoutProjectInput>,
  faqs?: Maybe<FaqUpdateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionUpdateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionUpdateManyWithoutProjectInput>,
  users?: Maybe<UserUpdateManyWithoutProjectsInput>,
  admins?: Maybe<UserUpdateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileUpdateManyWithoutProjectInput>,
};

export type ProjectUpdateWithoutUsersDataInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  logoSmall?: Maybe<Scalars['String']>,
  faqFeature?: Maybe<Scalars['Boolean']>,
  restrictionFeature?: Maybe<Scalars['Boolean']>,
  suggestionFeature?: Maybe<Scalars['Boolean']>,
  blogFeature?: Maybe<Scalars['Boolean']>,
  languages?: Maybe<Scalars['String']>,
  editorConfig?: Maybe<Scalars['String']>,
  Company?: Maybe<CompanyUpdateOneRequiredWithoutProjectsInput>,
  postCategories?: Maybe<PostCategoryUpdateManyWithoutProjectInput>,
  faqCategories?: Maybe<FaqCategoryUpdateManyWithoutProjectInput>,
  posts?: Maybe<PostVersionUpdateManyWithoutProjectInput>,
  faqs?: Maybe<FaqUpdateManyWithoutProjectInput>,
  restrictions?: Maybe<RestrictionUpdateManyWithoutProjectInput>,
  suggestions?: Maybe<SuggestionUpdateManyWithoutProjectInput>,
  admins?: Maybe<UserUpdateManyWithoutProjectsAdminInput>,
  files?: Maybe<FileUpdateManyWithoutProjectInput>,
};

export type ProjectUpdateWithWhereUniqueWithoutAdminsInput = {
  where: ProjectWhereUniqueInput,
  data: ProjectUpdateWithoutAdminsDataInput,
};

export type ProjectUpdateWithWhereUniqueWithoutCompanyInput = {
  where: ProjectWhereUniqueInput,
  data: ProjectUpdateWithoutCompanyDataInput,
};

export type ProjectUpdateWithWhereUniqueWithoutUsersInput = {
  where: ProjectWhereUniqueInput,
  data: ProjectUpdateWithoutUsersDataInput,
};

export type ProjectUpsertWithoutFaqCategoriesInput = {
  update: ProjectUpdateWithoutFaqCategoriesDataInput,
  create: ProjectCreateWithoutFaqCategoriesInput,
};

export type ProjectUpsertWithoutFaqsInput = {
  update: ProjectUpdateWithoutFaqsDataInput,
  create: ProjectCreateWithoutFaqsInput,
};

export type ProjectUpsertWithoutPostCategoriesInput = {
  update: ProjectUpdateWithoutPostCategoriesDataInput,
  create: ProjectCreateWithoutPostCategoriesInput,
};

export type ProjectUpsertWithoutPostsInput = {
  update: ProjectUpdateWithoutPostsDataInput,
  create: ProjectCreateWithoutPostsInput,
};

export type ProjectUpsertWithWhereUniqueWithoutAdminsInput = {
  where: ProjectWhereUniqueInput,
  update: ProjectUpdateWithoutAdminsDataInput,
  create: ProjectCreateWithoutAdminsInput,
};

export type ProjectUpsertWithWhereUniqueWithoutCompanyInput = {
  where: ProjectWhereUniqueInput,
  update: ProjectUpdateWithoutCompanyDataInput,
  create: ProjectCreateWithoutCompanyInput,
};

export type ProjectUpsertWithWhereUniqueWithoutUsersInput = {
  where: ProjectWhereUniqueInput,
  update: ProjectUpdateWithoutUsersDataInput,
  create: ProjectCreateWithoutUsersInput,
};

export type ProjectWhereInput = {
  id?: Maybe<StringFilter>,
  name?: Maybe<StringFilter>,
  company?: Maybe<StringFilter>,
  webUrl?: Maybe<NullableStringFilter>,
  logo?: Maybe<StringFilter>,
  logoSmall?: Maybe<StringFilter>,
  postCategories?: Maybe<PostCategoryFilter>,
  faqCategories?: Maybe<FaqCategoryFilter>,
  posts?: Maybe<PostVersionFilter>,
  faqs?: Maybe<FaqFilter>,
  restrictions?: Maybe<RestrictionFilter>,
  suggestions?: Maybe<SuggestionFilter>,
  users?: Maybe<UserFilter>,
  admins?: Maybe<UserFilter>,
  files?: Maybe<FileFilter>,
  faqFeature?: Maybe<BooleanFilter>,
  restrictionFeature?: Maybe<BooleanFilter>,
  suggestionFeature?: Maybe<BooleanFilter>,
  blogFeature?: Maybe<BooleanFilter>,
  languages?: Maybe<StringFilter>,
  editorConfig?: Maybe<StringFilter>,
  AND?: Maybe<Array<ProjectWhereInput>>,
  OR?: Maybe<Array<ProjectWhereInput>>,
  NOT?: Maybe<Array<ProjectWhereInput>>,
  Company?: Maybe<CompanyWhereInput>,
};

export type ProjectWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type Query = {
  restrictions: Array<ClientRestriction>,
  restriction?: Maybe<ClientRestriction>,
  faqCategories: Array<FaqCategory>,
  faq?: Maybe<Faq>,
  faqs: Array<Faq>,
  clientFaqs: Array<ClientFaq>,
  companies: Array<Company>,
  company?: Maybe<Company>,
  post?: Maybe<Post>,
  posts: Array<Post>,
  clientPosts: ClientPosts,
  clientPost: ClientPost,
  postCategories: Array<PostCategory>,
  postCategory?: Maybe<PostCategory>,
  clientPostCategories: Array<ClientPostCategory>,
  projects: Array<Project>,
  project?: Maybe<Project>,
  users: Array<User>,
  postUsers: Array<User>,
  usersToConnect: Array<User>,
  user?: Maybe<User>,
  me: User,
  suggestion?: Maybe<Suggestion>,
  suggestions: Array<Suggestion>,
  clientSuggestions: Array<ClientSuggestion>,
};


export type QueryRestrictionsArgs = {
  projectId: Scalars['String']
};


export type QueryRestrictionArgs = {
  id?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>
};


export type QueryFaqCategoriesArgs = {
  projectId: Scalars['String']
};


export type QueryFaqArgs = {
  where: FaqWhereUniqueInput
};


export type QueryFaqsArgs = {
  projectId: Scalars['String']
};


export type QueryClientFaqsArgs = {
  projectId: Scalars['String'],
  language?: Maybe<Scalars['String']>
};


export type QueryCompaniesArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<CompanyWhereUniqueInput>,
  after?: Maybe<CompanyWhereUniqueInput>
};


export type QueryCompanyArgs = {
  where: CompanyWhereUniqueInput
};


export type QueryPostArgs = {
  where: PostWhereUniqueInput
};


export type QueryPostsArgs = {
  projectId: Scalars['String']
};


export type QueryClientPostsArgs = {
  projectId: Scalars['String'],
  categoryId?: Maybe<Scalars['String']>,
  tag?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  page?: Maybe<Scalars['Int']>,
  itemsPerPage?: Maybe<Scalars['Int']>
};


export type QueryClientPostArgs = {
  projectId: Scalars['String'],
  postId?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  relatedPostsLimit?: Maybe<Scalars['Int']>
};


export type QueryPostCategoriesArgs = {
  projectId: Scalars['String']
};


export type QueryPostCategoryArgs = {
  where: PostCategoryWhereUniqueInput
};


export type QueryClientPostCategoriesArgs = {
  projectId: Scalars['String']
};


export type QueryProjectsArgs = {
  companyId?: Maybe<Scalars['String']>
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput
};


export type QueryUsersArgs = {
  projectId: Scalars['String']
};


export type QueryPostUsersArgs = {
  projectId: Scalars['String']
};


export type QueryUsersToConnectArgs = {
  projectId: Scalars['String']
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput
};


export type QuerySuggestionArgs = {
  where: SuggestionWhereUniqueInput
};


export type QuerySuggestionsArgs = {
  projectId: Scalars['String']
};


export type QueryClientSuggestionsArgs = {
  projectId: Scalars['String']
};

export type Restriction = {
  id: Scalars['String'],
  logoImg: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  slug: Scalars['String'],
  Project: Project,
  restrictionVersions: Array<RestrictionVersion>,
};


export type RestrictionRestrictionVersionsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<RestrictionVersionWhereUniqueInput>,
  after?: Maybe<RestrictionVersionWhereUniqueInput>
};

export type RestrictionCreateManyWithoutProjectInput = {
  create?: Maybe<Array<RestrictionCreateWithoutProjectInput>>,
  connect?: Maybe<Array<RestrictionWhereUniqueInput>>,
};

export type RestrictionCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>,
  logoImg: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  slug: Scalars['String'],
  restrictionVersions?: Maybe<RestrictionVersionCreateManyWithoutRestrictionInput>,
};

export type RestrictionFilter = {
  every?: Maybe<RestrictionWhereInput>,
  some?: Maybe<RestrictionWhereInput>,
  none?: Maybe<RestrictionWhereInput>,
};

export type RestrictionScalarWhereInput = {
  id?: Maybe<StringFilter>,
  logoImg?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  slug?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  restrictionVersions?: Maybe<RestrictionVersionFilter>,
  AND?: Maybe<Array<RestrictionScalarWhereInput>>,
  OR?: Maybe<Array<RestrictionScalarWhereInput>>,
  NOT?: Maybe<Array<RestrictionScalarWhereInput>>,
};

export type RestrictionUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  logoImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  slug?: Maybe<Scalars['String']>,
};

export type RestrictionUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<RestrictionCreateWithoutProjectInput>>,
  connect?: Maybe<Array<RestrictionWhereUniqueInput>>,
  set?: Maybe<Array<RestrictionWhereUniqueInput>>,
  disconnect?: Maybe<Array<RestrictionWhereUniqueInput>>,
  delete?: Maybe<Array<RestrictionWhereUniqueInput>>,
  update?: Maybe<Array<RestrictionUpdateWithWhereUniqueWithoutProjectInput>>,
  updateMany?: Maybe<Array<RestrictionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<RestrictionScalarWhereInput>>,
  upsert?: Maybe<Array<RestrictionUpsertWithWhereUniqueWithoutProjectInput>>,
};

export type RestrictionUpdateManyWithWhereNestedInput = {
  where: RestrictionScalarWhereInput,
  data: RestrictionUpdateManyDataInput,
};

export type RestrictionUpdateWithoutProjectDataInput = {
  id?: Maybe<Scalars['String']>,
  logoImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  slug?: Maybe<Scalars['String']>,
  restrictionVersions?: Maybe<RestrictionVersionUpdateManyWithoutRestrictionInput>,
};

export type RestrictionUpdateWithWhereUniqueWithoutProjectInput = {
  where: RestrictionWhereUniqueInput,
  data: RestrictionUpdateWithoutProjectDataInput,
};

export type RestrictionUpsertWithWhereUniqueWithoutProjectInput = {
  where: RestrictionWhereUniqueInput,
  update: RestrictionUpdateWithoutProjectDataInput,
  create: RestrictionCreateWithoutProjectInput,
};

export type RestrictionVersion = {
  id: Scalars['String'],
  language: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  text: Scalars['String'],
  title: Scalars['String'],
  Restriction: Restriction,
};

export type RestrictionVersionCreateManyWithoutRestrictionInput = {
  create?: Maybe<Array<RestrictionVersionCreateWithoutRestrictionInput>>,
  connect?: Maybe<Array<RestrictionVersionWhereUniqueInput>>,
};

export type RestrictionVersionCreateWithoutRestrictionInput = {
  id?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  text: Scalars['String'],
  language: Scalars['String'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type RestrictionVersionFilter = {
  every?: Maybe<RestrictionVersionWhereInput>,
  some?: Maybe<RestrictionVersionWhereInput>,
  none?: Maybe<RestrictionVersionWhereInput>,
};

export type RestrictionVersionScalarWhereInput = {
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  text?: Maybe<StringFilter>,
  language?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  restriction?: Maybe<StringFilter>,
  AND?: Maybe<Array<RestrictionVersionScalarWhereInput>>,
  OR?: Maybe<Array<RestrictionVersionScalarWhereInput>>,
  NOT?: Maybe<Array<RestrictionVersionScalarWhereInput>>,
};

export type RestrictionVersionUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type RestrictionVersionUpdateManyWithoutRestrictionInput = {
  create?: Maybe<Array<RestrictionVersionCreateWithoutRestrictionInput>>,
  connect?: Maybe<Array<RestrictionVersionWhereUniqueInput>>,
  set?: Maybe<Array<RestrictionVersionWhereUniqueInput>>,
  disconnect?: Maybe<Array<RestrictionVersionWhereUniqueInput>>,
  delete?: Maybe<Array<RestrictionVersionWhereUniqueInput>>,
  update?: Maybe<Array<RestrictionVersionUpdateWithWhereUniqueWithoutRestrictionInput>>,
  updateMany?: Maybe<Array<RestrictionVersionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<RestrictionVersionScalarWhereInput>>,
  upsert?: Maybe<Array<RestrictionVersionUpsertWithWhereUniqueWithoutRestrictionInput>>,
};

export type RestrictionVersionUpdateManyWithWhereNestedInput = {
  where: RestrictionVersionScalarWhereInput,
  data: RestrictionVersionUpdateManyDataInput,
};

export type RestrictionVersionUpdateWithoutRestrictionDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type RestrictionVersionUpdateWithWhereUniqueWithoutRestrictionInput = {
  where: RestrictionVersionWhereUniqueInput,
  data: RestrictionVersionUpdateWithoutRestrictionDataInput,
};

export type RestrictionVersionUpsertWithWhereUniqueWithoutRestrictionInput = {
  where: RestrictionVersionWhereUniqueInput,
  update: RestrictionVersionUpdateWithoutRestrictionDataInput,
  create: RestrictionVersionCreateWithoutRestrictionInput,
};

export type RestrictionVersionWhereInput = {
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  text?: Maybe<StringFilter>,
  language?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  restriction?: Maybe<StringFilter>,
  AND?: Maybe<Array<RestrictionVersionWhereInput>>,
  OR?: Maybe<Array<RestrictionVersionWhereInput>>,
  NOT?: Maybe<Array<RestrictionVersionWhereInput>>,
  Restriction?: Maybe<RestrictionWhereInput>,
};

export type RestrictionVersionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type RestrictionWhereInput = {
  id?: Maybe<StringFilter>,
  logoImg?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  slug?: Maybe<StringFilter>,
  project?: Maybe<StringFilter>,
  restrictionVersions?: Maybe<RestrictionVersionFilter>,
  AND?: Maybe<Array<RestrictionWhereInput>>,
  OR?: Maybe<Array<RestrictionWhereInput>>,
  NOT?: Maybe<Array<RestrictionWhereInput>>,
  Project?: Maybe<ProjectWhereInput>,
};

export type RestrictionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>,
  not?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  notIn?: Maybe<Array<Scalars['String']>>,
  lt?: Maybe<Scalars['String']>,
  lte?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  gte?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
};

export type Suggestion = {
  id: Scalars['String'],
  Project: Project,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  from?: Maybe<Scalars['DateTime']>,
  until?: Maybe<Scalars['DateTime']>,
  img: Scalars['String'],
  published: Scalars['Boolean'],
  unlimited: Scalars['Boolean'],
  title: Scalars['String'],
  items: Array<SuggestionItem>,
};


export type SuggestionItemsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<SuggestionItemWhereUniqueInput>,
  after?: Maybe<SuggestionItemWhereUniqueInput>
};

export type SuggestionCreateManyWithoutProjectInput = {
  create?: Maybe<Array<SuggestionCreateWithoutProjectInput>>,
  connect?: Maybe<Array<SuggestionWhereUniqueInput>>,
};

export type SuggestionCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  img: Scalars['String'],
  published: Scalars['Boolean'],
  unlimited?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  from?: Maybe<Scalars['DateTime']>,
  until?: Maybe<Scalars['DateTime']>,
  items?: Maybe<SuggestionItemCreateManyWithoutSuggestionInput>,
};

export type SuggestionFilter = {
  every?: Maybe<SuggestionWhereInput>,
  some?: Maybe<SuggestionWhereInput>,
  none?: Maybe<SuggestionWhereInput>,
};

export type SuggestionItem = {
  id: Scalars['String'],
  img: Scalars['String'],
  title: Scalars['String'],
  text: Scalars['String'],
  index: Scalars['Int'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  Suggestion: Suggestion,
};

export type SuggestionItemCreateManyWithoutSuggestionInput = {
  create?: Maybe<Array<SuggestionItemCreateWithoutSuggestionInput>>,
  connect?: Maybe<Array<SuggestionItemWhereUniqueInput>>,
};

export type SuggestionItemCreateWithoutSuggestionInput = {
  id?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  text: Scalars['String'],
  img: Scalars['String'],
  index: Scalars['Int'],
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type SuggestionItemFilter = {
  every?: Maybe<SuggestionItemWhereInput>,
  some?: Maybe<SuggestionItemWhereInput>,
  none?: Maybe<SuggestionItemWhereInput>,
};

export type SuggestionItemInputCreate = {
  index: Scalars['Int'],
  img: Scalars['Upload'],
  title: Scalars['String'],
  text: Scalars['String'],
};

export type SuggestionItemInputUpdate = {
  id: Scalars['String'],
  index: Scalars['Int'],
  updatedImg?: Maybe<Scalars['Upload']>,
  title: Scalars['String'],
  img: Scalars['String'],
  text: Scalars['String'],
};

export type SuggestionItemScalarWhereInput = {
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  text?: Maybe<StringFilter>,
  img?: Maybe<StringFilter>,
  index?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  suggestion?: Maybe<StringFilter>,
  AND?: Maybe<Array<SuggestionItemScalarWhereInput>>,
  OR?: Maybe<Array<SuggestionItemScalarWhereInput>>,
  NOT?: Maybe<Array<SuggestionItemScalarWhereInput>>,
};

export type SuggestionItemUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  img?: Maybe<Scalars['String']>,
  index?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type SuggestionItemUpdateManyWithoutSuggestionInput = {
  create?: Maybe<Array<SuggestionItemCreateWithoutSuggestionInput>>,
  connect?: Maybe<Array<SuggestionItemWhereUniqueInput>>,
  set?: Maybe<Array<SuggestionItemWhereUniqueInput>>,
  disconnect?: Maybe<Array<SuggestionItemWhereUniqueInput>>,
  delete?: Maybe<Array<SuggestionItemWhereUniqueInput>>,
  update?: Maybe<Array<SuggestionItemUpdateWithWhereUniqueWithoutSuggestionInput>>,
  updateMany?: Maybe<Array<SuggestionItemUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<SuggestionItemScalarWhereInput>>,
  upsert?: Maybe<Array<SuggestionItemUpsertWithWhereUniqueWithoutSuggestionInput>>,
};

export type SuggestionItemUpdateManyWithWhereNestedInput = {
  where: SuggestionItemScalarWhereInput,
  data: SuggestionItemUpdateManyDataInput,
};

export type SuggestionItemUpdateWithoutSuggestionDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  img?: Maybe<Scalars['String']>,
  index?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type SuggestionItemUpdateWithWhereUniqueWithoutSuggestionInput = {
  where: SuggestionItemWhereUniqueInput,
  data: SuggestionItemUpdateWithoutSuggestionDataInput,
};

export type SuggestionItemUpsertWithWhereUniqueWithoutSuggestionInput = {
  where: SuggestionItemWhereUniqueInput,
  update: SuggestionItemUpdateWithoutSuggestionDataInput,
  create: SuggestionItemCreateWithoutSuggestionInput,
};

export type SuggestionItemWhereInput = {
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  text?: Maybe<StringFilter>,
  img?: Maybe<StringFilter>,
  index?: Maybe<IntFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  suggestion?: Maybe<StringFilter>,
  AND?: Maybe<Array<SuggestionItemWhereInput>>,
  OR?: Maybe<Array<SuggestionItemWhereInput>>,
  NOT?: Maybe<Array<SuggestionItemWhereInput>>,
  Suggestion?: Maybe<SuggestionWhereInput>,
};

export type SuggestionItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};

export type SuggestionScalarWhereInput = {
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  img?: Maybe<StringFilter>,
  published?: Maybe<BooleanFilter>,
  unlimited?: Maybe<BooleanFilter>,
  project?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  from?: Maybe<NullableDateTimeFilter>,
  until?: Maybe<NullableDateTimeFilter>,
  items?: Maybe<SuggestionItemFilter>,
  AND?: Maybe<Array<SuggestionScalarWhereInput>>,
  OR?: Maybe<Array<SuggestionScalarWhereInput>>,
  NOT?: Maybe<Array<SuggestionScalarWhereInput>>,
};

export type SuggestionUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  img?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  unlimited?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  from?: Maybe<Scalars['DateTime']>,
  until?: Maybe<Scalars['DateTime']>,
};

export type SuggestionUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<SuggestionCreateWithoutProjectInput>>,
  connect?: Maybe<Array<SuggestionWhereUniqueInput>>,
  set?: Maybe<Array<SuggestionWhereUniqueInput>>,
  disconnect?: Maybe<Array<SuggestionWhereUniqueInput>>,
  delete?: Maybe<Array<SuggestionWhereUniqueInput>>,
  update?: Maybe<Array<SuggestionUpdateWithWhereUniqueWithoutProjectInput>>,
  updateMany?: Maybe<Array<SuggestionUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<SuggestionScalarWhereInput>>,
  upsert?: Maybe<Array<SuggestionUpsertWithWhereUniqueWithoutProjectInput>>,
};

export type SuggestionUpdateManyWithWhereNestedInput = {
  where: SuggestionScalarWhereInput,
  data: SuggestionUpdateManyDataInput,
};

export type SuggestionUpdateWithoutProjectDataInput = {
  id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  img?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  unlimited?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  from?: Maybe<Scalars['DateTime']>,
  until?: Maybe<Scalars['DateTime']>,
  items?: Maybe<SuggestionItemUpdateManyWithoutSuggestionInput>,
};

export type SuggestionUpdateWithWhereUniqueWithoutProjectInput = {
  where: SuggestionWhereUniqueInput,
  data: SuggestionUpdateWithoutProjectDataInput,
};

export type SuggestionUpsertWithWhereUniqueWithoutProjectInput = {
  where: SuggestionWhereUniqueInput,
  update: SuggestionUpdateWithoutProjectDataInput,
  create: SuggestionCreateWithoutProjectInput,
};

export type SuggestionWhereInput = {
  id?: Maybe<StringFilter>,
  title?: Maybe<StringFilter>,
  img?: Maybe<StringFilter>,
  published?: Maybe<BooleanFilter>,
  unlimited?: Maybe<BooleanFilter>,
  project?: Maybe<StringFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  from?: Maybe<NullableDateTimeFilter>,
  until?: Maybe<NullableDateTimeFilter>,
  items?: Maybe<SuggestionItemFilter>,
  AND?: Maybe<Array<SuggestionWhereInput>>,
  OR?: Maybe<Array<SuggestionWhereInput>>,
  NOT?: Maybe<Array<SuggestionWhereInput>>,
  Project?: Maybe<ProjectWhereInput>,
};

export type SuggestionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
};


export type User = {
  id: Scalars['String'],
  authId?: Maybe<Scalars['String']>,
  projects: Array<Project>,
  email: Scalars['String'],
  name: Scalars['String'],
  profileImg?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
};


export type UserProjectsArgs = {
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<ProjectWhereUniqueInput>,
  after?: Maybe<ProjectWhereUniqueInput>
};

export type UserCreateManyWithoutProjectsAdminInput = {
  create?: Maybe<Array<UserCreateWithoutProjectsAdminInput>>,
  connect?: Maybe<Array<UserWhereUniqueInput>>,
};

export type UserCreateManyWithoutProjectsInput = {
  create?: Maybe<Array<UserCreateWithoutProjectsInput>>,
  connect?: Maybe<Array<UserWhereUniqueInput>>,
};

export type UserCreateOneWithoutFaqsInput = {
  create?: Maybe<UserCreateWithoutFaqsInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateOneWithoutPostVersionsInput = {
  create?: Maybe<UserCreateWithoutPostVersionsInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserCreateWithoutFaqsInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  name: Scalars['String'],
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  projects?: Maybe<ProjectCreateManyWithoutUsersInput>,
  projectsAdmin?: Maybe<ProjectCreateManyWithoutAdminsInput>,
  postVersions?: Maybe<PostVersionCreateManyWithoutAuthorInput>,
};

export type UserCreateWithoutPostVersionsInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  name: Scalars['String'],
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  projects?: Maybe<ProjectCreateManyWithoutUsersInput>,
  projectsAdmin?: Maybe<ProjectCreateManyWithoutAdminsInput>,
  faqs?: Maybe<FaqCreateManyWithoutAuthorInput>,
};

export type UserCreateWithoutProjectsAdminInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  name: Scalars['String'],
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  projects?: Maybe<ProjectCreateManyWithoutUsersInput>,
  postVersions?: Maybe<PostVersionCreateManyWithoutAuthorInput>,
  faqs?: Maybe<FaqCreateManyWithoutAuthorInput>,
};

export type UserCreateWithoutProjectsInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  name: Scalars['String'],
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  projectsAdmin?: Maybe<ProjectCreateManyWithoutAdminsInput>,
  postVersions?: Maybe<PostVersionCreateManyWithoutAuthorInput>,
  faqs?: Maybe<FaqCreateManyWithoutAuthorInput>,
};

export type UserFilter = {
  every?: Maybe<UserWhereInput>,
  some?: Maybe<UserWhereInput>,
  none?: Maybe<UserWhereInput>,
};

export type UserScalarWhereInput = {
  id?: Maybe<StringFilter>,
  authId?: Maybe<NullableStringFilter>,
  email?: Maybe<StringFilter>,
  projects?: Maybe<ProjectFilter>,
  projectsAdmin?: Maybe<ProjectFilter>,
  name?: Maybe<StringFilter>,
  profileImg?: Maybe<NullableStringFilter>,
  postVersions?: Maybe<PostVersionFilter>,
  faqs?: Maybe<FaqFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<UserScalarWhereInput>>,
  OR?: Maybe<Array<UserScalarWhereInput>>,
  NOT?: Maybe<Array<UserScalarWhereInput>>,
};

export type UserUpdateManyDataInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
};

export type UserUpdateManyWithoutProjectsAdminInput = {
  create?: Maybe<Array<UserCreateWithoutProjectsAdminInput>>,
  connect?: Maybe<Array<UserWhereUniqueInput>>,
  set?: Maybe<Array<UserWhereUniqueInput>>,
  disconnect?: Maybe<Array<UserWhereUniqueInput>>,
  delete?: Maybe<Array<UserWhereUniqueInput>>,
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutProjectsAdminInput>>,
  updateMany?: Maybe<Array<UserUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<UserScalarWhereInput>>,
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutProjectsAdminInput>>,
};

export type UserUpdateManyWithoutProjectsInput = {
  create?: Maybe<Array<UserCreateWithoutProjectsInput>>,
  connect?: Maybe<Array<UserWhereUniqueInput>>,
  set?: Maybe<Array<UserWhereUniqueInput>>,
  disconnect?: Maybe<Array<UserWhereUniqueInput>>,
  delete?: Maybe<Array<UserWhereUniqueInput>>,
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutProjectsInput>>,
  updateMany?: Maybe<Array<UserUpdateManyWithWhereNestedInput>>,
  deleteMany?: Maybe<Array<UserScalarWhereInput>>,
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutProjectsInput>>,
};

export type UserUpdateManyWithWhereNestedInput = {
  where: UserScalarWhereInput,
  data: UserUpdateManyDataInput,
};

export type UserUpdateOneRequiredWithoutFaqsInput = {
  create?: Maybe<UserCreateWithoutFaqsInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  update?: Maybe<UserUpdateWithoutFaqsDataInput>,
  upsert?: Maybe<UserUpsertWithoutFaqsInput>,
};

export type UserUpdateOneRequiredWithoutPostVersionsInput = {
  create?: Maybe<UserCreateWithoutPostVersionsInput>,
  connect?: Maybe<UserWhereUniqueInput>,
  update?: Maybe<UserUpdateWithoutPostVersionsDataInput>,
  upsert?: Maybe<UserUpsertWithoutPostVersionsInput>,
};

export type UserUpdateWithoutFaqsDataInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  projects?: Maybe<ProjectUpdateManyWithoutUsersInput>,
  projectsAdmin?: Maybe<ProjectUpdateManyWithoutAdminsInput>,
  postVersions?: Maybe<PostVersionUpdateManyWithoutAuthorInput>,
};

export type UserUpdateWithoutPostVersionsDataInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  projects?: Maybe<ProjectUpdateManyWithoutUsersInput>,
  projectsAdmin?: Maybe<ProjectUpdateManyWithoutAdminsInput>,
  faqs?: Maybe<FaqUpdateManyWithoutAuthorInput>,
};

export type UserUpdateWithoutProjectsAdminDataInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  projects?: Maybe<ProjectUpdateManyWithoutUsersInput>,
  postVersions?: Maybe<PostVersionUpdateManyWithoutAuthorInput>,
  faqs?: Maybe<FaqUpdateManyWithoutAuthorInput>,
};

export type UserUpdateWithoutProjectsDataInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profileImg?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  projectsAdmin?: Maybe<ProjectUpdateManyWithoutAdminsInput>,
  postVersions?: Maybe<PostVersionUpdateManyWithoutAuthorInput>,
  faqs?: Maybe<FaqUpdateManyWithoutAuthorInput>,
};

export type UserUpdateWithWhereUniqueWithoutProjectsAdminInput = {
  where: UserWhereUniqueInput,
  data: UserUpdateWithoutProjectsAdminDataInput,
};

export type UserUpdateWithWhereUniqueWithoutProjectsInput = {
  where: UserWhereUniqueInput,
  data: UserUpdateWithoutProjectsDataInput,
};

export type UserUpsertWithoutFaqsInput = {
  update: UserUpdateWithoutFaqsDataInput,
  create: UserCreateWithoutFaqsInput,
};

export type UserUpsertWithoutPostVersionsInput = {
  update: UserUpdateWithoutPostVersionsDataInput,
  create: UserCreateWithoutPostVersionsInput,
};

export type UserUpsertWithWhereUniqueWithoutProjectsAdminInput = {
  where: UserWhereUniqueInput,
  update: UserUpdateWithoutProjectsAdminDataInput,
  create: UserCreateWithoutProjectsAdminInput,
};

export type UserUpsertWithWhereUniqueWithoutProjectsInput = {
  where: UserWhereUniqueInput,
  update: UserUpdateWithoutProjectsDataInput,
  create: UserCreateWithoutProjectsInput,
};

export type UserWhereInput = {
  id?: Maybe<StringFilter>,
  authId?: Maybe<NullableStringFilter>,
  email?: Maybe<StringFilter>,
  projects?: Maybe<ProjectFilter>,
  projectsAdmin?: Maybe<ProjectFilter>,
  name?: Maybe<StringFilter>,
  profileImg?: Maybe<NullableStringFilter>,
  postVersions?: Maybe<PostVersionFilter>,
  faqs?: Maybe<FaqFilter>,
  createdAt?: Maybe<DateTimeFilter>,
  updatedAt?: Maybe<DateTimeFilter>,
  AND?: Maybe<Array<UserWhereInput>>,
  OR?: Maybe<Array<UserWhereInput>>,
  NOT?: Maybe<Array<UserWhereInput>>,
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>,
  authId?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type CreateCompanyMutationVariables = {
  name: Scalars['String']
};


export type CreateCompanyMutation = { createOneCompany: Pick<Company, 'id' | 'name'> };

export type CompanyQueryVariables = {
  id?: Maybe<Scalars['String']>
};


export type CompanyQuery = { company: Maybe<Pick<Company, 'id' | 'name'>> };

export type UpdateCompanyMutationVariables = {
  id?: Maybe<Scalars['String']>,
  name: Scalars['String']
};


export type UpdateCompanyMutation = { updateOneCompany: Maybe<Pick<Company, 'id' | 'name'>> };

export type DeleteCompanyMutationVariables = {
  id?: Maybe<Scalars['String']>
};


export type DeleteCompanyMutation = { deleteOneCompany: Maybe<Pick<Company, 'id'>> };

export type CompaniesQueryVariables = {};


export type CompaniesQuery = { companies: Array<Pick<Company, 'id' | 'name'>> };

export type CreateProjectMutationVariables = {
  name: Scalars['String'],
  companyId: Scalars['String'],
  logo: Scalars['Upload'],
  logoSmall: Scalars['Upload'],
  webUrl?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  faq: Scalars['Boolean'],
  restriction: Scalars['Boolean'],
  suggestion: Scalars['Boolean'],
  blog: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String']
};


export type CreateProjectMutation = { createOneProject: (
    Pick<Project, 'id' | 'name'>
    & { Company: Pick<Company, 'id' | 'name'> }
  ) };

export type ProjectQueryVariables = {
  id?: Maybe<Scalars['String']>
};


export type ProjectQuery = { project: Maybe<Pick<Project, 'id' | 'name' | 'webUrl' | 'faqFeature' | 'restrictionFeature' | 'blogFeature' | 'suggestionFeature' | 'languages' | 'editorConfig'>> };

export type UpdateProjectMutationVariables = {
  id: Scalars['String'],
  name: Scalars['String'],
  webUrl?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['Upload']>,
  logoSmall?: Maybe<Scalars['Upload']>,
  faq: Scalars['Boolean'],
  restriction: Scalars['Boolean'],
  suggestion: Scalars['Boolean'],
  blog: Scalars['Boolean'],
  languages: Scalars['String'],
  editorConfig: Scalars['String']
};


export type UpdateProjectMutation = { updateOneProject: (
    Pick<Project, 'id' | 'name' | 'webUrl' | 'logo' | 'logoSmall' | 'faqFeature' | 'restrictionFeature' | 'blogFeature' | 'languages'>
    & { Company: Pick<Company, 'id' | 'name'> }
  ) };

export type DeleteProjectMutationVariables = {
  id?: Maybe<Scalars['String']>
};


export type DeleteProjectMutation = { deleteOneProject: Maybe<Pick<Project, 'id'>> };

export type ProjectsQueryVariables = {};


export type ProjectsQuery = { projects: Array<(
    Pick<Project, 'id' | 'name'>
    & { Company: Pick<Company, 'id' | 'name'> }
  )> };

export type FaqCategoriesQueryVariables = {
  projectId: Scalars['String']
};


export type FaqCategoriesQuery = { faqCategories: Array<Pick<FaqCategory, 'id' | 'name'>> };

export type CreateFaqMutationVariables = {
  question: Scalars['String'],
  answer: Scalars['String'],
  published: Scalars['Boolean'],
  language: Scalars['String'],
  category: Scalars['String'],
  projectId: Scalars['String'],
  largeLink?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>
};


export type CreateFaqMutation = { createOneFaq: (
    Pick<Faq, 'id' | 'question' | 'answer' | 'published' | 'language' | 'largeLink' | 'largeLinkTitle'>
    & { Category: Maybe<Pick<FaqCategory, 'id' | 'name'>> }
  ) };

export type FaqQueryVariables = {
  id?: Maybe<Scalars['String']>,
  projectId: Scalars['String']
};


export type FaqQuery = { faqCategories: Array<Pick<FaqCategory, 'id' | 'name'>>, faq: Maybe<(
    Pick<Faq, 'id' | 'question' | 'answer' | 'language' | 'published' | 'largeLink' | 'largeLinkTitle'>
    & { Category: Maybe<Pick<FaqCategory, 'id' | 'name'>> }
  )> };

export type UpdateFaqMutationVariables = {
  id: Scalars['String'],
  question: Scalars['String'],
  answer: Scalars['String'],
  published: Scalars['Boolean'],
  language: Scalars['String'],
  category?: Maybe<Scalars['String']>,
  largeLink?: Maybe<Scalars['String']>,
  largeLinkTitle?: Maybe<Scalars['String']>
};


export type UpdateFaqMutation = { updateOneFaq: (
    Pick<Faq, 'id' | 'question' | 'answer' | 'published' | 'language' | 'largeLink' | 'largeLinkTitle'>
    & { Category: Maybe<Pick<FaqCategory, 'id' | 'name'>> }
  ) };

export type DeleteFaqMutationVariables = {
  id?: Maybe<Scalars['String']>
};


export type DeleteFaqMutation = { deleteOneFaq: Maybe<Pick<Faq, 'id'>> };

export type FaqsQueryVariables = {
  projectId: Scalars['String']
};


export type FaqsQuery = { faqs: Array<(
    Pick<Faq, 'id' | 'question' | 'language' | 'published'>
    & { Category: Maybe<Pick<FaqCategory, 'id' | 'name'>> }
  )> };

export type CreatePostFormDataQueryVariables = {
  projectId: Scalars['String']
};


export type CreatePostFormDataQuery = { postCategories: Array<Pick<PostCategory, 'id' | 'name'>>, postUsers: Array<Pick<User, 'authId' | 'name'>> };

export type CreatePostMutationVariables = {
  title: Scalars['String'],
  slug: Scalars['String'],
  text: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  published: Scalars['Boolean'],
  language: Scalars['String'],
  categories: Array<Scalars['String']>,
  projectId: Scalars['String'],
  createdAt: Scalars['DateTime'],
  mainImg: Scalars['Upload'],
  author: Scalars['String'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>
};


export type CreatePostMutation = { createOnePost: (
    Pick<Post, 'id'>
    & { PublishedVersion: (
      Pick<PostVersion, 'id' | 'title' | 'language' | 'createdAt' | 'published'>
      & { Author: Pick<User, 'id' | 'authId' | 'name'>, categories: Array<Pick<PostCategory, 'id' | 'name'>>, publishedPost: Maybe<Pick<Post, 'id'>> }
    ) }
  ) };

export type PostQueryVariables = {
  id?: Maybe<Scalars['String']>,
  projectId: Scalars['String']
};


export type PostQuery = { postCategories: Array<Pick<PostCategory, 'id' | 'name'>>, postUsers: Array<Pick<User, 'authId' | 'name'>>, post: Maybe<(
    Pick<Post, 'id'>
    & { DraftedVersion: (
      Pick<PostVersion, 'id' | 'title' | 'slug' | 'text' | 'excerpt' | 'language' | 'mainImg' | 'createdAt' | 'ogTitle' | 'ogDescription' | 'tags'>
      & { categories: Array<Pick<PostCategory, 'id' | 'name'>>, Author: Pick<User, 'id' | 'authId' | 'name'> }
    ), PublishedVersion: (
      Pick<PostVersion, 'id' | 'title' | 'slug' | 'text' | 'excerpt' | 'language' | 'published' | 'mainImg' | 'createdAt' | 'ogTitle' | 'ogDescription' | 'tags'>
      & { categories: Array<Pick<PostCategory, 'id' | 'name'>>, Author: Pick<User, 'id' | 'authId' | 'name'> }
    ) }
  )> };

export type UpdatePostMutationVariables = {
  id: Scalars['String'],
  title: Scalars['String'],
  slug: Scalars['String'],
  text: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  published: Scalars['Boolean'],
  language: Scalars['String'],
  categories: Array<Scalars['String']>,
  createdAt: Scalars['String'],
  mainImg: Scalars['String'],
  updatedImg?: Maybe<Scalars['Upload']>,
  author: Scalars['String'],
  ogTitle?: Maybe<Scalars['String']>,
  ogDescription?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  isDraft: Scalars['Boolean'],
  draftedPostId: Scalars['String'],
  publishedPostId: Scalars['String'],
  projectId: Scalars['String']
};


export type UpdatePostMutation = { updateOnePost: Maybe<Pick<Post, 'id'>> };

export type DeletePostMutationVariables = {
  id?: Maybe<Scalars['String']>
};


export type DeletePostMutation = { deleteOnePost: Maybe<Pick<Post, 'id'>> };

export type DuplicatePostMutationVariables = {
  id: Scalars['String']
};


export type DuplicatePostMutation = { duplicatePost: Maybe<(
    Pick<Post, 'id'>
    & { PublishedVersion: (
      Pick<PostVersion, 'id' | 'title' | 'createdAt' | 'language' | 'published'>
      & { categories: Array<Pick<PostCategory, 'id' | 'name'>>, publishedPost: Maybe<Pick<Post, 'id'>>, Author: Pick<User, 'id' | 'name'> }
    ) }
  )> };

export type PostsQueryVariables = {
  projectId: Scalars['String']
};


export type PostsQuery = { posts: Array<(
    Pick<Post, 'id'>
    & { PublishedVersion: (
      Pick<PostVersion, 'id' | 'title' | 'createdAt' | 'language' | 'published'>
      & { categories: Array<Pick<PostCategory, 'id' | 'name'>>, publishedPost: Maybe<Pick<Post, 'id'>>, Author: Pick<User, 'id' | 'name'> }
    ) }
  )> };

export type CreateRestrictionMutationVariables = {
  logoImg: Scalars['Upload'],
  language: Scalars['String'],
  title: Scalars['String'],
  projectId: Scalars['String'],
  text: Scalars['String'],
  slug: Scalars['String']
};


export type CreateRestrictionMutation = { createOneRestriction: (
    Pick<ClientRestriction, 'id' | 'slug' | 'logoImg' | 'createdAt'>
    & { versions: Maybe<Array<Pick<ClientRestrictionVersion, 'id' | 'text' | 'title' | 'language'>>> }
  ) };

export type RestrictionQueryVariables = {
  id: Scalars['String']
};


export type RestrictionQuery = { restriction: Maybe<(
    Pick<ClientRestriction, 'id' | 'slug' | 'logoImg' | 'createdAt'>
    & { versions: Maybe<Array<Pick<ClientRestrictionVersion, 'id' | 'text' | 'title' | 'language'>>> }
  )> };

export type UpdateRestrictionMutationVariables = {
  id: Scalars['String'],
  language: Scalars['String'],
  text?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  logoImg?: Maybe<Scalars['Upload']>
};


export type UpdateRestrictionMutation = { updateOneRestriction: Pick<ClientRestriction, 'id'> };

export type DeleteRestrictionMutationVariables = {
  id?: Maybe<Scalars['String']>
};


export type DeleteRestrictionMutation = { deleteOneRestriction: Maybe<Pick<ClientRestriction, 'id'>> };

export type RestrictionsQueryVariables = {
  projectId: Scalars['String']
};


export type RestrictionsQuery = { restrictions: Array<(
    Pick<ClientRestriction, 'id' | 'slug' | 'logoImg' | 'createdAt'>
    & { versions: Maybe<Array<Pick<ClientRestrictionVersion, 'id' | 'text' | 'title' | 'language'>>> }
  )> };

export type DeleteSuggestionItemMutationVariables = {
  id: Scalars['String']
};


export type DeleteSuggestionItemMutation = { deleteOneSuggestionItem: Pick<SuggestionItem, 'id'> };

export type CreateSuggestionItemMutationVariables = {
  index: Scalars['Int'],
  img: Scalars['Upload'],
  text: Scalars['String'],
  title: Scalars['String'],
  suggestionId: Scalars['String']
};


export type CreateSuggestionItemMutation = { createOneSuggestionItem: Pick<SuggestionItem, 'id' | 'img' | 'text' | 'title' | 'index'> };

export type UpdateSuggestionItemMutationVariables = {
  id: Scalars['String'],
  index: Scalars['Int'],
  img: Scalars['String'],
  text: Scalars['String'],
  title: Scalars['String'],
  updatedImg: Scalars['Upload'],
  suggestionId: Scalars['String']
};


export type UpdateSuggestionItemMutation = { updateOneSuggestionItem: Pick<SuggestionItem, 'id' | 'img' | 'text' | 'title' | 'index'> };

export type SuggestionQueryVariables = {
  id?: Maybe<Scalars['String']>
};


export type SuggestionQuery = { suggestion: Maybe<(
    Pick<Suggestion, 'id' | 'img' | 'from' | 'until' | 'title' | 'published' | 'unlimited'>
    & { items: Array<Pick<SuggestionItem, 'id' | 'img' | 'text' | 'title' | 'index'>> }
  )> };

export type CreateSuggestionMutationVariables = {
  title: Scalars['String'],
  projectId: Scalars['String'],
  img: Scalars['Upload'],
  from?: Maybe<Scalars['DateTime']>,
  until?: Maybe<Scalars['DateTime']>,
  unlimited: Scalars['Boolean'],
  published: Scalars['Boolean'],
  items?: Maybe<Array<SuggestionItemInputCreate>>
};


export type CreateSuggestionMutation = { createOneSuggestion: (
    Pick<Suggestion, 'id' | 'img' | 'title' | 'from' | 'until' | 'unlimited' | 'published'>
    & { items: Array<Pick<SuggestionItem, 'id' | 'index' | 'title' | 'text' | 'img'>> }
  ) };

export type UpdateSuggestionMutationVariables = {
  id: Scalars['String'],
  title: Scalars['String'],
  img: Scalars['String'],
  published: Scalars['Boolean'],
  unlimited: Scalars['Boolean'],
  updatedImg?: Maybe<Scalars['Upload']>,
  from?: Maybe<Scalars['DateTime']>,
  until?: Maybe<Scalars['DateTime']>,
  items?: Maybe<Array<SuggestionItemInputUpdate>>
};


export type UpdateSuggestionMutation = { updateOneSuggestion: Maybe<(
    Pick<Suggestion, 'id' | 'img' | 'title' | 'from' | 'until' | 'unlimited' | 'published'>
    & { items: Array<Pick<SuggestionItem, 'id' | 'img' | 'text' | 'title' | 'index'>> }
  )> };

export type DeleteSuggestionMutationVariables = {
  id?: Maybe<Scalars['String']>
};


export type DeleteSuggestionMutation = { deleteOneSuggestion: Pick<Suggestion, 'id'> };

export type SuggestionsQueryVariables = {
  projectId: Scalars['String']
};


export type SuggestionsQuery = { suggestions: Array<(
    Pick<Suggestion, 'id' | 'from' | 'until' | 'title' | 'published' | 'unlimited'>
    & { items: Array<Pick<SuggestionItem, 'id' | 'index' | 'title' | 'text' | 'img'>> }
  )> };

export type UpdateUserMutationVariables = {
  id: Scalars['String'],
  name: Scalars['String']
};


export type UpdateUserMutation = { updateUserName: Pick<User, 'id' | 'authId' | 'name' | 'email' | 'createdAt'> };

export type UsersToConnectQueryVariables = {
  projectId: Scalars['String']
};


export type UsersToConnectQuery = { usersToConnect: Array<Pick<User, 'id' | 'name'>> };

export type ConnectUserMutationVariables = {
  userId: Scalars['String'],
  projectId: Scalars['String']
};


export type ConnectUserMutation = { connectUser: Pick<User, 'id' | 'authId' | 'name' | 'email' | 'createdAt'> };

export type DisconnectUserMutationVariables = {
  userId: Scalars['String'],
  projectId: Scalars['String']
};


export type DisconnectUserMutation = { disconnectUser: Pick<User, 'id'> };

export type UsersQueryVariables = {
  projectId: Scalars['String']
};


export type UsersQuery = { users: Array<Pick<User, 'id' | 'authId' | 'name' | 'email' | 'createdAt'>> };


export const CreateCompanyDocument = gql`
    mutation createCompany($name: String!) {
  createOneCompany(data: {name: $name}) {
    id
    name
  }
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CompanyDocument = gql`
    query company($id: String) {
  company(where: {id: $id}) {
    id
    name
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
      }
export function useCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = ApolloReactCommon.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: String, $name: String!) {
  updateOneCompany(where: {id: $id}, data: {name: $name}) {
    id
    name
  }
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: String) {
  deleteOneCompany(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteCompanyMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, baseOptions);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const CompaniesDocument = gql`
    query companies {
  companies {
    id
    name
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
      }
export function useCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($name: String!, $companyId: String!, $logo: Upload!, $logoSmall: Upload!, $webUrl: String, $email: String!, $faq: Boolean!, $restriction: Boolean!, $suggestion: Boolean!, $blog: Boolean!, $languages: String!, $editorConfig: String!) {
  createOneProject(name: $name, companyId: $companyId, logo: $logo, logoSmall: $logoSmall, webUrl: $webUrl, email: $email, faq: $faq, restriction: $restriction, suggestion: $suggestion, blog: $blog, languages: $languages, editorConfig: $editorConfig) {
    id
    name
    Company {
      id
      name
    }
  }
}
    `;
export type CreateProjectMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      logo: // value for 'logo'
 *      logoSmall: // value for 'logoSmall'
 *      webUrl: // value for 'webUrl'
 *      email: // value for 'email'
 *      faq: // value for 'faq'
 *      restriction: // value for 'restriction'
 *      suggestion: // value for 'suggestion'
 *      blog: // value for 'blog'
 *      languages: // value for 'languages'
 *      editorConfig: // value for 'editorConfig'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const ProjectDocument = gql`
    query project($id: String) {
  project(where: {id: $id}) {
    id
    name
    webUrl
    faqFeature
    restrictionFeature
    blogFeature
    suggestionFeature
    languages
    editorConfig
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
      }
export function useProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = ApolloReactCommon.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const UpdateProjectDocument = gql`
    mutation updateProject($id: String!, $name: String!, $webUrl: String, $logo: Upload, $logoSmall: Upload, $faq: Boolean!, $restriction: Boolean!, $suggestion: Boolean!, $blog: Boolean!, $languages: String!, $editorConfig: String!) {
  updateOneProject(id: $id, name: $name, webUrl: $webUrl, logo: $logo, logoSmall: $logoSmall, faq: $faq, restriction: $restriction, suggestion: $suggestion, blog: $blog, languages: $languages, editorConfig: $editorConfig) {
    id
    name
    webUrl
    logo
    logoSmall
    faqFeature
    restrictionFeature
    blogFeature
    languages
    Company {
      id
      name
    }
  }
}
    `;
export type UpdateProjectMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      webUrl: // value for 'webUrl'
 *      logo: // value for 'logo'
 *      logoSmall: // value for 'logoSmall'
 *      faq: // value for 'faq'
 *      restriction: // value for 'restriction'
 *      suggestion: // value for 'suggestion'
 *      blog: // value for 'blog'
 *      languages: // value for 'languages'
 *      editorConfig: // value for 'editorConfig'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = ApolloReactCommon.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($id: String) {
  deleteOneProject(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteProjectMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const ProjectsDocument = gql`
    query projects {
  projects {
    id
    name
    Company {
      id
      name
    }
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
      }
export function useProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = ApolloReactCommon.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const FaqCategoriesDocument = gql`
    query faqCategories($projectId: String!) {
  faqCategories(projectId: $projectId) {
    id
    name
  }
}
    `;

/**
 * __useFaqCategoriesQuery__
 *
 * To run a query within a React component, call `useFaqCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqCategoriesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFaqCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaqCategoriesQuery, FaqCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<FaqCategoriesQuery, FaqCategoriesQueryVariables>(FaqCategoriesDocument, baseOptions);
      }
export function useFaqCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaqCategoriesQuery, FaqCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaqCategoriesQuery, FaqCategoriesQueryVariables>(FaqCategoriesDocument, baseOptions);
        }
export type FaqCategoriesQueryHookResult = ReturnType<typeof useFaqCategoriesQuery>;
export type FaqCategoriesLazyQueryHookResult = ReturnType<typeof useFaqCategoriesLazyQuery>;
export type FaqCategoriesQueryResult = ApolloReactCommon.QueryResult<FaqCategoriesQuery, FaqCategoriesQueryVariables>;
export const CreateFaqDocument = gql`
    mutation createFaq($question: String!, $answer: String!, $published: Boolean!, $language: String!, $category: String!, $projectId: String!, $largeLink: String, $largeLinkTitle: String) {
  createOneFaq(question: $question, answer: $answer, published: $published, language: $language, category: $category, projectId: $projectId, largeLink: $largeLink, largeLinkTitle: $largeLinkTitle) {
    id
    question
    answer
    published
    language
    largeLink
    largeLinkTitle
    Category {
      id
      name
    }
  }
}
    `;
export type CreateFaqMutationFn = ApolloReactCommon.MutationFunction<CreateFaqMutation, CreateFaqMutationVariables>;

/**
 * __useCreateFaqMutation__
 *
 * To run a mutation, you first call `useCreateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaqMutation, { data, loading, error }] = useCreateFaqMutation({
 *   variables: {
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *      published: // value for 'published'
 *      language: // value for 'language'
 *      category: // value for 'category'
 *      projectId: // value for 'projectId'
 *      largeLink: // value for 'largeLink'
 *      largeLinkTitle: // value for 'largeLinkTitle'
 *   },
 * });
 */
export function useCreateFaqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFaqMutation, CreateFaqMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFaqMutation, CreateFaqMutationVariables>(CreateFaqDocument, baseOptions);
      }
export type CreateFaqMutationHookResult = ReturnType<typeof useCreateFaqMutation>;
export type CreateFaqMutationResult = ApolloReactCommon.MutationResult<CreateFaqMutation>;
export type CreateFaqMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFaqMutation, CreateFaqMutationVariables>;
export const FaqDocument = gql`
    query faq($id: String, $projectId: String!) {
  faqCategories(projectId: $projectId) {
    id
    name
  }
  faq(where: {id: $id}) {
    id
    question
    answer
    language
    published
    largeLink
    largeLinkTitle
    Category {
      id
      name
    }
  }
}
    `;

/**
 * __useFaqQuery__
 *
 * To run a query within a React component, call `useFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqQuery({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFaqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaqQuery, FaqQueryVariables>) {
        return ApolloReactHooks.useQuery<FaqQuery, FaqQueryVariables>(FaqDocument, baseOptions);
      }
export function useFaqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaqQuery, FaqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaqQuery, FaqQueryVariables>(FaqDocument, baseOptions);
        }
export type FaqQueryHookResult = ReturnType<typeof useFaqQuery>;
export type FaqLazyQueryHookResult = ReturnType<typeof useFaqLazyQuery>;
export type FaqQueryResult = ApolloReactCommon.QueryResult<FaqQuery, FaqQueryVariables>;
export const UpdateFaqDocument = gql`
    mutation updateFaq($id: String!, $question: String!, $answer: String!, $published: Boolean!, $language: String!, $category: String, $largeLink: String, $largeLinkTitle: String) {
  updateOneFaq(id: $id, question: $question, answer: $answer, published: $published, language: $language, category: $category, largeLink: $largeLink, largeLinkTitle: $largeLinkTitle) {
    id
    question
    answer
    published
    language
    largeLink
    largeLinkTitle
    Category {
      id
      name
    }
  }
}
    `;
export type UpdateFaqMutationFn = ApolloReactCommon.MutationFunction<UpdateFaqMutation, UpdateFaqMutationVariables>;

/**
 * __useUpdateFaqMutation__
 *
 * To run a mutation, you first call `useUpdateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaqMutation, { data, loading, error }] = useUpdateFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *      published: // value for 'published'
 *      language: // value for 'language'
 *      category: // value for 'category'
 *      largeLink: // value for 'largeLink'
 *      largeLinkTitle: // value for 'largeLinkTitle'
 *   },
 * });
 */
export function useUpdateFaqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFaqMutation, UpdateFaqMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFaqMutation, UpdateFaqMutationVariables>(UpdateFaqDocument, baseOptions);
      }
export type UpdateFaqMutationHookResult = ReturnType<typeof useUpdateFaqMutation>;
export type UpdateFaqMutationResult = ApolloReactCommon.MutationResult<UpdateFaqMutation>;
export type UpdateFaqMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFaqMutation, UpdateFaqMutationVariables>;
export const DeleteFaqDocument = gql`
    mutation deleteFaq($id: String) {
  deleteOneFaq(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteFaqMutationFn = ApolloReactCommon.MutationFunction<DeleteFaqMutation, DeleteFaqMutationVariables>;

/**
 * __useDeleteFaqMutation__
 *
 * To run a mutation, you first call `useDeleteFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaqMutation, { data, loading, error }] = useDeleteFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFaqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFaqMutation, DeleteFaqMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFaqMutation, DeleteFaqMutationVariables>(DeleteFaqDocument, baseOptions);
      }
export type DeleteFaqMutationHookResult = ReturnType<typeof useDeleteFaqMutation>;
export type DeleteFaqMutationResult = ApolloReactCommon.MutationResult<DeleteFaqMutation>;
export type DeleteFaqMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFaqMutation, DeleteFaqMutationVariables>;
export const FaqsDocument = gql`
    query faqs($projectId: String!) {
  faqs(projectId: $projectId) {
    id
    question
    Category {
      id
      name
    }
    language
    published
  }
}
    `;

/**
 * __useFaqsQuery__
 *
 * To run a query within a React component, call `useFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFaqsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FaqsQuery, FaqsQueryVariables>) {
        return ApolloReactHooks.useQuery<FaqsQuery, FaqsQueryVariables>(FaqsDocument, baseOptions);
      }
export function useFaqsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FaqsQuery, FaqsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FaqsQuery, FaqsQueryVariables>(FaqsDocument, baseOptions);
        }
export type FaqsQueryHookResult = ReturnType<typeof useFaqsQuery>;
export type FaqsLazyQueryHookResult = ReturnType<typeof useFaqsLazyQuery>;
export type FaqsQueryResult = ApolloReactCommon.QueryResult<FaqsQuery, FaqsQueryVariables>;
export const CreatePostFormDataDocument = gql`
    query createPostFormData($projectId: String!) {
  postCategories(projectId: $projectId) {
    id
    name
  }
  postUsers(projectId: $projectId) {
    authId
    name
  }
}
    `;

/**
 * __useCreatePostFormDataQuery__
 *
 * To run a query within a React component, call `useCreatePostFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatePostFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatePostFormDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreatePostFormDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreatePostFormDataQuery, CreatePostFormDataQueryVariables>) {
        return ApolloReactHooks.useQuery<CreatePostFormDataQuery, CreatePostFormDataQueryVariables>(CreatePostFormDataDocument, baseOptions);
      }
export function useCreatePostFormDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreatePostFormDataQuery, CreatePostFormDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreatePostFormDataQuery, CreatePostFormDataQueryVariables>(CreatePostFormDataDocument, baseOptions);
        }
export type CreatePostFormDataQueryHookResult = ReturnType<typeof useCreatePostFormDataQuery>;
export type CreatePostFormDataLazyQueryHookResult = ReturnType<typeof useCreatePostFormDataLazyQuery>;
export type CreatePostFormDataQueryResult = ApolloReactCommon.QueryResult<CreatePostFormDataQuery, CreatePostFormDataQueryVariables>;
export const CreatePostDocument = gql`
    mutation createPost($title: String!, $slug: String!, $text: String!, $excerpt: String, $published: Boolean!, $language: String!, $categories: [String!]!, $projectId: String!, $createdAt: DateTime!, $mainImg: Upload!, $author: String!, $ogTitle: String, $ogDescription: String, $tags: String) {
  createOnePost(title: $title, slug: $slug, text: $text, excerpt: $excerpt, published: $published, language: $language, categories: $categories, projectId: $projectId, createdAt: $createdAt, mainImg: $mainImg, author: $author, ogTitle: $ogTitle, ogDescription: $ogDescription, tags: $tags) {
    id
    PublishedVersion {
      id
      title
      language
      Author {
        id
        authId
        name
      }
      createdAt
      categories {
        id
        name
      }
      published
      publishedPost {
        id
      }
    }
  }
}
    `;
export type CreatePostMutationFn = ApolloReactCommon.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      title: // value for 'title'
 *      slug: // value for 'slug'
 *      text: // value for 'text'
 *      excerpt: // value for 'excerpt'
 *      published: // value for 'published'
 *      language: // value for 'language'
 *      categories: // value for 'categories'
 *      projectId: // value for 'projectId'
 *      createdAt: // value for 'createdAt'
 *      mainImg: // value for 'mainImg'
 *      author: // value for 'author'
 *      ogTitle: // value for 'ogTitle'
 *      ogDescription: // value for 'ogDescription'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, baseOptions);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = ApolloReactCommon.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const PostDocument = gql`
    query post($id: String, $projectId: String!) {
  postCategories(projectId: $projectId) {
    id
    name
  }
  postUsers(projectId: $projectId) {
    authId
    name
  }
  post(where: {id: $id}) {
    id
    DraftedVersion {
      id
      title
      slug
      text
      excerpt
      language
      mainImg
      categories {
        id
        name
      }
      Author {
        id
        authId
        name
      }
      createdAt
      ogTitle
      ogDescription
      tags
    }
    PublishedVersion {
      id
      title
      slug
      text
      excerpt
      language
      published
      mainImg
      categories {
        id
        name
      }
      Author {
        id
        authId
        name
      }
      createdAt
      ogTitle
      ogDescription
      tags
    }
  }
}
    `;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePostQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostQuery, PostQueryVariables>) {
        return ApolloReactHooks.useQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
      }
export function usePostLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = ApolloReactCommon.QueryResult<PostQuery, PostQueryVariables>;
export const UpdatePostDocument = gql`
    mutation updatePost($id: String!, $title: String!, $slug: String!, $text: String!, $excerpt: String, $published: Boolean!, $language: String!, $categories: [String!]!, $createdAt: String!, $mainImg: String!, $updatedImg: Upload, $author: String!, $ogTitle: String, $ogDescription: String, $tags: String, $isDraft: Boolean!, $draftedPostId: String!, $publishedPostId: String!, $projectId: String!) {
  updateOnePost(id: $id, title: $title, slug: $slug, text: $text, excerpt: $excerpt, published: $published, language: $language, categories: $categories, mainImg: $mainImg, updatedImg: $updatedImg, createdAt: $createdAt, author: $author, ogTitle: $ogTitle, ogDescription: $ogDescription, tags: $tags, isDraft: $isDraft, draftedPostId: $draftedPostId, publishedPostId: $publishedPostId, projectId: $projectId) {
    id
  }
}
    `;
export type UpdatePostMutationFn = ApolloReactCommon.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      slug: // value for 'slug'
 *      text: // value for 'text'
 *      excerpt: // value for 'excerpt'
 *      published: // value for 'published'
 *      language: // value for 'language'
 *      categories: // value for 'categories'
 *      createdAt: // value for 'createdAt'
 *      mainImg: // value for 'mainImg'
 *      updatedImg: // value for 'updatedImg'
 *      author: // value for 'author'
 *      ogTitle: // value for 'ogTitle'
 *      ogDescription: // value for 'ogDescription'
 *      tags: // value for 'tags'
 *      isDraft: // value for 'isDraft'
 *      draftedPostId: // value for 'draftedPostId'
 *      publishedPostId: // value for 'publishedPostId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, baseOptions);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = ApolloReactCommon.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const DeletePostDocument = gql`
    mutation deletePost($id: String) {
  deleteOnePost(where: {id: $id}) {
    id
  }
}
    `;
export type DeletePostMutationFn = ApolloReactCommon.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, baseOptions);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = ApolloReactCommon.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DuplicatePostDocument = gql`
    mutation duplicatePost($id: String!) {
  duplicatePost(id: $id) {
    id
    PublishedVersion {
      id
      title
      createdAt
      language
      categories {
        id
        name
      }
      published
      publishedPost {
        id
      }
      Author {
        id
        name
      }
    }
  }
}
    `;
export type DuplicatePostMutationFn = ApolloReactCommon.MutationFunction<DuplicatePostMutation, DuplicatePostMutationVariables>;

/**
 * __useDuplicatePostMutation__
 *
 * To run a mutation, you first call `useDuplicatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePostMutation, { data, loading, error }] = useDuplicatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicatePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicatePostMutation, DuplicatePostMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicatePostMutation, DuplicatePostMutationVariables>(DuplicatePostDocument, baseOptions);
      }
export type DuplicatePostMutationHookResult = ReturnType<typeof useDuplicatePostMutation>;
export type DuplicatePostMutationResult = ApolloReactCommon.MutationResult<DuplicatePostMutation>;
export type DuplicatePostMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicatePostMutation, DuplicatePostMutationVariables>;
export const PostsDocument = gql`
    query posts($projectId: String!) {
  posts(projectId: $projectId) {
    id
    PublishedVersion {
      id
      title
      createdAt
      language
      categories {
        id
        name
      }
      published
      publishedPost {
        id
      }
      Author {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        return ApolloReactHooks.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
      }
export function usePostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = ApolloReactCommon.QueryResult<PostsQuery, PostsQueryVariables>;
export const CreateRestrictionDocument = gql`
    mutation createRestriction($logoImg: Upload!, $language: String!, $title: String!, $projectId: String!, $text: String!, $slug: String!) {
  createOneRestriction(projectId: $projectId, language: $language, logoImg: $logoImg, title: $title, text: $text, slug: $slug) {
    id
    slug
    logoImg
    createdAt
    versions {
      id
      text
      title
      language
    }
  }
}
    `;
export type CreateRestrictionMutationFn = ApolloReactCommon.MutationFunction<CreateRestrictionMutation, CreateRestrictionMutationVariables>;

/**
 * __useCreateRestrictionMutation__
 *
 * To run a mutation, you first call `useCreateRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestrictionMutation, { data, loading, error }] = useCreateRestrictionMutation({
 *   variables: {
 *      logoImg: // value for 'logoImg'
 *      language: // value for 'language'
 *      title: // value for 'title'
 *      projectId: // value for 'projectId'
 *      text: // value for 'text'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCreateRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRestrictionMutation, CreateRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRestrictionMutation, CreateRestrictionMutationVariables>(CreateRestrictionDocument, baseOptions);
      }
export type CreateRestrictionMutationHookResult = ReturnType<typeof useCreateRestrictionMutation>;
export type CreateRestrictionMutationResult = ApolloReactCommon.MutationResult<CreateRestrictionMutation>;
export type CreateRestrictionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRestrictionMutation, CreateRestrictionMutationVariables>;
export const RestrictionDocument = gql`
    query restriction($id: String!) {
  restriction(id: $id) {
    id
    slug
    logoImg
    createdAt
    versions {
      id
      text
      title
      language
    }
  }
}
    `;

/**
 * __useRestrictionQuery__
 *
 * To run a query within a React component, call `useRestrictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestrictionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestrictionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestrictionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestrictionQuery, RestrictionQueryVariables>) {
        return ApolloReactHooks.useQuery<RestrictionQuery, RestrictionQueryVariables>(RestrictionDocument, baseOptions);
      }
export function useRestrictionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestrictionQuery, RestrictionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestrictionQuery, RestrictionQueryVariables>(RestrictionDocument, baseOptions);
        }
export type RestrictionQueryHookResult = ReturnType<typeof useRestrictionQuery>;
export type RestrictionLazyQueryHookResult = ReturnType<typeof useRestrictionLazyQuery>;
export type RestrictionQueryResult = ApolloReactCommon.QueryResult<RestrictionQuery, RestrictionQueryVariables>;
export const UpdateRestrictionDocument = gql`
    mutation updateRestriction($id: String!, $language: String!, $text: String, $title: String, $logoImg: Upload) {
  updateOneRestriction(id: $id, text: $text, title: $title, logoImg: $logoImg, language: $language) {
    id
  }
}
    `;
export type UpdateRestrictionMutationFn = ApolloReactCommon.MutationFunction<UpdateRestrictionMutation, UpdateRestrictionMutationVariables>;

/**
 * __useUpdateRestrictionMutation__
 *
 * To run a mutation, you first call `useUpdateRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestrictionMutation, { data, loading, error }] = useUpdateRestrictionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *      text: // value for 'text'
 *      title: // value for 'title'
 *      logoImg: // value for 'logoImg'
 *   },
 * });
 */
export function useUpdateRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRestrictionMutation, UpdateRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRestrictionMutation, UpdateRestrictionMutationVariables>(UpdateRestrictionDocument, baseOptions);
      }
export type UpdateRestrictionMutationHookResult = ReturnType<typeof useUpdateRestrictionMutation>;
export type UpdateRestrictionMutationResult = ApolloReactCommon.MutationResult<UpdateRestrictionMutation>;
export type UpdateRestrictionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRestrictionMutation, UpdateRestrictionMutationVariables>;
export const DeleteRestrictionDocument = gql`
    mutation deleteRestriction($id: String) {
  deleteOneRestriction(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteRestrictionMutationFn = ApolloReactCommon.MutationFunction<DeleteRestrictionMutation, DeleteRestrictionMutationVariables>;

/**
 * __useDeleteRestrictionMutation__
 *
 * To run a mutation, you first call `useDeleteRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestrictionMutation, { data, loading, error }] = useDeleteRestrictionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRestrictionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRestrictionMutation, DeleteRestrictionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRestrictionMutation, DeleteRestrictionMutationVariables>(DeleteRestrictionDocument, baseOptions);
      }
export type DeleteRestrictionMutationHookResult = ReturnType<typeof useDeleteRestrictionMutation>;
export type DeleteRestrictionMutationResult = ApolloReactCommon.MutationResult<DeleteRestrictionMutation>;
export type DeleteRestrictionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRestrictionMutation, DeleteRestrictionMutationVariables>;
export const RestrictionsDocument = gql`
    query restrictions($projectId: String!) {
  restrictions(projectId: $projectId) {
    id
    slug
    logoImg
    createdAt
    versions {
      id
      text
      title
      language
    }
  }
}
    `;

/**
 * __useRestrictionsQuery__
 *
 * To run a query within a React component, call `useRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestrictionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRestrictionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestrictionsQuery, RestrictionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RestrictionsQuery, RestrictionsQueryVariables>(RestrictionsDocument, baseOptions);
      }
export function useRestrictionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestrictionsQuery, RestrictionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestrictionsQuery, RestrictionsQueryVariables>(RestrictionsDocument, baseOptions);
        }
export type RestrictionsQueryHookResult = ReturnType<typeof useRestrictionsQuery>;
export type RestrictionsLazyQueryHookResult = ReturnType<typeof useRestrictionsLazyQuery>;
export type RestrictionsQueryResult = ApolloReactCommon.QueryResult<RestrictionsQuery, RestrictionsQueryVariables>;
export const DeleteSuggestionItemDocument = gql`
    mutation deleteSuggestionItem($id: String!) {
  deleteOneSuggestionItem(id: $id) {
    id
  }
}
    `;
export type DeleteSuggestionItemMutationFn = ApolloReactCommon.MutationFunction<DeleteSuggestionItemMutation, DeleteSuggestionItemMutationVariables>;

/**
 * __useDeleteSuggestionItemMutation__
 *
 * To run a mutation, you first call `useDeleteSuggestionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSuggestionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSuggestionItemMutation, { data, loading, error }] = useDeleteSuggestionItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSuggestionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSuggestionItemMutation, DeleteSuggestionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSuggestionItemMutation, DeleteSuggestionItemMutationVariables>(DeleteSuggestionItemDocument, baseOptions);
      }
export type DeleteSuggestionItemMutationHookResult = ReturnType<typeof useDeleteSuggestionItemMutation>;
export type DeleteSuggestionItemMutationResult = ApolloReactCommon.MutationResult<DeleteSuggestionItemMutation>;
export type DeleteSuggestionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSuggestionItemMutation, DeleteSuggestionItemMutationVariables>;
export const CreateSuggestionItemDocument = gql`
    mutation createSuggestionItem($index: Int!, $img: Upload!, $text: String!, $title: String!, $suggestionId: String!) {
  createOneSuggestionItem(img: $img, text: $text, index: $index, title: $title, suggestionId: $suggestionId) {
    id
    img
    text
    title
    index
  }
}
    `;
export type CreateSuggestionItemMutationFn = ApolloReactCommon.MutationFunction<CreateSuggestionItemMutation, CreateSuggestionItemMutationVariables>;

/**
 * __useCreateSuggestionItemMutation__
 *
 * To run a mutation, you first call `useCreateSuggestionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuggestionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuggestionItemMutation, { data, loading, error }] = useCreateSuggestionItemMutation({
 *   variables: {
 *      index: // value for 'index'
 *      img: // value for 'img'
 *      text: // value for 'text'
 *      title: // value for 'title'
 *      suggestionId: // value for 'suggestionId'
 *   },
 * });
 */
export function useCreateSuggestionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSuggestionItemMutation, CreateSuggestionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSuggestionItemMutation, CreateSuggestionItemMutationVariables>(CreateSuggestionItemDocument, baseOptions);
      }
export type CreateSuggestionItemMutationHookResult = ReturnType<typeof useCreateSuggestionItemMutation>;
export type CreateSuggestionItemMutationResult = ApolloReactCommon.MutationResult<CreateSuggestionItemMutation>;
export type CreateSuggestionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSuggestionItemMutation, CreateSuggestionItemMutationVariables>;
export const UpdateSuggestionItemDocument = gql`
    mutation updateSuggestionItem($id: String!, $index: Int!, $img: String!, $text: String!, $title: String!, $updatedImg: Upload!, $suggestionId: String!) {
  updateOneSuggestionItem(id: $id, img: $img, text: $text, index: $index, title: $title, updatedImg: $updatedImg) {
    id
    img
    text
    title
    index
  }
}
    `;
export type UpdateSuggestionItemMutationFn = ApolloReactCommon.MutationFunction<UpdateSuggestionItemMutation, UpdateSuggestionItemMutationVariables>;

/**
 * __useUpdateSuggestionItemMutation__
 *
 * To run a mutation, you first call `useUpdateSuggestionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuggestionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuggestionItemMutation, { data, loading, error }] = useUpdateSuggestionItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      index: // value for 'index'
 *      img: // value for 'img'
 *      text: // value for 'text'
 *      title: // value for 'title'
 *      updatedImg: // value for 'updatedImg'
 *      suggestionId: // value for 'suggestionId'
 *   },
 * });
 */
export function useUpdateSuggestionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSuggestionItemMutation, UpdateSuggestionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSuggestionItemMutation, UpdateSuggestionItemMutationVariables>(UpdateSuggestionItemDocument, baseOptions);
      }
export type UpdateSuggestionItemMutationHookResult = ReturnType<typeof useUpdateSuggestionItemMutation>;
export type UpdateSuggestionItemMutationResult = ApolloReactCommon.MutationResult<UpdateSuggestionItemMutation>;
export type UpdateSuggestionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSuggestionItemMutation, UpdateSuggestionItemMutationVariables>;
export const SuggestionDocument = gql`
    query suggestion($id: String) {
  suggestion(where: {id: $id}) {
    id
    img
    from
    until
    title
    published
    unlimited
    items {
      id
      img
      text
      title
      index
    }
  }
}
    `;

/**
 * __useSuggestionQuery__
 *
 * To run a query within a React component, call `useSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSuggestionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SuggestionQuery, SuggestionQueryVariables>) {
        return ApolloReactHooks.useQuery<SuggestionQuery, SuggestionQueryVariables>(SuggestionDocument, baseOptions);
      }
export function useSuggestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuggestionQuery, SuggestionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuggestionQuery, SuggestionQueryVariables>(SuggestionDocument, baseOptions);
        }
export type SuggestionQueryHookResult = ReturnType<typeof useSuggestionQuery>;
export type SuggestionLazyQueryHookResult = ReturnType<typeof useSuggestionLazyQuery>;
export type SuggestionQueryResult = ApolloReactCommon.QueryResult<SuggestionQuery, SuggestionQueryVariables>;
export const CreateSuggestionDocument = gql`
    mutation createSuggestion($title: String!, $projectId: String!, $img: Upload!, $from: DateTime, $until: DateTime, $unlimited: Boolean!, $published: Boolean!, $items: [SuggestionItemInputCreate!]) {
  createOneSuggestion(title: $title, img: $img, projectId: $projectId, from: $from, until: $until, items: $items, unlimited: $unlimited, published: $published) {
    id
    img
    title
    from
    until
    unlimited
    published
    items {
      id
      index
      title
      text
      img
    }
  }
}
    `;
export type CreateSuggestionMutationFn = ApolloReactCommon.MutationFunction<CreateSuggestionMutation, CreateSuggestionMutationVariables>;

/**
 * __useCreateSuggestionMutation__
 *
 * To run a mutation, you first call `useCreateSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuggestionMutation, { data, loading, error }] = useCreateSuggestionMutation({
 *   variables: {
 *      title: // value for 'title'
 *      projectId: // value for 'projectId'
 *      img: // value for 'img'
 *      from: // value for 'from'
 *      until: // value for 'until'
 *      unlimited: // value for 'unlimited'
 *      published: // value for 'published'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCreateSuggestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSuggestionMutation, CreateSuggestionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSuggestionMutation, CreateSuggestionMutationVariables>(CreateSuggestionDocument, baseOptions);
      }
export type CreateSuggestionMutationHookResult = ReturnType<typeof useCreateSuggestionMutation>;
export type CreateSuggestionMutationResult = ApolloReactCommon.MutationResult<CreateSuggestionMutation>;
export type CreateSuggestionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSuggestionMutation, CreateSuggestionMutationVariables>;
export const UpdateSuggestionDocument = gql`
    mutation updateSuggestion($id: String!, $title: String!, $img: String!, $published: Boolean!, $unlimited: Boolean!, $updatedImg: Upload, $from: DateTime, $until: DateTime, $items: [SuggestionItemInputUpdate!]) {
  updateOneSuggestion(id: $id, img: $img, title: $title, published: $published, unlimited: $unlimited, updatedImg: $updatedImg, from: $from, until: $until, items: $items) {
    id
    img
    title
    from
    until
    unlimited
    published
    items {
      id
      img
      text
      title
      index
    }
  }
}
    `;
export type UpdateSuggestionMutationFn = ApolloReactCommon.MutationFunction<UpdateSuggestionMutation, UpdateSuggestionMutationVariables>;

/**
 * __useUpdateSuggestionMutation__
 *
 * To run a mutation, you first call `useUpdateSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuggestionMutation, { data, loading, error }] = useUpdateSuggestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      img: // value for 'img'
 *      published: // value for 'published'
 *      unlimited: // value for 'unlimited'
 *      updatedImg: // value for 'updatedImg'
 *      from: // value for 'from'
 *      until: // value for 'until'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateSuggestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSuggestionMutation, UpdateSuggestionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSuggestionMutation, UpdateSuggestionMutationVariables>(UpdateSuggestionDocument, baseOptions);
      }
export type UpdateSuggestionMutationHookResult = ReturnType<typeof useUpdateSuggestionMutation>;
export type UpdateSuggestionMutationResult = ApolloReactCommon.MutationResult<UpdateSuggestionMutation>;
export type UpdateSuggestionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSuggestionMutation, UpdateSuggestionMutationVariables>;
export const DeleteSuggestionDocument = gql`
    mutation deleteSuggestion($id: String) {
  deleteOneSuggestion(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteSuggestionMutationFn = ApolloReactCommon.MutationFunction<DeleteSuggestionMutation, DeleteSuggestionMutationVariables>;

/**
 * __useDeleteSuggestionMutation__
 *
 * To run a mutation, you first call `useDeleteSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSuggestionMutation, { data, loading, error }] = useDeleteSuggestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSuggestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSuggestionMutation, DeleteSuggestionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSuggestionMutation, DeleteSuggestionMutationVariables>(DeleteSuggestionDocument, baseOptions);
      }
export type DeleteSuggestionMutationHookResult = ReturnType<typeof useDeleteSuggestionMutation>;
export type DeleteSuggestionMutationResult = ApolloReactCommon.MutationResult<DeleteSuggestionMutation>;
export type DeleteSuggestionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSuggestionMutation, DeleteSuggestionMutationVariables>;
export const SuggestionsDocument = gql`
    query suggestions($projectId: String!) {
  suggestions(projectId: $projectId) {
    id
    from
    until
    title
    published
    unlimited
    items {
      id
      index
      title
      text
      img
    }
  }
}
    `;

/**
 * __useSuggestionsQuery__
 *
 * To run a query within a React component, call `useSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, baseOptions);
      }
export function useSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, baseOptions);
        }
export type SuggestionsQueryHookResult = ReturnType<typeof useSuggestionsQuery>;
export type SuggestionsLazyQueryHookResult = ReturnType<typeof useSuggestionsLazyQuery>;
export type SuggestionsQueryResult = ApolloReactCommon.QueryResult<SuggestionsQuery, SuggestionsQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $name: String!) {
  updateUserName(id: $id, name: $name) {
    id
    authId
    name
    email
    createdAt
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UsersToConnectDocument = gql`
    query usersToConnect($projectId: String!) {
  usersToConnect(projectId: $projectId) {
    id
    name
  }
}
    `;

/**
 * __useUsersToConnectQuery__
 *
 * To run a query within a React component, call `useUsersToConnectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersToConnectQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersToConnectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUsersToConnectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersToConnectQuery, UsersToConnectQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersToConnectQuery, UsersToConnectQueryVariables>(UsersToConnectDocument, baseOptions);
      }
export function useUsersToConnectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersToConnectQuery, UsersToConnectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersToConnectQuery, UsersToConnectQueryVariables>(UsersToConnectDocument, baseOptions);
        }
export type UsersToConnectQueryHookResult = ReturnType<typeof useUsersToConnectQuery>;
export type UsersToConnectLazyQueryHookResult = ReturnType<typeof useUsersToConnectLazyQuery>;
export type UsersToConnectQueryResult = ApolloReactCommon.QueryResult<UsersToConnectQuery, UsersToConnectQueryVariables>;
export const ConnectUserDocument = gql`
    mutation connectUser($userId: String!, $projectId: String!) {
  connectUser(userId: $userId, projectId: $projectId) {
    id
    authId
    name
    email
    createdAt
  }
}
    `;
export type ConnectUserMutationFn = ApolloReactCommon.MutationFunction<ConnectUserMutation, ConnectUserMutationVariables>;

/**
 * __useConnectUserMutation__
 *
 * To run a mutation, you first call `useConnectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserMutation, { data, loading, error }] = useConnectUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useConnectUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectUserMutation, ConnectUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ConnectUserMutation, ConnectUserMutationVariables>(ConnectUserDocument, baseOptions);
      }
export type ConnectUserMutationHookResult = ReturnType<typeof useConnectUserMutation>;
export type ConnectUserMutationResult = ApolloReactCommon.MutationResult<ConnectUserMutation>;
export type ConnectUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectUserMutation, ConnectUserMutationVariables>;
export const DisconnectUserDocument = gql`
    mutation disconnectUser($userId: String!, $projectId: String!) {
  disconnectUser(userId: $userId, projectId: $projectId) {
    id
  }
}
    `;
export type DisconnectUserMutationFn = ApolloReactCommon.MutationFunction<DisconnectUserMutation, DisconnectUserMutationVariables>;

/**
 * __useDisconnectUserMutation__
 *
 * To run a mutation, you first call `useDisconnectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectUserMutation, { data, loading, error }] = useDisconnectUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDisconnectUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisconnectUserMutation, DisconnectUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DisconnectUserMutation, DisconnectUserMutationVariables>(DisconnectUserDocument, baseOptions);
      }
export type DisconnectUserMutationHookResult = ReturnType<typeof useDisconnectUserMutation>;
export type DisconnectUserMutationResult = ApolloReactCommon.MutationResult<DisconnectUserMutation>;
export type DisconnectUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DisconnectUserMutation, DisconnectUserMutationVariables>;
export const UsersDocument = gql`
    query users($projectId: String!) {
  users(projectId: $projectId) {
    id
    authId
    name
    email
    createdAt
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;