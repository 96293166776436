import { Icon } from "antd";
import styled from "styled-components";
import { ProfileImg } from '../../shared/layout';

export const PopoverHeader = styled.p`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
`;

export const ProjectLogo = styled.img`
  width: 100px;
`;

export const LogoIcon = styled(Icon)`
  margin: auto 0 auto 5px;
`;

export const ProfileDropdown = styled.div<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ collapsed }) => collapsed ? 'column' : 'row'};
  padding: 0 0 27px 0;

  color: #000;

  ${ProfileImg} {
    margin: ${({ collapsed }) => !collapsed ? '0 10px 0 15px' : '0 auto'};
  }
`

export const LeftMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

export const ProfileArrow = styled.img<{ collapsed: boolean }>`
  display: inline-block;
  margin: ${({ collapsed }) => collapsed ? '10px auto 0' : '0 0 0 10px' };
  transform: rotate(-90deg);
`